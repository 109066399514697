import type { PayloadAction } from "@reduxjs/toolkit";
import type { CountableFilter, ISearchFiltersState, SelectableFilterKey } from "./types";
import {
  buildIsResultVisibleChecker,
  buildSelectedPropertyNames,
  checkIsResultRemovedByPriceRange,
  checkIsResultRemovedByPropertyName,
  checkIsResultRemovedByStarRating,
  getSelectableFilter,
  incrementFilterCounts,
  resetFilterCounts,
  toggleAllLoyaltyOptions,
  toggleAllStayTypes,
  updateOrderedSelectedFilters,
} from "./helpers";
import { UserPrefs } from "@hotel-engine/utilities";
import type { SortOptions } from "store/Search/constants";
import type { IResults, ISearchResultsState } from "store/Search/SearchResults/types";
import {
  getFiltersAvailabilityFromNewResults,
  getLoyaltyProgramFilters,
  getPropertyNameFilters,
  getPropertyTypeFiltersAvailability,
  getSuppliersFilters,
} from "store/Search/SearchFilters/selectors/helpers";
import { SELECTABLE_FILTER_TYPES, initialState } from "./constants";

export const clearSelectedFilters = (state: ISearchFiltersState) => {
  SELECTABLE_FILTER_TYPES.forEach((filterType) => {
    // Deselect each filter for the filter type
    Object.keys(state[filterType]).forEach((filterKey) => {
      state[filterType][filterKey].selected = false;
    });
  });

  state.priceRange.userMin = null;
  state.priceRange.userMax = null;
  state.lastDeselectedFilter = undefined;
  state.orderedSelectedFilters = [];
};

export const initFilters = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    results: IResults;
  }>
) => {
  const { results } = action.payload;

  const { propertyTypes, propertyNames, suppliers, priceRange } =
    getFiltersAvailabilityFromNewResults(results);

  // Set new price range numbers
  state.priceRange.floor = priceRange.floor || initialState.priceRange.floor;
  state.priceRange.ceil = priceRange.ceil || initialState.priceRange.ceil;

  // Set property type filters as available if a match is found
  state.propertyTypes = getPropertyTypeFiltersAvailability(state.propertyTypes, propertyTypes);

  // Set available and currently selected property name filters
  state.propertyNames = getPropertyNameFilters(state.propertyNames, propertyNames);

  state.suppliers = getSuppliersFilters(state.suppliers, suppliers);

  // Set loyalty program filters
  const loyaltyPrograms = getLoyaltyProgramFilters(results);
  state.loyaltyPrograms = { ...state.loyaltyPrograms, ...loyaltyPrograms };
};

export const setPriceRange = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    min: number | null;
    max: number | null;
  }>
) => {
  const { min, max } = action.payload;
  state.priceRange.userMin = min;
  state.priceRange.userMax = max;
};

export const setSelectedSort = (
  state: ISearchFiltersState,
  action: PayloadAction<{ selectedSort: SortOptions; userId?: number }>
) => {
  const { selectedSort, userId } = action.payload;
  UserPrefs.set("sort_preference", selectedSort, userId);

  state.selectedSort = selectedSort;
};

export const setStarRating = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    filterKey: keyof ISearchFiltersState["starRating"];
    selected: boolean;
  }>
) => {
  const { filterKey, selected } = action.payload;

  state.starRating[filterKey].selected = selected;
};

export const resetStarRating = (state: ISearchFiltersState) => {
  Object.keys(state.starRating).forEach((key) => {
    state.starRating[key].selected = false;
  });
};

export const setFilterSelected = (
  state: ISearchFiltersState,
  action: PayloadAction<{ filterKey: SelectableFilterKey; selected: boolean }>
) => {
  const { filterKey, selected } = action.payload;
  const selectableFilter = getSelectableFilter(state, filterKey);

  if (selectableFilter) {
    selectableFilter.selected = selected;

    const filterGroup = filterKey.split(".")[0];
    updateOrderedSelectedFilters(selected, filterGroup as CountableFilter, state);
  }
};

export const toggleLoyaltyProgramFilter = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    filterKey: keyof ISearchFiltersState["loyaltyPrograms"];
    selected: boolean;
  }>
) => {
  const { filterKey, selected } = action.payload;

  if (filterKey === "all") {
    toggleAllLoyaltyOptions(state.loyaltyPrograms, selected);
    return;
  }

  if (state.loyaltyPrograms.all.selected && !selected) {
    state.loyaltyPrograms.all.selected = false;
  }

  state.loyaltyPrograms[filterKey].selected = selected;

  updateOrderedSelectedFilters(selected, "loyaltyPrograms", state);
};

export const resetLoyaltyProgramFilters = (state: ISearchFiltersState) => {
  Object.keys(state.loyaltyPrograms).forEach((filterKey) => {
    state.loyaltyPrograms[filterKey].selected = false;
  });
  updateOrderedSelectedFilters(false, "loyaltyPrograms", state);
};

export const togglePropertyNameFilter = (
  state: ISearchFiltersState,
  action: PayloadAction<string>
) => {
  const propertyName = state.propertyNames[action.payload];
  if (propertyName) {
    propertyName.selected = !propertyName.selected;
  } else {
    state.propertyNames[action.payload] = { selected: true };
  }
};

export const toggleStayTypeFilter = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    filterKey: keyof ISearchFiltersState["stayTypes"];
    selected: boolean;
  }>
) => {
  const { filterKey, selected } = action.payload;

  if (filterKey === "all") {
    toggleAllStayTypes(state.stayTypes, selected);
    return;
  }

  if (state.stayTypes.all.selected && !selected) {
    state.stayTypes.all.selected = false;
  }

  state.stayTypes[filterKey].selected = selected;

  updateOrderedSelectedFilters(selected, "stayTypes", state);

  // If all stay types are selected, select the "all" filter
  const allStayTypesSelected = Object.values(state.stayTypes)
    .slice(1)
    .every((stayTypeValue) => stayTypeValue.selected);
  if (allStayTypesSelected) {
    state.stayTypes.all.selected = true;
  }
};

/**
 * Slice reducer to update the filter counts based on the search results.
 *
 * There are 2 different types of filters, ones that always update their counts when a filter is selected
 * (facility, room, popular, services), and ones that should keep their same counts as long as a filter
 * within the same filter type is selected (stay types, property types, loyalty programs, suppliers) BUT will
 * update counts outside of their filter type. This allows the user to select multiple options within the filter
 * type.
 */
export const updateFilterCounts = (
  state: ISearchFiltersState,
  action: PayloadAction<{
    results: ISearchResultsState["results"];
    visibleResultIds: ISearchResultsState["visibleResultIds"];
    visibleHiddenResultIds: ISearchResultsState["visibleHiddenResultIds"];
  }>
) => {
  const { results, visibleHiddenResultIds, visibleResultIds } = action.payload;
  const checkIsResultVisible = buildIsResultVisibleChecker({
    visibleHiddenResultIds,
    visibleResultIds,
  });
  const selectedPropertyNames = buildSelectedPropertyNames(state);

  /**
   * Since the desired output of this reducer is to create a NEW count for the
   * results, we need to reset all of the existing counts back to 0 to prevent
   * the old counts from being incrementing. Doing this, gives us a 0 count to
   * increment.
   *
   * The exception to this is if a filter type is a specified filter that keeps its previous counts
   */
  resetFilterCounts(state);

  Object.entries(results).forEach(([propertyId, result]) => {
    if (
      checkIsResultRemovedByPriceRange(state, result) ||
      checkIsResultRemovedByStarRating(state, result) ||
      checkIsResultRemovedByPropertyName(selectedPropertyNames, result)
    ) {
      return;
    }

    const isResultVisible = checkIsResultVisible(Number(propertyId));

    incrementFilterCounts(state, result, isResultVisible);
  });
};
