import type { TCarFeatureNames } from "@hotel-engine/types/cars/cars.shared";
import {
  CarRentalVendorAirportAccessType,
  ECarBottomFeatures,
  ECarListFeatures,
} from "@hotel-engine/types/cars/cars.shared";
import type { CustomIconName, IconName } from "@hotelengine/atlas-core";
import type { TokensSchema } from "@hotelengine/atlas-web/dist/theme/tokens";

export type TCarFeatureInfo = {
  icon: IconName | CustomIconName | "directBill";
  label: string;
  color: keyof TokensSchema["colors"];
};

export const FEATURES: {
  [key in TCarFeatureNames]: TCarFeatureInfo;
} = {
  [ECarBottomFeatures.passengers]: {
    icon: "user",
    label: "Number of passengers",
    color: "foregroundSecondary",
  },
  [ECarBottomFeatures.transmissionType]: {
    icon: "gearshift",
    label: "Transmission type",
    color: "foregroundSecondary",
  },
  [ECarBottomFeatures.numberBags]: {
    icon: "suitcase",
    label: "Number of bags",
    color: "foregroundSecondary",
  },
  [ECarListFeatures.doorCount]: {
    icon: "door",
    label: "Door count",
    color: "foregroundSecondary",
  },
  [ECarListFeatures.milesIncluded]: {
    icon: "gauge",
    label: "Limited mileage",
    color: "foregroundSecondary",
  },
  [ECarListFeatures.unlimitedMileage]: {
    icon: "check",
    label: "Unlimited mileage",
    color: "sentimentPositiveDefault",
  },
  [ECarListFeatures.freeCancellation]: {
    icon: "check",
    label: "Free cancellation",
    color: "sentimentPositiveDefault",
  },
  [ECarListFeatures.flexibleCancellation]: {
    icon: "check",
    label: "Flexible cancellation",
    color: "sentimentPositiveDefault",
  },
  [ECarListFeatures.payLater]: {
    icon: "check",
    label: "Pay at pick-up",
    color: "sentimentPositiveDefault",
  },
  [ECarListFeatures.directBill]: {
    icon: "directBill",
    label: "Direct bill",
    color: "foregroundPrimary",
  },
  [CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT]: {
    icon: "location-dot",
    label: "In-terminal pick-up",
    color: "foregroundSecondary",
  },
  [CarRentalVendorAirportAccessType.CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT]: {
    icon: "location-dot",
    label: "Shuttle to pick-up",
    color: "foregroundSecondary",
  },
};
