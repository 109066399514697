import styled, { keyframes } from "styled-components";

import {
  Box,
  Button,
  Typography,
  type BoxProps,
  type ButtonProps,
  type TypographyProps,
} from "@hotelengine/atlas-web";

const SlideUp = keyframes`
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
`;

export const CookieBannerContainer = styled.div`
  animation: ${SlideUp} 1s ease-in-out;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.modal};
  bottom: 0;
  position: fixed;
  box-shadow: 0 -8px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  opacity: 0.95;
`;

export const CookieBannerContent = styled<React.FC<BoxProps>>(Box)`
  padding: 24px;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    flex-direction: row;
    padding: 0 2vh;
    height: 15vh;
  }
`;

export const CookieText = styled<React.FC<TypographyProps>>(Typography)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 2.5vw 5vh;
  }
`;

export const ButtonContainer = styled<React.FC<BoxProps>>(Box)`
  justify-content: space-between;
  margin: 16px 0 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    justify-content: center;
    width: 18vw;
    flex-direction: column;
  }
`;

export const Approve = styled<React.FC<ButtonProps>>(Button)`
  min-width: 84px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-bottom: 1rem;
  }
`;
