import moment from "moment";
import type { SetRequired } from "type-fest";

import { useSearchParams } from "@hotel-engine/hooks";

interface IQueryStringParams {
  checkIn: string;
  checkOut: string;
  defaultFocus?: "startDate";
  filters?: string;
  guestCount: string;
  roomCount: string;
  s?: string;
  sessionId?: string;
  extendedContractId?: string;
  roomRateId?: string;
}

export interface IPropertySearchParams {
  checkIn?: moment.Moment;
  checkOut?: moment.Moment;
  roomCount?: number;
  guestCount?: number;
  searchId?: number;
  extendedContractId?: string;
  defaultFocus?: "startDate";
  roomRateId?: number;
  sessionId?: string;
  failedRate?: string;
}

// After search these params will always be present.
export type PropertySearchParamsPostSearch = SetRequired<
  IPropertySearchParams,
  "checkOut" | "checkIn" | "roomCount" | "guestCount"
>;

/**
 * used to transform the search params to a modal that's easily reusable
 *
 * These values are expected to be undefined before search for rooms and then not undefined after searching for rooms
 */
export const usePropertySearchParams = () => {
  const queryStringParams = useSearchParams<IQueryStringParams>();

  return {
    checkIn: queryStringParams.checkIn && moment(queryStringParams.checkIn, "MM-DD-YYYY"),
    checkOut: queryStringParams.checkOut && moment(queryStringParams.checkOut, "MM-DD-YYYY"),
    roomCount: queryStringParams.roomCount && parseInt(queryStringParams.roomCount),
    guestCount: queryStringParams.guestCount && parseInt(queryStringParams.guestCount),
    searchId: queryStringParams.s && Number(queryStringParams.s),
    sessionId: queryStringParams.sessionId,
    extendedContractId: queryStringParams.extendedContractId,
    defaultFocus: queryStringParams.defaultFocus,
    roomRateId: Number(queryStringParams.roomRateId),
  } as IPropertySearchParams;
};
