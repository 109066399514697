import { useState } from "react";
import { ampli } from "ampli";

import * as Styled from "../../../../styles";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

type MapTypeToggleProps = {
  updateMapStyle: (val: string) => void;
};

const MapTypeToggle = ({ updateMapStyle }: MapTypeToggleProps) => {
  const [isBasicMap, setIsBasicMap] = useState(true);
  const searchId = useAppSelector(selectSearchId) ?? undefined;

  const handleStyleClick = (mapType: string) => {
    if (isBasicMap) {
      ampli.clickSatelliteView({ searchId });
    }
    updateMapStyle(mapType);
    setIsBasicMap((prev) => !prev);
  };

  const buttonVal = isBasicMap ? "Satellite" : "Basic";

  return (
    <Styled.GoogleMapControlButtonRebrand
      aria-label={buttonVal}
      data-testid="map-type-toggle"
      id={buttonVal}
      onClick={() => handleStyleClick(isBasicMap ? "satellite" : "roadmap")}
    >
      {buttonVal}
    </Styled.GoogleMapControlButtonRebrand>
  );
};
export default MapTypeToggle;
