import LogRocket from "logrocket";
import { datadogRum } from "@datadog/browser-rum";
import { useRef } from "react";

type LogRocketTrackEventProperties = {
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

/**
 * Logs an event to LogRocket and DataDog.
 * @param eventName - The name of the event.
 * @param payload - Optional payload associated with the event.
 */
export const logToLogRocketAndDataDog = (eventName: string, payload?: object) => {
  try {
    const actionEventName = `action__${eventName}`;
    if (LogRocket && LogRocket.info) {
      LogRocket.info(actionEventName, payload);
    }

    if (datadogRum && datadogRum.addAction) {
      datadogRum.addAction(actionEventName, {
        ...payload,
        logRocketSessionLink: LogRocket.sessionURL,
      });
    }
  } catch (e) {
    console.error("Error logging to LogRocket and DataDog", e);
  }
};

export const useTrackOnce = () => {
  const hasTracked = useRef<Record<string, boolean>>({});

  /**
   * Tracks an event using LogRocket only once for a given key.
   * This function is specific to LogRocket.
   *
   * @param key - The key used to identify if the event has been tracked before.
   * @param eventName - The name of the event to track.
   * @param payload - Optional payload to include with the event.
   */
  const trackToLogRocketOnce = (
    key: string,
    eventName: string,
    payload?: LogRocketTrackEventProperties
  ) => {
    {
      if (!hasTracked.current[key]) {
        LogRocket?.track(eventName, payload);
        hasTracked.current[key] = true;
      }
    }
  };
  return trackToLogRocketOnce;
};
