import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Icon` component from `@hotelengine/atlas-web` paired with the component's `animationStyle` prop.
 * @see {@link https://atlas.he-dev.com/latest/components/icon-eeqv1LXW Atlas Web Icon Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-icon--docs Atlas Web icon Storybook}
 *
 * @summary The `Spin` component wraps antd's `<Spin />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Spin component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/spin/ AntD Spin Documentation}
 */
const Spin = Styled.Spin;

export default Spin;
