import { type WexPromptsField } from "@hotel-engine/types/paymentProfile";

export const FieldsErrorList: Record<string, WexPromptsField> = {
  driver_number: "driverNumber",
  trip_number: "tripNumber",
  drivers_license_number: "driversLicenseNumber",
  unit_number: "unitNumber",
  odometer: "odometer",
  po_number: "poNumber",
  job_number: "jobNumber",
  trailer_number: "trailerNumber",
  vehicle_number: "vehicleNumber",
};

export const formatErrorReason = (reason: string) => {
  return reason.charAt(0).toUpperCase() + reason.slice(1).toLowerCase();
};
