import { useCallback, useRef } from "react";
import type { AxiosRequestConfig } from "axios";
import { v4 as uuidV4 } from "uuid";
import { datadogLogs } from "@datadog/browser-logs";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { type IRequestType, useApi } from "../useApi";
import { DataDogTracingAttributes, LssRequestHeaders } from "@hotel-engine/constants/search";
import config from "config";
import { selectSessionId } from "store/Search/SearchResults/selectors";
import { searchResultsActions } from "store/Search/SearchResults";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericOptions = Record<string, any>;

/**
 *
 * @param requestMethod - HTTP request method
 * @param options - React query options
 * @param createNewSessionId  - Boolean to control if a new session ID should be created and saved in state
 * @param incomingSessionId - Optional session ID to use if createNewSessionId is false. If not provided, a new session ID will be created.
 * @returns Object which contains the headers, request function, and options to use in react query query or mutation
 */
export const useLodgingSearchApi = <
  ResponseType extends GenericOptions,
  OptionsType extends GenericOptions = GenericOptions,
>({
  createNewSessionId = false,
  incomingSessionId,
  options,
  requestMethod = "get",
}: {
  createNewSessionId?: boolean;
  incomingSessionId?: string;
  options?: OptionsType;
  requestMethod?: IRequestType;
}) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.Auth.user?.id);
  const businessId = useAppSelector((state) => state.Auth.user?.businessId);
  const stateSessionId = useAppSelector(selectSessionId);
  const sessionId = useRef(uuidV4());
  const requestId = useRef(uuidV4());
  const traceId = useRef(uuidV4());
  const requestPath = useRef<string>();
  const requestFn = useApi(requestMethod);

  const setSessionId = useCallback(() => {
    if (createNewSessionId || !stateSessionId) {
      sessionId.current = createNewSessionId ? uuidV4() : incomingSessionId ?? uuidV4();
      dispatch(searchResultsActions.setSessionId(sessionId.current));
    } else {
      sessionId.current = stateSessionId;
    }
  }, [createNewSessionId, dispatch, incomingSessionId, stateSessionId]);

  const buildHeaders = () => {
    setSessionId();

    return {
      [LssRequestHeaders.RequestId]: requestId.current,
      [LssRequestHeaders.SessionId]: sessionId.current,
      [LssRequestHeaders.TraceId]: traceId.current,
    };
  };

  const buildLogContext = () => ({
    [DataDogTracingAttributes.BusinessId]: businessId,
    [DataDogTracingAttributes.RequestId]: requestId.current,
    [DataDogTracingAttributes.SessionId]: sessionId.current,
    [DataDogTracingAttributes.TraceId]: traceId.current,
    [DataDogTracingAttributes.UserId]: userId,
  });

  /** The websocket search will need to input the websocket URL path since it is formed outside of this hook */
  const logSuccess = (incomingRequestPath?: string) => {
    datadogLogs.logger.info(
      `Request to ${incomingRequestPath ?? requestPath.current} completed successfully`,
      buildLogContext()
    );
  };

  /** The websocket search will need to input the websocket URL path since it is formed outside of this hook */
  const logError = (error: Error, incomingRequestPath?: string) => {
    datadogLogs.logger.error(
      `Unable to complete ${incomingRequestPath ?? requestPath.current} request`,
      buildLogContext(),
      error
    );
  };

  return {
    buildHeaders,
    executeRequest: (lssPath: string, payload = {}, requestConfig: AxiosRequestConfig = {}) => {
      requestPath.current = lssPath;
      requestId.current = uuidV4();
      traceId.current = uuidV4();

      return requestFn<ResponseType>(
        lssPath,
        payload,
        {
          baseURL: config.lssHost,
          ...requestConfig,
          headers: {
            ...buildHeaders(),
            ...requestConfig.headers,
          },
        },
        false,
        true
      );
    },
    logError,
    logSuccess,
    queryOptions: {
      ...options,
      onSuccess: (...args) => {
        options?.onSuccess?.(...args);
        logSuccess();
      },
      onError: (error, ...args) => {
        options?.onError?.(error, ...args);
        logError(error);
      },
    },
  };
};
