import { Box, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

/**
 * This component returns the styled JSX for the error message that is passed in as a prop to the Atlas `toast` `description` function
 */
export const ToastErrorMessage = ({ error = "" }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap={8} marginTop={4}>
      {error}
      <Styled.ReloadLink onClick={() => globalThis.location.reload()}>
        <Typography variant="body/md" color="foregroundPrimary">
          Reload page
        </Typography>
      </Styled.ReloadLink>
    </Box>
  );
};
