import type { IAchPaymentProfile } from "./achPaymentProfile";
import type { IAutopayMethod } from "./autopay";

/** Wex payment profile */
export const WexPromptsFields = [
  "driverNumber",
  "tripNumber",
  "driversLicenseNumber",
  "unitNumber",
  "odometer",
  "poNumber",
  "jobNumber",
  "trailerNumber",
  "vehicleNumber",
] as const;

export type WexPromptsField = (typeof WexPromptsFields)[number];

/** Direct bill credit card payment profile. */

export type IPaymentProfileBase = {
  /** PK */
  id: number;
  /** FK for Business */
  businessId: number | null;
  /** Card expiration month */
  expirationMonth: string;
  /** Card expiration year */
  expirationYear: string;
  /** Masked credit card number */
  cardNumber: string;
  /** Last 4 digits of the card number */
  last4: string;
  /** Cardholder name */
  name: string;
  /** Displayed name for the method */
  nickname?: string | null;
  /** Default payment method */
  default: boolean;
  duplicate?: boolean;
  isExpired: boolean;
};

export type CreditDebitCardPaymentProfile = IPaymentProfileBase & {
  type: spreedly.SpreedlyCardType;
  spreedlyFingerprint: string;
  billingPostalCode: string;
};

export type WexCardPaymentProfile = IPaymentProfileBase & {
  type: "wex";
  basisTheoryToken: string;
  metadata: Partial<Record<WexPromptsField, string>>;
};

export type IPaymentProfile = CreditDebitCardPaymentProfile | WexCardPaymentProfile;

//  This payment profile type is coming in the response while updating
export interface INewPaymentProfile {
  billingName: string;
  billingPostalCode: string;
  businessId: string | null;
  cardExpMonth: string;
  cardExpYear: string;
  collectionName: string;
  createdAt: string;
  creditCardNum: string;
  creditCardType: spreedly.SpreedlyCardType;
  displayName: string;
  firstName: string;
  id: number;
  lastName: string;
  status: string;
  updatedAt: string;
  userId: number;
}

/** type guard for IPaymentProfile */
export function isCreditCardPaymentProfile(
  profile: IPaymentProfile | IAchPaymentProfile | IAutopayMethod | undefined
): profile is IPaymentProfile {
  const p = profile as IPaymentProfile;
  return p.type !== "wex" && p.cardNumber !== undefined;
}

export const isPaymentMethodValid = (payment?: IPaymentProfile) => {
  const isWexPaymentMethod = payment?.type === "wex";

  if (isWexPaymentMethod) return payment && !payment.isExpired;
  return payment && !!payment.billingPostalCode && !payment.isExpired;
};

/** Credit card payment profile params whitelisted for mutation this list is the same for both create & update in the BE.  */
export type CreditDebitCardPaymentProfileParams = Partial<
  Pick<
    CreditDebitCardPaymentProfile,
    | "billingPostalCode"
    | "businessId"
    | "expirationMonth"
    | "expirationYear"
    | "type"
    | "default"
    | "name"
    | "cardNumber"
    | "nickname"
    | "spreedlyFingerprint"
  >
>;

export type WexCardPaymentProfileParams = Partial<
  Pick<
    WexCardPaymentProfile,
    | "nickname"
    | "name"
    | "default"
    | "expirationYear"
    | "expirationMonth"
    | "basisTheoryToken"
    | "last4"
    | "metadata"
  >
>;

/** Payment profile create params. */
export type ICreditCardPaymentProfileCreateParams = {
  cardType: spreedly.SpreedlyCardType;
} & CreditDebitCardPaymentProfileParams;

export type IWexCardPaymentProfileCreateParams = {
  cardType: "wex";
  maskedCardNumber?: string;
} & WexCardPaymentProfileParams;

export type IPaymentProfileCreateParams =
  | ICreditCardPaymentProfileCreateParams
  | IWexCardPaymentProfileCreateParams;

/** Payment profile update params. */
export type ICreditCardPaymentProfileUpdateParams = CreditDebitCardPaymentProfileParams &
  Pick<IPaymentProfile, "id">;

export type IWexCardPaymentProfileUpdateParams = Omit<
  WexCardPaymentProfile,
  "cardNumber" | "metadata.driversLicenseNumber"
> &
  Pick<IPaymentProfile, "id">;

export type IPaymentProfileUpdateParams =
  | ICreditCardPaymentProfileUpdateParams
  | IWexCardPaymentProfileUpdateParams;
