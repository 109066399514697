import moment from "moment";
import * as yup from "yup";

export const wexFormSchema = yup.object({
  cardNumber: yup.string().optional(),
  expDate: yup
    .string()
    .optional()
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Expiration date must be in MM/YY format")
    .test("is-valid-date", "Expiration date is invalid or expired", (value) => {
      if (!value) return true;

      const expDate = moment(value, "MM/YY", true);
      if (!expDate.isValid()) return false;

      return expDate.endOf("month").isAfter(moment());
    }),
  nickname: yup.string().max(20).optional(),
  driverNumber: yup.string().max(20).optional(),
  tripNumber: yup.string().max(20).optional(),
  driversLicenseNumber: yup.string().max(20).optional(),
  unitNumber: yup.string().max(20).optional(),
  odometer: yup.number().optional(),
  poNumber: yup.string().max(20).optional(),
  trailerNumber: yup.string().max(20).optional(),
  jobNumber: yup.string().max(20).optional(),
  vehicleNumber: yup.string().max(20).optional(),
  default: yup.boolean().default(false),
});

export type WexFormValues = yup.InferType<typeof wexFormSchema>;
