import * as Styled from "./styles";

import EmptyInbox from "../EmptyInbox";
import NotificationItem from "../NotificationItem";

import type { ICourierMessage } from "@hotel-engine/types/courierNotifications";

interface INotificationInboxContent {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  messages: ICourierMessage[];
  markMessageArchived: (messageId: string, archiveTrackingId: string) => void;
  markMessageRead: (messageId: string, readTrackingId: string) => void;
  markMessageUnread: (messageId: string, unreadTrackingId: string) => void;
}

export const NotificationInboxContent = ({
  isLoading,
  isEmpty,
  isError,
  messages,
  markMessageArchived,
  markMessageRead,
  markMessageUnread,
}: INotificationInboxContent) => {
  if (isLoading) {
    return (
      <Styled.LoaderWrap>
        <Styled.NotificationSkeleton height={72} />
        <Styled.NotificationSkeleton height={72} />
        <Styled.NotificationSkeleton height={72} />
      </Styled.LoaderWrap>
    );
  }

  if (isError || isEmpty) {
    return <EmptyInbox isError={isError} />;
  }

  return (
    <Styled.NotificationWrap>
      {messages
        .filter(({ trackingIds }) => trackingIds)
        .map((message) => (
          <NotificationItem
            key={message.messageId}
            markMessageArchived={markMessageArchived}
            markMessageRead={markMessageRead}
            markMessageUnread={markMessageUnread}
            message={message}
          />
        ))}
    </Styled.NotificationWrap>
  );
};
