import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Button` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/button-1YhsYKM6 Atlas Web Button Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-button--docs Atlas Web Button Storybook}
 *
 * @summary The `Button` component wraps antd's `<Button />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Button component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/button/ AntD Button Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=4387%3A34332 Design Specs}
 */
const Button = Styled.AntButton;

export type { ButtonProps } from "antd/lib/button";
export default Button;
