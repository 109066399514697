import type { FieldProps } from "formik";
import type { ReactNode } from "react";

import { FormItem } from "@hotel-engine/common/Form";
import { TextArea } from "@hotel-engine/common/Inputs";

import { getNestedProperty } from "../helpers";

export interface ITextAreaFieldProps extends FieldProps {
  /** manually applied help text or error message */
  help?: string;
  /** form field label */
  label?: string;
  /** if form is multistep then different validation rules will apply */
  isMultiStep?: boolean;
  /* extra information that will be under the field */
  extra?: string | ReactNode;
  /** any other props to pass to ant input (e.g. label, placeholder, etc.) */
  [x: string]: unknown;
}

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `TextArea` and `FormControl` components from `@hotelengine/atlas-web`.
 * @see {@link https://atlas.he-dev.com/latest/components/text-area-91qWEB5x Atlas Web TextArea Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-text-area--docs Atlas Web TextInput Storybook}
 * @see {@link https://atlas.he-dev.com/latest/components/form-control-bFx0WsJj Atlas Web FormControl Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-form-control--docs Atlas Web FormControl Storybook}
 *
 * @summary The `TextAreaField` component is a wrapper around antd's FormItem and their Input's TextArea components. It requires the use of formik and their
 * `<Field />` component.
 *
 * @remarks Props are spread onto the antd textarea, so we have full control over antd's textarea in this component
 * @example <Field component={TextAreaField} label="Notes" name="notes" />
 * @see {@link https://3x.ant.design/components/input/#components-input-demo-textarea/ AntD Input Documentation}
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=870%3A4 Design Specs}
 */
const TextAreaField = ({
  field,
  form: { submitCount, touched, errors },
  isMultiStep = false,
  help,
  label,
  extra,
  ...props
}: ITextAreaFieldProps) => {
  const fieldTouched = getNestedProperty(touched, field.name, ".");
  const fieldError = getNestedProperty(errors, field.name, ".");
  const showValidation = isMultiStep ? fieldTouched : submitCount || fieldTouched;
  const validateStatus = showValidation && fieldError && "error";
  const helpText = (showValidation && fieldError) || help;

  return (
    <FormItem
      colon={!!props.colon}
      help={helpText}
      htmlFor={field.name}
      label={label}
      validateStatus={validateStatus}
      extra={extra}
    >
      <TextArea {...field} {...props} id={field.name} />
    </FormItem>
  );
};

export default TextAreaField;
