import type { ReactNode } from "react";

import classnames from "classnames";
import type { FieldProps } from "formik";

import type { CheckboxProps } from "@hotel-engine/common/Checkbox";
import { Checkbox } from "@hotel-engine/common/Checkbox";
import { FormItem } from "@hotel-engine/common/Form";

import { getNestedProperty } from "../helpers";
import * as Styled from "./styles";

export interface ICheckboxClasses {
  /** label classname */
  label?: string;
}

export interface ICheckboxFieldProps extends FieldProps, CheckboxProps {
  /** object for classes to be assigned at child level */
  classes?: ICheckboxClasses;
  /** label for checkbox. can also be markup */
  label?: string | ReactNode;
  /** if form is multistep then different validation rules will apply */
  isMultiStep?: boolean;
  /** manually applied help text or error message */
  help?: string;
  description?: string;
  blurredDescription?: boolean;
}

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Checkbox` and `FormControl` components from `@hotelengine/atlas-web`.
 * @see {@link https://atlas.he-dev.com/latest/components/checkbox-aunCUPLt Atlas Web Checkbox Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-checkbox--docs Atlas Web Checkbox Storybook}
 * @see {@link https://atlas.he-dev.com/latest/components/form-control-bFx0WsJj Atlas Web FormControl Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-form-control--docs Atlas Web FormControl Storybook}
 *
 * @summary The `CheckboxField` component is a wrapper around antd's FormItem and Checkbox components. It requires the use of formik and their
 * `<Field />` component.
 *
 * @remarks Props are spread onto the antd input, so we have full control over antd's checkbox in this component
 * @example <Field component={CheckboxField} label="Allowed" name="isAllowed" />
 * @see {@link https://3x.ant.design/components/checkbox/ AntD Checkbox Documentation}
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=870%3A667 Design Specs}
 */
const CheckboxField = ({
  classes,
  field,
  form: { errors, submitCount, touched },
  isMultiStep = false,
  ...props
}: ICheckboxFieldProps) => {
  const fieldTouched = getNestedProperty(touched, field.name, ".");
  const fieldError = getNestedProperty(errors, field.name, ".");
  const showValidation = isMultiStep ? fieldTouched : submitCount || fieldTouched;
  const validateStatus = showValidation && fieldError && "error";
  const help = (showValidation && fieldError) || props.help;

  return (
    <FormItem validateStatus={validateStatus} help={help}>
      <Checkbox {...field} {...props} checked={!!field.value} defaultChecked={false}>
        <span className={classnames(!props.disabled && classes?.label)}>{props.label}</span>
        {!!props.description && (
          <>
            <br />
            <Styled.FieldDescription blurred={props.blurredDescription}>
              {props.description}
            </Styled.FieldDescription>
          </>
        )}
      </Checkbox>
    </FormItem>
  );
};

export default CheckboxField;
