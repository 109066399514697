import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const PromptsContainer = styled<React.FC<BoxProps>>(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;
