// eslint-disable-next-line import/no-named-as-default
import DOMPurify from "dompurify";

/**
 * @param html html string to be sanitized
 * @returns sanitized html using DOMPurify https://github.com/cure53/DOMPurify
 */
export function sanitizeHtml(html: string | Node): string {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml;
}

/**
 * @param html html string to be sanitized
 * @param sanitizer custom sanitizer function
 * @returns sanitized html using DOMPurify https://github.com/cure53/DOMPurify
 */
export function sanitizeHtmlWithCustomSanitizer(
  html: string | Node,
  sanitizer: (html: string | Node) => string
): string {
  const cleanHtml = sanitizer(html);
  return cleanHtml;
}
