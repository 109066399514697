import { forwardRef, useState } from "react";
import { TextElement, type TextElementProps } from "@basis-theory/basis-theory-react";
import type {
  ChangeEvent,
  InputBlurEvent,
  TextElement as ITextElement,
} from "@basis-theory/basis-theory-react/types";
import { Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

export type BasisTheoryInputProps = React.PropsWithChildren<
  {
    id: string;
    label: string;
    error?: string;
    hint?: string;
  } & TextElementProps
>;

export const BasisTheoryInput = forwardRef<ITextElement, BasisTheoryInputProps>((props, ref) => {
  const { id, label, onChange, onBlur, error, disabled, hint, children, ...rest } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: InputBlurEvent) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleChange = (e: ChangeEvent) => {
    onChange?.(e);
  };

  const hasError = !!error;

  return (
    <Styled.Container display="flex" flexDirection="column">
      <Typography as="label" variant="body/sm-light" htmlFor={id} marginBottom={4}>
        {label}
      </Typography>
      <Styled.InputContainer $hasError={hasError} $isFocused={isFocused} $isDisabled={disabled}>
        <TextElement
          {...rest}
          id={id}
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          style={Styled.TextElement}
        />
        {children}
      </Styled.InputContainer>
      <Styled.Hint $hasError={hasError}>{error ?? hint}</Styled.Hint>
    </Styled.Container>
  );
});

BasisTheoryInput.displayName = "BasisTheoryInput";
