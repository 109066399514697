import type { Moment } from "moment";

export enum TrendsTabs {
  ALL = "all",
  BOOKERS = "bookers",
  TRAVELERS = "travelers",
  DEPARTMENTS = "departments",
  TRAVEL_POLICIES = "travel-policies",
  TOP_LOCATIONS = "top-locations",
  TOP_PROPERTIES = "top-properties",
  TOP_VENDORS = "top-vendors",
}

export enum CategoryTypes {
  department = "department",
  member = "member",
  travel_policy = "travel_policy",
  location = "location",
  hotels = "hotels",
  custom_field = "custom_field",
  traveler = "traveler",
}

export type TrendsFilterMembers = {
  email: string;
  name?: string;
};

export type TrendsFilterLocation = {
  state: string;
  city: string;
  zip: number;
  country: string;
};

export type TrendsFilterResponse = {
  id: number;
  searchValue: string | TrendsFilterMembers | TrendsFilterLocation;
};

export function isMemberSearch(
  searchType: TrendsFilterMembers | unknown
): searchType is TrendsFilterMembers {
  return (searchType as TrendsFilterMembers).email !== undefined;
}

export type ITrendsGroupBy = "year" | "week" | "month" | "day";

export type IReportType = "lodging" | "flights" | "car_rentals" | "all_trips";

export type IFilterType = "member" | "department" | "travel_policy" | "custom_field" | "traveler";

export interface ITrendsParams {
  businessId?: number;
  groupBy: ITrendsGroupBy;
  startDate: string;
  endDate: string;
  query?: string | null;
  category?: string | null;
  customFieldId?: number | null;
}
export interface ITrendsReportParams {
  startDate: string;
  endDate: string;
  userId: string;
  format: "json";
  bcc_emails?: string;
}
export interface ITrendsConsolidatedParams {
  businessId: number;
  startDate: string;
  endDate: string;
  query: string | null;
  category: string | null;
  customFieldId: number | null;
  groupBy: {
    bookingsParams: ITrendsGroupBy;
    spendingQueryParams: ITrendsGroupBy;
    averageRateParams: ITrendsGroupBy;
    businessStatsParams: ITrendsGroupBy;
  };
}

export interface IBookingsData {
  ranges: {
    start: string;
    end: string;
  }[];
  bookings: number[];
  refundableBookings: number[];
  nonRefundableBookings: number[];
  totalBookings?: number;
}

export type BookingType = "flight" | "lodging" | "car_rental";

export interface ITrendsBookingsData {
  bookingNumber: string;
  bookingType: BookingType;
  travelers: string[];
  departments: string;
  spending: number;
  savings: number;
  vendor: string;
  dateBooked: string;
}

export interface ITrendsBookersData {
  booker: string;
  role: string;
  department: string;
  spending: number;
  savings: number;
  adr: number;
  bookings: number;
  bookingFor: number;
}

export interface ITrendsBookerByAdr {
  fullName: string;
  adr: string;
}

export interface ITrendsBookersByAdrData {
  topBookers: ITrendsBookerByAdr[];
  adrPerBooker: string;
}

export interface ITrendsDepartmentByQuantity {
  name: string;
  bookings: number;
  variation: string;
}
export interface ITrendsBookerByQuantity {
  fullName: string;
  bookings: number;
  variation: string;
}

export interface ITrendsDepartmentsByQuantityQuery {
  topDepartments: ITrendsDepartmentByQuantity[];
  totalBookings: number;
  totalDepartments: number;
}
export interface ITrendsBookersByQuantity {
  topBookers: ITrendsBookerByQuantity[];
  totalBookings: number;
  totalBookers: number;
}

export interface ITrendsTravelersData {
  traveler: string;
  department: string;
  spending: number;
  savings: number;
  adr: number;
  bookings: number;
}

export interface ITrendsMemberTravelingItem {
  fullName: string;
}

export interface ITrendsMembersTraveling {
  travelers: ITrendsMemberTravelingItem[];
  totalTravelers: number;
}

export interface ITrendsTravelerBySpend {
  fullName: string;
  bookings: number;
  spending: string;
}

export interface ITrendsTravelersOthers {
  quantityOfOthers: number;
  bookings: number;
  spending: string;
}

export interface ITrendsTravelersBySpend {
  topTravelers: ITrendsTravelerBySpend[];
  others: ITrendsTravelersOthers;
  averageSpendingPerTraveler: string;
}

export interface ITrendsTravelPoliciesData {
  travelPolicy: string;
  spending: string;
  savings: string;
  bookings: number;
  topBooker: string;
  topTraveler: string;
}

export interface ISpendingData {
  averageSpending: (string | number)[];
  ranges: {
    start: string;
    end: string;
  }[];
  savings: number[];
  spend: number[];
  summary: {
    averageRate: number;
    totalSavings: number;
    totalSpend: number;
  };
}

export interface ITrendsDefaultParams {
  startDate: Moment | null;
  endDate: Moment | null;
  reportType: IReportType;
  filterType?: IFilterType;
  filterResourceId?: number | null;
  filterValue?: string;
  groupBy?: ITrendsGroupBy;
  offset?: number;
  limit?: number;
  includeTaxesAndFees: boolean;
}

export interface IChartSpendingData {
  bookings: number[];
  totalSpend: string[];
  lodgingSpend: string[];
  flightsSpend: string[];
  carRentalsSpend: string[];
  dates: string[];
  variation: string;
}

export interface IChartAdrData {
  adr: string[];
  dates: string[];
}

export interface IChartSavingsData {
  bookings: number[];
  savings: string[];
  originalCost: string[];
  dates: string[];
  variation: string;
}

export interface IChartNightsData {
  bookings: number[];
  dates: string[];
  nights: number[];
  totalNights: number;
}

export type ReportDateRangeStatus = "default" | "custom";

export type DateRangeFilterValue =
  | "check_in_date"
  | "date_booked"
  | "night_of_stay"
  | "departure_date"
  | "pickup_date"
  | "dropoff_date";

export interface ITrendsBookersByQuantityItem {
  name?: string;
  fullName?: string;
  bookings: number;
  variation: string;
  vendorType?: TrendsVendorType;
}

export interface ITrendsBySpendingItem {
  fullName: string;
  bookings: number;
  spending: string;
}

export interface ITrendsBookersByADRItem {
  fullName: string;
  adr: string;
}

export interface ITrendsDepartmentsByAdrItem {
  name: string;
  adr: string;
}

export interface ITrendsDepartmentsBySpendingItem {
  name: string;
  bookings: number;
  spending: string;
}

export interface ITrendsDepartmentsData {
  department: string;
  spending: string;
  savings: string;
  topBooker: string;
  topTraveler: string;
  bookings: number;
}

export interface ITrendsDepartmentsAdrData {
  topDepartments: ITrendsDepartmentsByAdrItem[];
  averageAdrOfDepartments: string;
}

export interface ITrendsDepartmentsSpendingData {
  topDepartments: ITrendsDepartmentsBySpendingItem[];
  averageSpendingPerDepartment: string;
}

export type BookersByQuantity = {
  items: ITrendsBookersByQuantityItem[];
  totalBookings: number;
  totalItems: number;
};

export type BookersBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerBooker: string;
};

export type IBookersBySpending = {
  topBookers: ITrendsBySpendingItem[];
  averageSpendingPerBooker: string;
};

export type ITravelersByAdrResponse = {
  topTravelers: ITrendsBookersByADRItem[];
  averageAdrPerTraveler: string;
};

export type TravelersBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerTraveler: string;
};

export type TravelersByADR = {
  items: ITrendsBookersByADRItem[];
  averageAdrPerTraveler: string;
};

export type BookersByADR = {
  items: ITrendsBookersByADRItem[];
  adrPerBooker: string;
};

export type ITrendsDepartmentsByAdr = {
  items: ITrendsDepartmentsByAdrItem[];
  averageAdrOfDepartments: string;
};

export type ITrendsDepartmentsBySpending = {
  items: ITrendsDepartmentsBySpendingItem[];
  averageSpendingPerDepartment: string;
};

// The following types are for Trends 3-in-1. The legacy types above should be removed after Trends 3-in1 is roll out

export type MetricsType = "spending" | "savings" | "he_benefits" | "average" | "total";
export type TopItemsWidgetType = "quantity" | "spend" | "adr" | "acu";

export interface ISpendingOrSaving {
  value: string;
  variation: string;
  totalBookings: number | string;
  averageCostPerUnit: string;
}

export interface IHeBenefits {
  total: string;
  heRewards: string;
  flexSavings: string;
  flexProSavings: string;
  travelCredits: string;
}

export interface IMetricsBillboardData {
  spending: ISpendingOrSaving;
  savings: ISpendingOrSaving;
  heBenefits: IHeBenefits;
}

export interface IChartData {
  name: string;
  mainData: string | number;
  secondaryData: string | number;
  untruncatedString?: string;
}

export type DepartmentsByQuantity = {
  items: ITrendsDepartmentByQuantity[];
  totalBookings: number;
  totalItems: number;
};

export type DepartmentsBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerBooker: string;
};

export type DepartmentsByADR = {
  items: ITrendsBookersByADRItem[];
  adrPerBooker: string;
};

export interface IBarColors {
  max: string;
  others: string;
}

export interface ISummaryMetricData {
  label: string;
  value?: string;
}

export interface ISummaryMetric {
  reportType: IReportType;
  data: ISummaryMetricData[];
}

export interface IStays {
  averageDailyRate: number;
  averageLengthOfStay: number;
  averageBookingLeadTime: number;
  cancellations: number;
}
export interface IFlights {
  averageCostPerFlight: number;
  averageBookingLeadTime: number;
  cancellations: number;
}
export interface ICarRentals {
  averageDailyRentalRate: number;
  averageLengthOfRental: number;
  averageBookingLeadTime: number;
  cancellations: number;
}

export interface ISummaryMetricsData {
  stays: IStays;
  flights: IFlights;
  carRentals: ICarRentals;
}

export type TrendsVendorType = "airline" | "car_rental_company" | "hotel";

export type VendorsByQuantity = {
  name: string;
  bookings: number;
  variation: string;
  vendorType?: TrendsVendorType;
};
export interface ITrendsVendorsByQuantity {
  topVendors: VendorsByQuantity[];
  totalBookings: number;
  totalVendors: number;
}

export interface ITrendsVendorsBySpendingItem {
  name: string;
  bookings: number;
  spending: string;
  vendorType?: TrendsVendorType;
}

export type IVendorsBySpending = {
  topVendors: ITrendsVendorsBySpendingItem[];
  averageSpendingPerVendor: string;
};

export type VendorsBySpending = {
  items: ITrendsVendorsBySpendingItem[];
  averageSpendingPerVendor: string;
};

export type VendorsByAcu = {
  items: IVendorsByAcuItem[];
  averageAcuPerVendor: string;
};

export type TrendsVendor = {
  name: string;
  type: TrendsVendorType;
};
export interface ITrendsVendorBreakdownData {
  vendor: TrendsVendor;
  spending: number;
  bookings: number;
  savings: number;
  adr: number;
  averageCostPerUnit: string;
}

export interface IVendorsByAcuItem {
  name: string;
  vendorType: TrendsVendorType;
  averageCostPerUnit: string;
}

export interface ITrendsVendorsByAcu {
  topVendors: IVendorsByAcuItem[];
  averageAcuPerVendor: string;
}

export interface ITrendsLocationsBySpendingItem {
  name: string;
  bookings: number;
  spending: string;
}

export type ILocationsBySpending = {
  topLocations: ITrendsLocationsBySpendingItem[];
  averageSpendingPerLocation: string;
};

export type LocationsBySpending = {
  items: ITrendsLocationsBySpendingItem[];
  averageSpendingPerLocation: string;
};

export interface ITrendsLocationsByAdrItem {
  name: string;
  adr: string;
}

export interface ILocationsByAdr {
  topLocations: ITrendsLocationsByAdrItem[];
  averageAdrPerLocation: string;
}

export type LocationsByAdr = {
  items: ITrendsLocationsByAdrItem[];
  averageAdrPerLocation: string;
};
export interface ITrendsLocationsBreakdown {
  location: string;
  spending: string;
  savings: string;
  adr: string;
  bookings: number;
  topBooker: string;
  topTraveler: string;
}

export interface IScheduledReportType {
  id?: number;
  reportCategory: "all_hotel_bookings" | "all_flight_bookings" | "all_car_rental_bookings";
  dateRangeType: DateRangeFilterValue;
  listNightsSeparate?: boolean;
  listMultiRoomSeparate?: boolean;
  createdAt?: string;
  updatedAt?: string;
  _destroy?: boolean;
}
export interface IScheduledReport {
  id?: number;
  name: string;
  businessId?: number;
  userId?: number;
  reportType: "weekly" | "monthly" | "daily";
  dayOfWeek: string | null;
  dayOfMonth?: number | null;
  emails: string[];
  scheduledReportTypes: IScheduledReportType[];
}

export interface IScheduledReportResponse {
  scheduledReports: IScheduledReport[];
}
