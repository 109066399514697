import type { IUser } from "@hotel-engine/types/user";

import type { AuthValidatorFn } from "../helpers";

/** Blocks route access if user account does not have reporting access enabled */
const allowAdminRoleRequests: AuthValidatorFn = (user: IUser) => {
  return user.allowAdminRoleRequests;
};

export { allowAdminRoleRequests };
