import { useAppSelector } from "store/hooks";
import { Alert } from "@hotelengine/atlas-web";

export const ImpersonationBanner = () => {
  const user = useAppSelector((state) => state.Auth?.user);

  if (!user?.impersonatorId) {
    return null;
  }

  return (
    <Alert
      sentiment="cautionary"
      title="You are logged in as an impersonated user. Log out to end session."
    >
      User ID: {user?.id} User Name: {user?.firstName} {user?.lastName}
    </Alert>
  );
};
