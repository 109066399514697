import { useTranslation } from "react-i18next";
import type { ACRISSCategory, ACRISSType } from "@hotel-engine/types/cars/cars.shared";

export const useAcrissInfo = () => {
  const { t } = useTranslation("cars");

  return {
    getAcrissCategory: (acrissCode: ACRISSCategory) => t(`acriss.category.${acrissCode}`, ""),
    getAcrissType: (acrissCode: ACRISSType) => t(`acriss.type.${acrissCode}`, ""),
    getAcrissCategoryType: (acrissCategoryCode: ACRISSCategory, acrissTypeCode: ACRISSType) => {
      const acrissCategory = t(`acriss.category.${acrissCategoryCode}`, "");
      const acrissType = t(`acriss.type.${acrissTypeCode}`, "car");

      return [acrissCategory, acrissType].join(" ");
    },
  };
};
