import type { Formatter } from "i18next";

import { formatCurrency } from "@hotel-engine/utilities";

export function addCustomFormatters(formatter?: Formatter) {
  formatter?.add("heCurrency", (value: string, _, options) => {
    return formatCurrency(
      value,
      options?.formatParams?.displayDecimals || true,
      options?.formatParams?.currencyCode
    );
  });
}
