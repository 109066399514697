import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IAuthState } from "./types";
import type { IUser } from "@hotel-engine/types/user";

export const initialState: IAuthState = {
  user: null,
};

// this action is used to clear the user data from the store
export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => ({
      ...state,
      user: action.payload,
    }),
    clearUserData: () => initialState,
  },
});

export const AuthActions = authSlice.actions;

export default authSlice.reducer;
