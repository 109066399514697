import { useCallback, useEffect, useRef } from "react";

import { useAppSelector } from "store/hooks";

import { useIsFeatureFlagOn } from "../Experiments";
import { getAuthStatus, setAuthStatus } from "@hotel-engine/utilities/auth/events";
import { toast } from "@hotelengine/atlas-web";

import { isUsersJWTExpired } from "./utils";
import { Unsafe } from "@hotel-engine/data";

interface IUseSetIntervalArgs {
  /**
   * How often to check the token's expiration time (in seconds).
   */
  secondsIntervalToCheckToken?: number;
}

/**
 * The goal of this hook is to force log-out the user at a time
 * when they are least likely to be using the app.
 * For example: 3:00am locally to the user
 *
 * The way we do this is by modifying the actual token expiration time,
 * to be sooner than it actually is, trying to expire it about 3:00am
 * local to their time (in the middle of the night). The ideal case
 * would be that when they wake up and start their Engine session
 * for that day, that they'll initially be logged out. This is more ideal
 * than letting the token expire when they're in the middle of using the app.
 *
 * We fetch the token's expiration time from the API, and then rewind the
 * time to the most recent occurrence of 3am (same or previous day). Then
 * we write that new time to local storage. Then we start a `setInterval()`
 * which periodically checks if that time has passed. If it has, then we
 * log them out (either forcefully, or gracefully with a same-page modal).
 */
export function useCheckSessionExpirationInterval({
  secondsIntervalToCheckToken = 1800, // 1800s = 30 minutes
}: IUseSetIntervalArgs = {}) {
  const isFFEnabled = useIsFeatureFlagOn("session_expiration_interval");

  /**
   * If the user isn't currently logged in, then there's no need
   * to fetch their JWT's expiration date, since it'll return a 401.
   * Also, if the user is an internal Engine employee who is impersonating
   * a real user, then the JWT will only be valid for 1 hour, so we
   * don't want to check the expiration time in that case.
   */
  const userId = useAppSelector((store) => store.Auth?.user?.id);
  const impersonatorId = useAppSelector((store) => store.Auth?.user?.impersonatorId);
  const isUserLoggedIn = typeof userId === "number";
  const isUserImpersonating = typeof impersonatorId === "number";
  const authStatus = getAuthStatus();

  // Prevents simultaneous fetches
  const refIsAlreadyProcessing = useRef(false);

  /**
   * This will be invoked every time the setInterval occurs.
   * The reason we might want to force a refetch of the token
   * is if the user switches between business and personal accounts.
   */
  const checkTokenExpiration = useCallback(
    async (forceRefetch = false) => {
      if (
        !isUserLoggedIn ||
        !isFFEnabled ||
        isUserImpersonating ||
        refIsAlreadyProcessing.current === true
      ) {
        return;
      }

      refIsAlreadyProcessing.current = true;
      if (await isUsersJWTExpired(forceRefetch)) {
        // TODO - We can remove this console.log after JWT's have been enabled
        // and are stable in production for over 1 month.
        console.log("Signing out user due to early token expiration.");

        toast({
          title: "Session expired. Please sign in again.",
          icon: "circle-exclamation",
          sentiment: "neutral",
          duration: 8000,
        });
        setAuthStatus(false);
      }
      // check if auth status is false, if so, set it to true since we have a valid token
      else if (isUserLoggedIn && !authStatus) {
        setAuthStatus(true);
      }

      refIsAlreadyProcessing.current = false;
    },
    [isUserLoggedIn, isFFEnabled, isUserImpersonating, authStatus]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }, 1000 * secondsIntervalToCheckToken);

    return () => interval && clearInterval(interval);
  }, [checkTokenExpiration, secondsIntervalToCheckToken]);

  /**
   * Whenever the user's ID is set to a real value, then we want
   * to do an initial check of the token's expiration time.
   * For instance, when a user logs in, or when they switch
   * between personal account and business account.
   *
   * If someone logs out, then their userId will be emptied,
   * which will not cause this check to run (until they sign back in).
   */
  useEffect(() => {
    if (userId) {
      checkTokenExpiration(true).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [userId, checkTokenExpiration]);
}
