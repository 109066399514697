import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import styled, { css } from "styled-components";

export const VehicleDetails = styled<
  React.FC<BoxProps & { $isResults?: boolean; $isTrips?: boolean; $lessGap?: boolean }>
>(Box)`
  align-items: center;

  ${({ $isResults, $isTrips, $lessGap }) => {
    return $isResults || $isTrips
      ? css`
        display: flex;
        gap: ${({ theme }) => theme.spacing[$lessGap ? 8 : 12]};
        margin-bottom: ${({ theme }) => ($isTrips ? theme.spacing[8] : 0)};
        text-align: left;
        white-space: pre;
        font-size: 14px;
      `
      : css`
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0 8px;
      flex: 1;
      `;
  }}
`;
