import { Navigate } from "@hotel-engine/lib/react-router-dom";

import type { StandardFlagKey } from "@hotel-engine/types/featureFlag";
import { useAmplitudeContext } from "@hotel-engine/hooks";
import { useAllExperimentsQuery } from "@hotel-engine/react-query/amplitude/useExperimentQuery";

import { useHydrateUser } from "../helpers/useHydrateUser";
import type { ReactElement } from "react";

export interface IAmpliAuthRouteProps {
  /**
   * Render Props
   */
  children: (isUserLoggedInAndFFEnabled: boolean) => ReactElement;
  /** Single flag or array of flags, for an array it will return true if any of the listed flags are "on" */
  featureFlag: StandardFlagKey | StandardFlagKey[];
  /**
   * Probably "on"
   */
  ffValueWhenEnabled?: string;
  publicRoute?: boolean;
}

/**
 * If user not logged in, redirects to "/".
 * If user is logged in:
 *  If feature flag enabled, passes `true` to render
 *  If feature flag disabled, passes `false` to render
 */
export const RouteWithFeatureFlag = ({
  children,
  featureFlag,
  ffValueWhenEnabled = "on",
  publicRoute = false,
}: IAmpliAuthRouteProps) => {
  const { user, loading: hydrateUserIsLoading } = useHydrateUser();
  const { experimentSdk } = useAmplitudeContext();
  const { data, isLoading: featureFlagIsLoading } = useAllExperimentsQuery(experimentSdk);

  if (hydrateUserIsLoading || featureFlagIsLoading) {
    return null;
  }

  if (typeof featureFlag === "string") {
    const treatment = data === undefined ? "control" : data[featureFlag]?.value;

    if (user || publicRoute) {
      return children(treatment === ffValueWhenEnabled);
    }
  } else {
    const flagValues = featureFlag.map((flag) =>
      data === undefined ? "control" : data[flag]?.value
    );

    const areSomeFlagsEnabled = flagValues.some((value) => value === ffValueWhenEnabled);

    if (user || publicRoute) {
      return children(areSomeFlagsEnabled);
    }
  }

  return <Navigate replace to="/" />;
};
