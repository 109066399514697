import { logToLogRocketAndDataDog } from "@hotel-engine/utilities/logger/thirdPartyLogger";

type LogOptions = {
  location: "RouteProviders" | "PreloadAllChunks";
};

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = globalThis.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const MODULE_FAILED_LOCAL_STORAGE_KEY = "module_import_failed";
const moduleFailedMessages = [
  "Failed to fetch dynamically imported module",
  "Failed to resolve module specifier",
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchModuleMissingProtection = (error: any, logOptions: LogOptions) => {
  try {
    const errorMessage = error && error.message ? error.message : "";
    /* If the module is missing, we will get a type error.  We want to reload the page since the asset they are asking for isn't available */
    if (moduleFailedMessages.some((msg) => errorMessage.includes(msg))) {
      // This prevents infinite reloads if refreshing the index.html doesn't fix the issue
      if (!getWithExpiry(MODULE_FAILED_LOCAL_STORAGE_KEY)) {
        logToLogRocketAndDataDog("ModuleImportFailed", {
          ...logOptions,
          message: errorMessage,
        });
        setWithExpiry(MODULE_FAILED_LOCAL_STORAGE_KEY, "true", 10000);
        globalThis.location.reload();
      }
      return { default: () => <></> };
    }
  } catch (subError) {
    throw error;
  }
  throw error;
};
