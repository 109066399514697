import { datadogRum } from "@datadog/browser-rum";

import { tokenKey } from "@hotel-engine/constants";
import { queryClient } from "@hotel-engine/contexts";
import storage from "@hotel-engine/storage";
import { setToken } from "@hotel-engine/utilities/auth";
import { setAuthStatus } from "@hotel-engine/utilities/auth/events";
import { getRedirectLocation } from "@hotel-engine/utilities/helpers";
import { clearTokenExpirationInStorage } from "@hotel-engine/app/CheckSessionInterval/sessionTokenExpirationStorage";
import { Unsafe } from "@hotel-engine/data";

import config from "config";

import { store } from "store";
import { OrdersActions } from "store/Orders/OrdersRedux";
import { AuthActions } from "store/Auth/AuthRedux";

import Api from "./Api";
import User from "./User";

/**
 * The pathname passed to this function needs to be a relative path that
 * react-router understands. You can't pass a fully qualified url.
 *
 * Examples of the pathname:
 * Bad: "https://www.google.com"
 * Good: "/trips"
 */
type InternalNavigateFn = (relativePath: string) => void;

/**
 * @deprecated - switch to the associated react-query hooks if possible
 */
export default class Auth {
  /**
   * This is an intermediate step to help get us off this Auth class.
   * This is the first step to get this class to stop depending on globals
   * for performing page navigation.
   *
   * This needs to be initialized with a function at the earliest
   * possible moment we can in the App's init flow.
   *
   * It probably looks something like this:
   * ```
   * const rrHistory = useNavigate() // const navigate = useNavigate()
   *
   * useEffect(() => {
   *  Auth.setNavigateFn((pathName: string) => rrnavigate(pathName));
   * }, [])
   * ```
   */
  private static reactRouterNavigateFn: null | InternalNavigateFn = null;

  public static get hasNavigateFn() {
    return typeof Auth.reactRouterNavigateFn === "function";
  }

  public static setNavigateFn(newNavigateFn: InternalNavigateFn) {
    Auth.reactRouterNavigateFn = newNavigateFn;
  }

  private static navigateUsingNavigateFn(relativePath: string) {
    if (typeof Auth.reactRouterNavigateFn === "function") {
      Auth.reactRouterNavigateFn(relativePath);
    } else {
      // If for some reason the callback function isn't set,
      // which is should be set on app init, but if it's not,
      // then we can just fall back to a full page load
      // since this is almost certainly going to be '/' or '/login'
      globalThis.location.href = relativePath;
    }
  }

  public static redirect() {
    const path = Auth.getRedirectPath();
    Auth.navigateUsingNavigateFn(path);
  }

  public static getRedirectMatches() {
    return location.search.match(/[&?]return=([^&]+)/);
  }

  public static getRedirectPath() {
    let path = "/";

    const matches = Auth.getRedirectMatches();

    if (matches && matches.length && matches[1]) {
      const url = new URL(decodeURIComponent(matches[1]));
      // dirty check to prevent redirecting to /login after successful login.
      if (url.pathname == "/login") {
        path = "/";
      } else {
        path = url.pathname + url.search;
      }
    }
    return path;
  }

  public static getLoginRedirectPath() {
    return `${globalThis.location.origin}/?return=${encodeURIComponent(
      globalThis.location.origin + Auth.getRedirectPath()
    )}`;
  }

  public static async requestReset(email) {
    return await Api.post(`${config.apiHostV2}/forgotten_password_requests`, {
      email,
    });
  }

  public static async resetPassword(password, newPassword, disableRedirect = false) {
    const path = `${config.apiHostV2}/password`;
    const res: Record<string, string> = await Api.put(path, {
      newPassword,
      password,
      validateCurrentPasswordFeatureFlag: true,
    });

    setToken(res.accessToken);

    if (!disableRedirect) {
      setTimeout(() => this.redirect(), 500);
    }

    return res;
  }

  public static async signOut(sourceOfSignOut: string, persistStatus = true) {
    datadogRum.addAction("userSignOut", {
      sourceOfSignOut,
    });

    store.dispatch(OrdersActions.clearSelectedOrder());
    store.dispatch(AuthActions.clearUserData());
    queryClient.clear();
    //if the user signing out has this in the localstorage it means the firstTimeSignIn was already used therefore there's not
    //need for this localStorage value anymore (want to avoid have localStorage loaded for no reason)
    const onboardingModalKey = `doNotShowPTOnboardingModal_${User.current()?.id}`;
    if (!!storage.getItem(onboardingModalKey)) {
      storage.removeItem(onboardingModalKey);
    }

    // We store the user's token's expiration time in local storage.
    // Whenever a user logs out, we need to clear it.
    // And when they log back in (potentially with a different account),
    // we can fetch a new one.
    clearTokenExpirationInStorage();

    User.signOut().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);

    if (persistStatus) setAuthStatus(false);

    storage.removeSecureItem(tokenKey);
    const hostname = globalThis.location.hostname.split(".");
    const topDomain = hostname.slice(hostname.length - 2, hostname.length).join(".");
    storage.removeSecureItem(tokenKey, { domain: topDomain });
    storage.removeItem(tokenKey);
    datadogRum.clearUser();

    // Clear session storage for internal groups team users on sign out.
    if (sessionStorage.getItem("orderPrefs")) {
      sessionStorage.removeItem("orderPrefs");
    }
    if (sessionStorage.getItem("tripPrefs")) {
      sessionStorage.removeItem("tripPrefs");
    }
    const redirectPath = getRedirectLocation();
    if (typeof globalThis?.auth0Logout === "function") {
      const returnTo = `${globalThis.location.origin}${redirectPath || "/"}`;
      await globalThis.auth0Logout({
        logoutParams: {
          returnTo: returnTo,
        },
      });
    }

    if (redirectPath) {
      Auth.navigateUsingNavigateFn(redirectPath); // This is a hack until race conditions are fixed
    }
  }

  public static async clearSessionAndRedirect(sourceOfSignOut: string): Promise<void> {
    await this.signOut(sourceOfSignOut);
    this.redirect();
  }
}
