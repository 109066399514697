import { useCallback, useRef, useState } from "react";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TopNavLink,
  Typography,
} from "@hotelengine/atlas-web";

import { ampli } from "ampli";
import {
  useBreakpoint,
  useSearchParams,
  useURLParams,
  type ISearchAndRoomRateID,
} from "@hotel-engine/hooks";
import { Unsafe } from "@hotel-engine/data";
import { TopNavMenu } from "./components/redesignComponents/TopNavMenu";
import { RightAccessories } from "./components/redesignComponents/RightAccessories";
import {
  getNavLinks,
  removeParameter,
  buildQueryString,
  getUserRoleEnabledFeatures,
  getCurrentState,
} from "./helpers";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { routes, GROUPS } from "@hotel-engine/constants";
import { InviteUserModal } from "@hotel-engine/app/modals/InviteUserModal/InviteUserModal";
import { SignInModal } from "@hotel-engine/app/modals/SignInModal";
import { PersonalAccountCreationModal } from "pages/Dashboard/components/PersonalAccountCreationCta/PersonalAccountCreationModal";
import OnboardingModals from "pages/Dashboard/components/OnboardingModals";

import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import type { User } from "@hotel-engine/types/user";
import { type ILinks, headerBackgroundColorOverrides } from "./constants";
import { Logo } from "@hotel-engine/app/Header/components/redesignComponents/Logo";
import { useAppSelector } from "store/hooks";
import { GlobalNavWrapper, StyledLink, TopNav } from "./components/userStyles";
import { useInternalHeaderOutlet } from "./HeaderOutletProvider";
import { isReactNode } from "@hotel-engine/helpers/isReactNode";
import { useIsFeatureFlagOn } from "../Experiments";
interface IHeader {
  hasBackground?: boolean;
}
const Header = ({ hasBackground }: IHeader) => {
  const user = useAppSelector((state) => state.Auth.user);
  const userId = user?.id;
  const { OutletContent } = useInternalHeaderOutlet();
  const headerGlobalNavWrapper = useRef<HTMLDivElement>(null);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [directBillPopoverVisible, setDirectBillPopoverVisible] = useState(false);
  const [isPersonalAccountCreationModalOpen, setIsPersonalAccountCreationModalOpen] =
    useState(false);
  const isMobile = useBreakpoint("lg", "max");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showOnboardingModals = pathname === routes.dashboard;
  const activePathId = getCurrentState(pathname);
  const urlParams = useSearchParams<{
    resetPassword?: string;
    toggleAccount?: User.AccountType;
  }>();
  const isNewSettingsEnabled = useIsFeatureFlagOn("ace_settings_redesign");

  // Grabs necessary info for usage tracking //
  const {
    params: { propertyId },
    search: { s: searchId, roomRateId },
  } = useURLParams<ISearchAndRoomRateID>();

  const isPsamOn = user?.hasGroupsSpecialization;

  const handleSwitchFailure = useCallback(() => setIsSignInModalOpen(true), []);
  const [mode, toggleAccountMode] = useAccountMode({
    onFailure: handleSwitchFailure,
  });

  const handleOnSwitchEvent = useCallback(
    (accountType: User.AccountType) => {
      triggerAmplitudeEvent(
        accountType === "personal"
          ? "clickSwitchToPersonalAccount"
          : "clickSwitchToBusinessAccount",
        { userId }
      );
    },
    [userId]
  );

  const handleSwitchAccounts = useCallback(
    (accountType: User.AccountType) => {
      handleOnSwitchEvent(accountType);
      toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    },
    [handleOnSwitchEvent, toggleAccountMode]
  );

  const handleTrackLocation = useCallback(() => {
    () => {
      if (location.pathname === routes.search) {
        ampli.clickHomepageFromSearchResults({
          searchId: Number(searchId),
        });
      }

      // eslint-disable-next-line no-useless-escape
      if (new RegExp(`${routes.properties}\/.*\/book`).test(location.pathname)) {
        ampli.clickHomepageFromCheckout({
          propertyId: Number(propertyId),
          searchId: Number(searchId),
          roomRateId: Number(roomRateId),
        });
      }

      if (/\/properties\/\d+/g.test(location.pathname)) {
        ampli.clickHomepageFromPropertyDetails({
          searchId: Number(searchId),
          propertyId: propertyId ? Number(propertyId) : undefined,
        });
      }
    };
  }, [propertyId, roomRateId, searchId]);

  const onInviteTraveler = useCallback(() => setIsInviteUserModalOpen(true), []);
  const onCancelInviteUser = useCallback((resetForm: () => void) => {
    setIsInviteUserModalOpen(false);
    resetForm();
  }, []);
  const onPersonalAccountCreation = useCallback(() => {
    setIsPersonalAccountCreationModalOpen(true);
    triggerAmplitudeEvent("clickAddPersonalAccountInMenu", { userId });
  }, [userId]);
  const onCancelPersonalAccountCreation = useCallback(() => {
    if (urlParams.toggleAccount) {
      navigate("/", { replace: true });
    }
    setIsPersonalAccountCreationModalOpen(false);
  }, [navigate, urlParams.toggleAccount]);
  const onCancelAccountSwitch = useCallback(() => {
    if (urlParams.toggleAccount) {
      navigate("/", { replace: true });
    }
    setIsSignInModalOpen(false);
  }, [urlParams.toggleAccount, navigate]);

  if (!user) {
    return null;
  }

  const navLinks = getNavLinks(user, isNewSettingsEnabled);
  const { canCreatePersonalAccount } = getUserRoleEnabledFeatures(user);

  if (urlParams?.resetPassword) {
    /** Remove resetPassword=true from url when comes from a
     * reset password redirect */
    const newParams = removeParameter(urlParams, "resetPassword");
    const newQueryString = buildQueryString(newParams);
    navigate(newQueryString ? `/?${newQueryString}` : "/", {
      replace: true,
    });
  }

  if (urlParams?.toggleAccount) {
    handleSwitchAccounts(urlParams.toggleAccount);
    navigate("/", { replace: true });
  }

  return (
    <GlobalNavWrapper $globalNavBackgroundColorOverride={headerBackgroundColorOverrides[pathname]}>
      <TopNav
        isBackgroundEnabled={hasBackground}
        ref={headerGlobalNavWrapper}
        leftAccessory={
          <Box
            gap={12}
            onClick={handleTrackLocation}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
          </Box>
        }
        rightAccessory={
          <RightAccessories
            isMobile={isMobile}
            mode={mode}
            handleSwitchAccounts={handleSwitchAccounts}
            onInviteTraveler={onInviteTraveler}
            onPersonalAccountCreation={onPersonalAccountCreation}
          />
        }
      >
        {OutletContent !== undefined && isReactNode(OutletContent) ? (
          <>{OutletContent}</>
        ) : (
          navLinks.reduce<(ILinks | JSX.Element)[]>((acc, link) => {
            const { id, name, route, type } = link;
            if (["trends", "members"].includes(id)) return acc;

            if (type === "menu-trigger" || (id === GROUPS && isPsamOn)) {
              return [
                ...acc,
                <TopNavMenu
                  key={id}
                  isMobile={isMobile}
                  activePathId={activePathId}
                  triggerLabel={name}
                  cards={link.subMenuItems ?? []}
                  triggerIsActiveDisabled
                />,
              ];
            }

            if (id === "billing") {
              return [
                ...acc,
                <Popover key={id} isOpen={directBillPopoverVisible}>
                  <PopoverTrigger>
                    <TopNavLink asChild isActive={activePathId === id}>
                      <StyledLink to={route} data-testid={`header-route--${id}`}>
                        {name}
                      </StyledLink>
                    </TopNavLink>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Typography variant="body/sm">
                      New Billing Page for all
                      <br /> your Direct Bill needs.
                    </Typography>
                  </PopoverContent>
                </Popover>,
              ];
            }

            return [
              ...acc,
              <TopNavLink asChild isActive={activePathId === id} key={id}>
                <StyledLink to={route} data-testid={`header-route--${id}`}>
                  {name}
                </StyledLink>
              </TopNavLink>,
            ];
          }, [])
        )}
        <InviteUserModal visible={isInviteUserModalOpen} onCancel={onCancelInviteUser} />
        {!!canCreatePersonalAccount && (
          <PersonalAccountCreationModal
            visible={isPersonalAccountCreationModalOpen}
            onClose={onCancelPersonalAccountCreation}
          />
        )}
        <SignInModal visible={isSignInModalOpen} onCancel={onCancelAccountSwitch} />
        {!!showOnboardingModals && (
          <OnboardingModals setDirectBillPopoverVisible={setDirectBillPopoverVisible} />
        )}
      </TopNav>
    </GlobalNavWrapper>
  );
};

export default Header;
