import { createEntityAdapter } from "@reduxjs/toolkit";
import type { IFlightResult } from "@hotelengine/core-booking-web";

import type { IFlightsSearchResultsState } from "./flights.results.types";

export const resultsAdapter = createEntityAdapter({
  selectId: (result: IFlightResult) => result.continuationToken,
});

export const initialFlightsResultsState: IFlightsSearchResultsState = {
  ...resultsAdapter.getInitialState(),
  error: null,
  searchId: null,
  travelerManifest: null,
  userTravelPolicy: null,
};

export const INTERNATIONAL_FLIGHTS_ERROR =
  "Reach out to your Engine representative in order to book international flights.";
