import { SEARCH_FILTERS } from "@hotel-engine/constants";
import { Str } from "@hotel-engine/data";
import type {
  FilterKey,
  IAvailablePropertyTypesFilter,
  IBasicFilter,
  INewPriceRange,
  IPropertyNameFilter,
  ISearchFiltersState,
  IStayTypeFilter,
  SelectedFilter,
} from "../types";
import type { IResult } from "@hotel-engine/types/search";
import { pluralize } from "@hotel-engine/utilities/formatters/formatStrings";

import type { IResults } from "store/Search/SearchResults/types";

export const buildBasicToggleSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  (["room", "facility", "popular", "services"] as const).forEach((filterType) => {
    Object.keys(state[filterType]).forEach((filterKey) => {
      const { selected } = state[filterType][filterKey] as IBasicFilter;

      if (!selected) return;

      const key = `${filterType}.${filterKey}` as FilterKey;
      const { label } = SEARCH_FILTERS[filterType][filterKey];

      selectedFilters.push({
        filterKey: key,
        label,
        type: "toggle",
        value: true,
      });
    });
  });

  return selectedFilters;
};

export const buildSupplierSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.suppliers).forEach((supplier) => {
    const { selected } = state.suppliers[supplier];

    if (!selected) return;

    selectedFilters.push({
      filterKey: "suppliers",
      nestedFilterKey: supplier,
      label: supplier,
      type: "toggle",
      value: supplier,
    });
  });

  return selectedFilters;
};

export const buildPropertyNameSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.propertyNames).forEach((propertyName) => {
    const { selected } = state.propertyNames[propertyName];

    if (!selected) return;

    selectedFilters.push({
      filterKey: "propertyNames",
      label: propertyName,
      type: "propertyNames",
      value: propertyName,
    });
  });

  return selectedFilters;
};

export const buildStayTypeSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.stayTypes).forEach((stayType) => {
    const { selected } = state.stayTypes[stayType];

    if (!selected) return;

    const { label, values } = SEARCH_FILTERS.stayTypes[stayType];

    values.forEach((value) => {
      selectedFilters.push({
        filterKey: "stayTypes",
        nestedFilterKey: stayType,
        label,
        type: "toggle",
        value,
      });
    });
  });

  return selectedFilters;
};

export const buildPropertyTypeSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.propertyTypes).forEach((propertyType) => {
    const { selected } = state.propertyTypes[propertyType];

    if (!selected) return;

    const { label, values } = SEARCH_FILTERS.propertyTypes[propertyType] ?? {
      label: propertyType,
      values: [propertyType.toLowerCase()],
    };

    values.forEach((value) => {
      selectedFilters.push({
        filterKey: "propertyTypes",
        nestedFilterKey: propertyType,
        label,
        type: "toggle",
        value,
      });
    });
  });

  return selectedFilters;
};

export const buildLoyaltyProgramSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.loyaltyPrograms).forEach((loyaltyProgram) => {
    const { selected } = state.loyaltyPrograms[loyaltyProgram];

    if (!selected) return;

    const label = Str.capitalize(String(loyaltyProgram));

    selectedFilters.push({
      filterKey: "loyaltyPrograms",
      nestedFilterKey: loyaltyProgram,
      label,
      type: "toggle",
      value: label,
    });
  });

  return selectedFilters;
};

export const buildPriceRangeSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];
  const { userMin, userMax } = state.priceRange;

  if (Number.isFinite(userMin) || Number.isFinite(userMax)) {
    selectedFilters.push({
      filterKey: "priceRange",
      label: "Price range",
      type: "priceRange",
      value: [userMin, userMax],
    });
  }

  return selectedFilters;
};

export const buildStarRatingSelectedFilters = (state: ISearchFiltersState) => {
  const selectedFilters: SelectedFilter[] = [];

  Object.keys(state.starRating).forEach((rating) => {
    const { selected } = state.starRating[rating];

    if (!selected) return;

    selectedFilters.push({
      filterKey: "starRating",
      nestedFilterKey: rating,
      label: pluralize(Number(rating), "star"),
      type: "starRating",
      value: Number(rating),
    });
  });

  return selectedFilters;
};

export const buildStayTypeFilters = (
  stayTypes: ISearchFiltersState["stayTypes"]
): IStayTypeFilter[] => {
  return Object.keys(stayTypes).map<IStayTypeFilter>((stayTypeKey) => {
    const stayType = stayTypes[stayTypeKey];
    const { label } = SEARCH_FILTERS.stayTypes[stayTypeKey];

    return {
      ...stayType,
      label,
      filterKey: stayTypeKey,
    };
  });
};

/**
 * Builds available property type filters that are displayed in the filter pane.
 * Only property type filters with results will display, indicated by the `available: true` property
 * added in the initFilters reducer
 */
export const buildAvailablePropertyTypeFilters = (
  propertyTypes: ISearchFiltersState["propertyTypes"]
) => {
  return Object.keys(propertyTypes)
    .reduce<IAvailablePropertyTypesFilter[]>((availableFilters, objectType) => {
      const propertyType = objectType as keyof ISearchFiltersState["propertyTypes"];
      const primaryPropertyTypeFilter = propertyTypes[propertyType];

      if (primaryPropertyTypeFilter.available) {
        return [
          ...availableFilters,
          {
            ...primaryPropertyTypeFilter,
            label: SEARCH_FILTERS.propertyTypes[propertyType]?.label ?? propertyType,
            filterKey: `propertyTypes.${propertyType}`,
          },
        ];
      }

      return availableFilters;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const addPropertyNameFilter = (
  name: string | null,
  availablePropertyNames: ISearchFiltersState["propertyNames"]
) => {
  if (name) {
    availablePropertyNames[name] = {
      selected: false,
    };
  }
};

export const updatePriceRangeFloorCeil = (
  customerRate: number | undefined,
  newPriceRange: INewPriceRange
) => {
  if (customerRate) {
    const shouldUpdateFloor = newPriceRange.floor === null || newPriceRange.floor > customerRate;
    const shouldUpdateCeil = newPriceRange.ceil === null || newPriceRange.ceil < customerRate;

    if (shouldUpdateFloor) {
      newPriceRange.floor = Math.floor(customerRate);
    }

    if (shouldUpdateCeil) {
      newPriceRange.ceil = Math.ceil(customerRate);
    }
  }
};

export const getFiltersAvailabilityFromNewResults = (results: IResults) => {
  const availablePropertyTypesSet = new Set<string>();
  const availablePropertyNames: ISearchFiltersState["propertyNames"] = {};
  const availableSuppliersSet = new Set<string>();
  const newPriceRange: INewPriceRange = {
    floor: null,
    ceil: null,
  };

  Object.values(results).forEach(({ propertyType, name, customerRate, supplier }: IResult) => {
    if (!!supplier) availableSuppliersSet.add(supplier);

    // Add available property type
    if (propertyType) {
      availablePropertyTypesSet.add(propertyType);
    }

    // Add available property name
    addPropertyNameFilter(name, availablePropertyNames);

    // Update price range floor and ceil
    updatePriceRangeFloorCeil(customerRate, newPriceRange);
  });

  return {
    propertyTypes: Array.from(availablePropertyTypesSet),
    propertyNames: availablePropertyNames,
    suppliers: Array.from(availableSuppliersSet),
    priceRange: newPriceRange,
  };
};

export const getPropertyNameFilters = (
  propertyNames: {
    [propertyName: string]: IPropertyNameFilter;
  },
  availablePropertyNames: ISearchFiltersState["propertyNames"]
): ISearchFiltersState["propertyNames"] => {
  const selectedPropertyNames: ISearchFiltersState["propertyNames"] = {};

  Object.keys(propertyNames).forEach((propertyName) => {
    const propertyNameFilter = propertyNames[propertyName];

    if (!propertyNameFilter.selected) return;

    selectedPropertyNames[propertyName] = propertyNameFilter;
  });

  return {
    ...availablePropertyNames,
    ...selectedPropertyNames,
  };
};

// This function updates the property types in the filter state
// to reflect if the property type has results available and
// therefore should be displayed in the filter pane
export const getPropertyTypeFiltersAvailability = (
  propertyTypes: ISearchFiltersState["propertyTypes"],
  availablePropertyTypesSet: Array<string>
) => {
  const updatedPropertyTypes: ISearchFiltersState["propertyTypes"] = {
    ...propertyTypes,
  };
  Object.keys(updatedPropertyTypes).forEach((key) => {
    updatedPropertyTypes[key] = {
      ...updatedPropertyTypes[key],
      available: false,
    };
  });

  // These property types are included under an umbrella property type
  const propertyTypeUmbrellaValues = Object.values(SEARCH_FILTERS.propertyTypes).flatMap(
    (umbrellaPropertyType) => umbrellaPropertyType.values
  ) as string[];

  availablePropertyTypesSet.forEach((availablePropertyType) => {
    const propertyTypeIncludedUnderUmbrella = propertyTypeUmbrellaValues.includes(
      availablePropertyType.toLowerCase()
    );
    if (propertyTypeIncludedUnderUmbrella) {
      Object.keys(SEARCH_FILTERS.propertyTypes).forEach((key) => {
        const propertyType = SEARCH_FILTERS.propertyTypes[key];

        if (propertyType.values.includes(availablePropertyType.toLowerCase())) {
          updatedPropertyTypes[key] = {
            ...updatedPropertyTypes[key],
            available: true,
          };
        }
      });
    } else {
      // Property type is not defined under an umbrella type
      // Add the property type to the list of available property types in state
      // If data for property type already exists due to rate refresh, maintain state
      updatedPropertyTypes[availablePropertyType] = {
        available: true,
        count: updatedPropertyTypes[availablePropertyType]?.count ?? 0,
        selected: updatedPropertyTypes[availablePropertyType]?.selected ?? false,
      };
    }
  });

  return updatedPropertyTypes;
};

export const getSuppliersFilters = (
  currentSuppliers: ISearchFiltersState["suppliers"],
  availableSuppliers: Array<string>
) => {
  const selectedSuppliers: string[] = [];

  for (const [supplier, supplierFilter] of Object.entries(currentSuppliers)) {
    if (supplierFilter.selected) {
      selectedSuppliers.push(supplier);
    }
  }

  return availableSuppliers.concat(selectedSuppliers).reduce((acc, supplier) => {
    if (!acc[supplier]) {
      acc[supplier] = {
        count: 0,
        selected: false,
      };
    }

    acc[supplier].selected = selectedSuppliers.includes(supplier);

    return acc;
  }, {});
};

// get Loyalty Program Filters from the results
export function getLoyaltyProgramFilters(results: IResults) {
  return Object.values(results).reduce(
    (acc, item) => {
      if (item.loyaltyRewardName === null || item.loyaltyRewardName === undefined) {
        return acc;
      }
      const key = item.loyaltyRewardName;
      if (!acc[key]) {
        acc[key] = { selected: false, count: 0 };
      }
      acc[key].count += 1;
      return acc;
    },
    {} as Record<string, { selected: boolean; count: number }>
  );
}
