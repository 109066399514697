import styled from "styled-components";

import { Box, Button, Icon, type BoxProps } from "@hotelengine/atlas-web";

export const SettingsContainer = styled<React.FC<BoxProps>>(Box)`
  overflow: auto;
`;

export const CookieInformation = styled.section`
  border-top: thin solid ${({ theme }) => theme.colors.accentGrayLighter};
  margin: 5px 0;
`;

export const CookieDetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  tbody {
    width: 100%;
  }

  th,
  td {
    border: thin solid ${({ theme }) => theme.colors.accentGrayLight};
    padding: 5px;
    text-align: left;
  }

  tr:hover {
    background-color: ${({ theme }) => theme.colors.accentGrayLighter};
  }

  th {
    background-color: ${({ theme }) => theme.colors.foregroundAccent};
    color: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

export const Approve = styled(Button)`
  margin-left: auto;
`;

export const PlusIcon = styled(Icon)`
  margin-right: 10px;
`;
