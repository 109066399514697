import styled from "styled-components";
import { buttonReset } from "../../styles";

export const ReloadLink = styled.button`
  ${buttonReset};

  color: inherit;
  margin-left: 4px;
  border-bottom: ${({ theme }) =>
    `${theme.borderWidth.normal} solid ${theme.colors.foregroundPrimary}`};
    width: fit-content;

  &:hover,
  &:focus {
    color: inherit;
  }
`;
