import { useState } from "react";

import { Form, Formik } from "formik";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import * as yup from "yup";

import { useTwoFactorVerificationCodeSubmit } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeSubmit";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import { User } from "@hotel-engine/services";
import { captureMessage } from "@hotel-engine/utilities";
import { getPersonalToken } from "@hotel-engine/utilities/auth";
import { ModalNavBar } from "pages/Dashboard/components/PersonalAccountCreationCta/ModalNavBar";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppSelector, useAppDispatch } from "store/hooks";

import { ResendButton } from "./ResendButton";

import { Unsafe } from "@hotel-engine/data";
import { is422ErrorResponse } from "@hotel-engine/react-query/errors/response";
import { Box, Button, Typography, FormControl, TextInput, toast } from "@hotelengine/atlas-web";

interface IEnterVerificationCode {
  /** Phone number used to request the code */
  phone: string;
  /** Indicates the component is rendering in a modal on the Dashboard on a Business account */
  isInModal?: boolean;
  /** Close the modal */
  onClose?: () => void;
  /** Go back to the phone number submission */
  onGoBack?: () => void;
}
export interface ICodeVerification {
  /** Authentication code texted to user's phone */
  code: string;
}

const initialValues: ICodeVerification = {
  code: "",
};

const errorMessage = "Please enter valid code";
const CodeVerificationValidation = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d+$/, errorMessage)
    .test("", errorMessage, (code) => code?.length === 6)
    .required(errorMessage),
});

// resend code, setup code submission
export const EnterVerificationCode = ({
  phone,
  isInModal,
  onClose,
  onGoBack,
}: IEnterVerificationCode) => {
  const user = useAppSelector((store) => store.Auth.user);
  const isBusinessAccount = user?.accountType === "business";
  const personalToken = getPersonalToken();
  const authToken = isBusinessAccount && personalToken ? { token: personalToken } : undefined;

  const { mutate } = useTwoFactorVerificationCodeSubmit(authToken);
  const [resendError, setResendError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, toggleAccountMode] = useAccountMode({
    onFailure: () =>
      toast({
        title: "Sorry, there was a problem switching you to your personal account.",
        icon: "circle-exclamation",
        sentiment: "critical",
      }),
  });

  const handleSubmit = (values: ICodeVerification, actions) => {
    mutate(
      { phone, code: values.code, accountType: "personal" },
      {
        onSuccess: () => {
          User.getSelf()
            .then((fetchedUser) => {
              if (fetchedUser) {
                dispatch(AuthActions.setUser(fetchedUser));
              }
            })
            .then(() => {
              if (isBusinessAccount && personalToken) {
                // set user to the associated personal account
                toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
              }
              // replacing history so users don't back into the search param or 2fV page //
              navigate("/", {
                replace: true,
              });
            })
            .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
        },
        onError: (error) => {
          if (is422ErrorResponse(error)) {
            actions.setFieldError(
              "code",
              "Verification code was not accepted. Please check that the number is valid and try again."
            );
            return;
          }
          captureMessage("Enter Verification Code error", { error });

          actions.setFieldError("code", "Sorry, there was a problem. Try again later.");
        },
      }
    );
  };

  const last4Digits = phone.slice(-4);

  return (
    <Box display="flex" flexDirection="column" gap={16}>
      {!!isInModal && !!onClose && <ModalNavBar onClose={onClose} onGoBack={onGoBack} />}

      <Typography variant="heading/xl" style={{ textAlign: isInModal ? "start" : "center" }}>
        Enter Authentication Code
      </Typography>

      <Typography variant="heading/sm" style={{ textAlign: isInModal ? "start" : "center" }}>
        We've sent a verification code to{" "}
        <Typography variant="heading/sm" color="foregroundSecondary">
          xxx-xxx-{last4Digits}
        </Typography>
        . Please enter the code you received in the field below.
      </Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CodeVerificationValidation}
      >
        {({ errors, setFieldValue }) => (
          <Box display="flex" flexDirection="column" gap={16}>
            <Typography
              variant="heading/sm"
              color="foregroundSecondary"
              style={{ textAlign: isInModal ? "start" : "center" }}
            >
              Didn't receive code?{" "}
              <ResendButton phone={phone as string} setResendError={setResendError} />{" "}
              {!!resendError && (
                <Typography variant="body/sm" color="accentRed">
                  {resendError}
                </Typography>
              )}
            </Typography>

            <Form>
              <Box display="flex" flexDirection="column" gap={16}>
                <FormControl
                  label=""
                  status={!!errors.code ? "error" : "default"}
                  errorText={errors.code}
                >
                  <TextInput
                    id="code"
                    name="code"
                    data-testid="code"
                    placeholder="Enter Verification Code"
                    required
                    onChange={(e) => setFieldValue("code", e.target.value, true)}
                  />
                </FormControl>
                <Button type="submit" variant="filled">
                  Verify
                </Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
