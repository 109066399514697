import { useState } from "react";

import { Button, Icon, Switch, Tooltip, Typography } from "@hotelengine/atlas-web";
import storage from "@hotel-engine/storage";

import { CookieTypeDefinitions, Cookies } from "./Cookies";
import * as Styled from "./CookieSettings.styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

const CookieInformation = (props: {
  type: keyof typeof CookieTypeDefinitions;
  enabled: [boolean, (type: keyof typeof CookieTypeDefinitions) => void];
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Styled.CookieInformation key={props.type}>
      <Typography
        variant="body/md"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        as="h3"
        marginTop={6}
      >
        {props.type}
        {props.type === "Strictly Necessary" ? (
          <Tooltip content={CookieTypeDefinitions[props.type]}>
            <Icon name="circle-question" />
          </Tooltip>
        ) : (
          <Switch
            onCheckedChange={() => props.enabled[1](props.type)}
            isChecked={props.enabled[0]}
            aria-label={`${props.enabled[0] ? "disable" : "enable"} ${props.type} cookies`}
          />
        )}
      </Typography>
      <Typography variant="body/sm" as="p" marginBottom={6}>
        {CookieTypeDefinitions[props.type]}
      </Typography>
      <Button
        variant="plain"
        color="accent"
        size="sm"
        onClick={() => setIsVisible((prev) => !prev)}
      >
        <Styled.PlusIcon size="sm" name={isVisible ? "circle-minus" : "circle-plus"} />
        {isVisible ? "Hide cookies" : "View cookies"}
      </Button>
      {!!isVisible && (
        <Styled.CookieDetailTable>
          <tbody>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Type</th>
              <th>Duration</th>
            </tr>
            {Object.values(Cookies).map(
              (cookie) =>
                cookie.type === props.type && (
                  <tr key={cookie.name}>
                    <td>{cookie.name}</td>
                    <td>{cookie.description}</td>
                    <td>{cookie.firstParty ? "First Party" : "Third Party"}</td>
                    <td>
                      {cookie?.["expiresDisplay"] || cookie?.["options"]?.expires || "Session"}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Styled.CookieDetailTable>
      )}
    </Styled.CookieInformation>
  );
};

export const CookieSettings = (props: {
  onOk?: () => void;
  setAmplitudeCookieAccepted: () => void;
}) => {
  const { COMPANY_NAME, COMPANY_URL } = useCompanyName();
  const cookiePerms = storage.getSecureItem("cookiePerms");
  const [cookieSettings, setCookieSettings] = useState(
    !!cookiePerms
      ? JSON.parse(cookiePerms)
      : {
          Statistics: true,
          Marketing: true,
        }
  );

  const saveCookieSettingsHandler = () => {
    storage.setSecureItem("cookiePerms", JSON.stringify(cookieSettings));
    if (cookieSettings.Statistics) {
      props.setAmplitudeCookieAccepted();
    }
    props.onOk?.();
  };

  return (
    <Styled.SettingsContainer display="flex" flexDirection="column" padding={12}>
      <section>
        <h1>Cookie Settings</h1>
        <h3>{COMPANY_NAME} uses cookies to improve your experience and for marketing.</h3>
        <Typography variant="body/sm" as="p" marginBottom={0}>
          For more information, please refer to our{" "}
          <a
            href={`https://www.${COMPANY_URL}/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </Typography>
      </section>
      {Object.keys(CookieTypeDefinitions).map((cookieType) =>
        CookieInformation({
          type: cookieType as keyof typeof CookieTypeDefinitions,
          enabled: [
            cookieSettings[cookieType],
            () =>
              setCookieSettings((prev) => ({
                ...prev,
                ...{ [cookieType]: !prev[cookieType] },
              })),
          ],
        })
      )}
      <Styled.Approve color="accent" onClick={saveCookieSettingsHandler}>
        OK
      </Styled.Approve>
    </Styled.SettingsContainer>
  );
};
