import { Menu as AntdMenu } from "antd";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Tabs` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/tabs-l22Xp9xq Atlas Web Tabs Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-tabs--docs Atlas Web Tabs Storybook}
 *
 * @summary The `Menu` component wraps antd's `<Menu />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Menu component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/menu/ AntD Menu Documentation}
 */
export const Menu = AntdMenu;
export const MenuItem = AntdMenu.Item;

export type { MenuProps, ClickParam } from "antd/lib/menu";
