import { useEffect, useState } from "react";

import type { IResult } from "@hotel-engine/types/search";
import { useSearchQueryParams } from "@hotel-engine/hooks/search/useSearchQueryParams";

import type {
  FormattedPropertiesGeoPoint,
  IClusterVariant,
  PropertyWithCluster,
} from "../../types";
import { supercluster } from "../GoogleMapsClusters/supercluster";

export const returnBounds = (bounds: google.maps.LatLngBounds, arr: IResult[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i].latitude !== "number" || typeof arr[i].longitude !== "number") {
      continue;
    }
    const markerBounds = {
      lat: arr[i].latitude,
      lng: arr[i].longitude,
    };
    bounds.extend(markerBounds);
  }

  return bounds;
};

export const getClusterVariant = ({
  clusterId,
  hiddenPropertyIds = [],
  listHighlightedResultId,
  point_count,
  preferredPropertyIds = [],
  selectedResultId,
  specificLocationId,
}: {
  clusterId: number;
  hiddenPropertyIds?: number[];
  listHighlightedResultId?: number | null | undefined;
  point_count: number;
  preferredPropertyIds?: number[];
  selectedResultId?: number;
  specificLocationId?: string;
}): IClusterVariant => {
  /** Leaves are all the points contained within the cluster */
  const leaves = supercluster.getLeaves(clusterId, point_count);
  const isActive = leaves.some((leaf) => {
    const { id: resultId, propertyId } = leaf.properties as PropertyWithCluster;
    return (
      (resultId && selectedResultId === resultId) ||
      (propertyId && listHighlightedResultId === propertyId)
    );
  });

  if (!!specificLocationId) {
    const isSpecificProperty = leaves.some((leaf) => {
      const { propertyId } = leaf.properties as PropertyWithCluster;
      return !!specificLocationId && +specificLocationId.split(".")[1] === propertyId;
    });

    if (isSpecificProperty) {
      return `specific${isActive ? " active" : ""}`;
    }
  }

  const isPreferredProperty = leaves.some((leaf) => {
    // destructure GeoJSON Feature properties of each leaf
    const { propertyId } = leaf.properties as PropertyWithCluster;
    return !!preferredPropertyIds.includes(propertyId) && !hiddenPropertyIds.includes(propertyId);
  });
  if (isPreferredProperty) {
    return `preferred${isActive ? " active" : ""}`;
  }

  const isFavorite = leaves.some((leaf) => {
    // destructure GeoJSON Feature properties of each leaf
    const { favoriteId, id } = leaf.properties as PropertyWithCluster;
    return favoriteId && !hiddenPropertyIds[id];
  });

  if (isFavorite) {
    return `favorite${isActive ? " active" : ""}`;
  }

  return isActive ? "active" : "normal";
};

export function formatSearchDataToGeoJsonPoints<
  T extends { longitude: number | string; latitude: number | string },
>(data: T[]): FormattedPropertiesGeoPoint<T>[] {
  return data.map((property) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [Number(property.longitude), Number(property.latitude)],
    },
    properties: { cluster: false, ...property },
  }));
}

export function returnClusters(bounds: google.maps.LatLngBoundsLiteral, currentZoom: number) {
  const mapClusters = supercluster.getClusters(
    [bounds.west, bounds.south, bounds.east, bounds.north],
    currentZoom
  );
  return mapClusters;
}

/**
 * Returns the concatenation of currentResults and previousResults removing duplicates.
 * It prioritize the currentResults items to provide the correct data.
 * @param previousResults
 * @param currentResults
 * @returns
 */
export const concatWithoutDupes = (previousResults: IResult[], currentResults: IResult[]) => {
  const finalResults = [...currentResults];
  const memoizedPropertyIds = new Set(currentResults.map((result) => result.propertyId));
  previousResults.forEach((result) => {
    if (!memoizedPropertyIds.has(result.propertyId)) {
      finalResults.push(result);
      memoizedPropertyIds.add(result.propertyId);
    }
  });
  return finalResults;
};

type HoldResultsHookParams = {
  loading: boolean;
  results: IResult[];
};

/**
 * This hook returns the combination of previous results and current ones -without duplicates - while loading is true
 * and the current results if loading is false.
 * @param HoldResultsHookParams
 * @returns
 */
export const useHoldExistingResults = ({ loading, results }: HoldResultsHookParams) => {
  const [previousResults, setPreviousResults] = useState<IResult[]>([]);
  const { baseSearchId = false } = useSearchQueryParams().queryParams;

  /**
   * effect to hold the current existing results when a new search is triggered.
   * Because baseSearchId being in the url param is an indicator that the search was triggered
   * by moving the map, is being used to hold or not the previous results
   */
  useEffect(() => {
    if (!loading && baseSearchId) {
      setPreviousResults(results ?? []);
    } else if (!baseSearchId) {
      setPreviousResults([]);
    }
  }, [loading, results, baseSearchId]);

  return loading ? concatWithoutDupes(previousResults, results) : results;
};
