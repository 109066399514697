import type { UseQueryOptions } from "react-query";
import { useMutation } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";

import { useApi } from "../useApi";
import { endpoints } from "../constants";

export interface IUsersData {
  users?: IUser[];
  limit: string;
  offset: number;
  total: number;
}

interface IMutationParams {
  userId: number;
}

export interface IImpersonateUserMutationResult {
  accessToken: string;
  firstName: string;
  lastName: string;
}

export interface IImpersonateUserConfig {
  options?: UseQueryOptions<IUsersData, IErrorResponse, IImpersonateUserMutationResult>;
}

const useImpersonateUserMutation = (config: IImpersonateUserConfig = {}) => {
  const post = useApi("post");

  const mutationFn = async ({ userId }: IMutationParams) =>
    post<IImpersonateUserMutationResult>(`${endpoints.users}/${userId}/impersonate_tokens`);

  return useMutation<IImpersonateUserMutationResult, IErrorResponse, IMutationParams>(
    mutationFn,
    config.options
  );
};

export { useImpersonateUserMutation };
