import {
  ACCEPT,
  ANONYMOUS_AMPLITUDE_USER_ID,
  BUSINESS,
  JOIN,
  LOGIN,
  PARTNERS,
  SIGN_UP,
} from "@hotel-engine/constants";
import { useLocation } from "@hotel-engine/lib/react-router-dom";

const PUBLIC_PATH_PREFIXES = [
  // /join/refer-a-friend (personal referral acceptance), /join/groups (group invitation), and /join/<business-code> are
  // all covered by this path prefix.  Of those three, only groups might come with a specific user ID.
  `/${JOIN}`,
  // This is for accepting an invitation.  Generally, it will come with a user ID in the query params.
  `/${ACCEPT}`,
  // This is for business referral acceptance and is actually an invitation, and will come with a specific user id.
  `/${SIGN_UP}`,
  // Rarely-hit case for partner sign up.  Should not come with any user IDs in the query params
  `/${PARTNERS}`,
  // (Legacy? Defunct?) alias for the static join link.  Does not come with a user id.
  `/${BUSINESS}`,
  // This is for supporting an anonymous user on the Login page for Amplitude (enabling feature flags there)
  `/${LOGIN}`,
];

/**
 * The user ID from the current signup flow -- if any can be found -- should be passed in to short-circuit the logic if
 * possible.  If we already know the user id, there's no need to iterate the prefixes.
 * @param signupUserId
 */
export const useAnonymousAmplitudeUserId = (
  signupUserId: string | undefined
): number | undefined => {
  const location = useLocation();

  if (signupUserId) {
    return undefined;
  }

  // TODO: This works, but I feel like the better approach is for the components to set some data that the amplitude
  //  code can read to determine that we are DEFINITELY in this case instead of sniffing paths.
  return PUBLIC_PATH_PREFIXES.some((prefix) => location.pathname.startsWith(prefix))
    ? ANONYMOUS_AMPLITUDE_USER_ID
    : undefined;
};
