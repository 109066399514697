import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { ISearchRatesServiceOutput } from "@hotel-engine/types/search";
import config from "config";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useEffect, useRef } from "react";
import type { SearchTypeOptions } from "@hotel-engine/constants/search";
import type { UserDistanceUnit } from "@hotel-engine/types/user";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useLodgingSearchApi } from "../lodgingSearch/useLodgingSearchApi";
import type { AxiosError } from "axios";

export interface ISearchMutationParams {
  checkIn: string;
  checkOut: string;
  guestCount: number;
  latitude?: number;
  linkedSearchId?: string;
  location?: string;
  locationId?: string;
  longitude?: number;
  market?: string;
  propertyIds?: number[];
  radius?: number;
  reqId?: string;
  retrySearchWithExpandedRadius?: boolean;
  roomCount: number;
  salesChannel?: string;
  sendPropertyDataOnce?: boolean;
  userAgent?: string;
  searchType: SearchTypeOptions;
  traceId?: string;
  distanceUnit?: UserDistanceUnit;
}

type UseSearchMutationOptions = Omit<
  UseMutationOptions<ISearchRatesServiceOutput, AxiosError, ISearchMutationParams, unknown>,
  "mutationFn"
>;

const REQUEST_CANCEL_REASON = "Members: Request aborted by user.";

interface IUseSearchMutationParams {
  createNewSessionId?: boolean;
  incomingSessionId?: string;
  options?: UseSearchMutationOptions;
}

export const useSearchMutation = ({
  createNewSessionId = false,
  incomingSessionId,
  options = {},
}: IUseSearchMutationParams) => {
  const morpheusEnabled = useIsFeatureFlagOn("morpheus");
  const { executeRequest, queryOptions } = useLodgingSearchApi<ISearchRatesServiceOutput>({
    createNewSessionId,
    incomingSessionId,
    options,
    requestMethod: "post",
  });
  const controllerRef = useRef<AbortController>();

  const post = useApi("post");

  const abort = () => {
    // Abort previous search request if it is still pending. Calling .abort() on
    // an already ended or cancelled request is a noop and will not cause an issue
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    controllerRef.current?.abort(REQUEST_CANCEL_REASON);
  };

  // Abort the request on unmount
  useEffect(() => abort, []);

  const mutation = useMutation<ISearchRatesServiceOutput, AxiosError, ISearchMutationParams>(
    (params) => {
      // Abort existing request
      abort();

      controllerRef.current = new AbortController();

      if (morpheusEnabled) {
        return executeRequest(endpoints.bestOffer, params, {
          signal: controllerRef.current.signal,
        });
      }

      // remove when morpheus flag is removed
      return post(
        endpoints.search,
        params,
        {
          baseURL: config.supplyRepoHost,
          signal: controllerRef.current.signal,
        },
        false,
        true
      );
    },
    morpheusEnabled ? queryOptions : options
  );

  return {
    mutation,
    abort,
  };
};
