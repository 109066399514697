import type { UseQueryOptions } from "react-query";

import type { IRoomCreateParams, IRoomsResponse } from "@hotel-engine/types/room";
import config from "config";
import { checkAndHandleDuplicateRooms } from "./helpers";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import type { AxiosError } from "axios";

// Remove when morpheus flag is removed
export const useRoomsQuery = (
  params: IRoomCreateParams,
  options?: UseQueryOptions<IRoomsResponse, AxiosError>
) => {
  const post = useApi("post");

  return useExtendedQuery(
    generateRoomsQueryKey(params),
    () =>
      post<IRoomsResponse>(
        `v2/${endpoints.rooms}`,
        params,
        {
          baseURL: config.supplyRepoHost,
        },
        false,
        true
      ),
    {
      onSuccess: (data) => {
        const { rooms } = data;
        checkAndHandleDuplicateRooms(rooms);
      },
      ...options,
    }
  );
};

export const generateRoomsQueryKey = ({
  checkIn,
  checkOut,
  propertyId,
  searchId,
  limit,
}: Pick<IRoomCreateParams, "checkIn" | "checkOut" | "propertyId" | "searchId" | "limit">) => {
  return [
    `v2/${endpoints.rooms}`,
    ...Object.values({
      checkIn,
      checkOut,
      propertyId,
      searchId,
      limit,
    }),
  ];
};
