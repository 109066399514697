import type { BoxProps, ButtonProps } from "@hotelengine/atlas-web";
import { Box, Button } from "@hotelengine/atlas-web";
import styled, { css } from "styled-components";

interface ICarCardProps extends BoxProps {
  $outOfPolicy: boolean;
  $isMobile?: boolean;
}

export const CarCard = styled<React.FC<ICarCardProps>>(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  display: grid;
  grid-template-columns: 200px 1fr ${({ $isMobile }) => ($isMobile ? "" : "140px")} minmax(128px, auto);
  grid-gap: 0;
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[20]}`};
  justify-content: stretch;
  cursor: pointer;
  ${({ $outOfPolicy }) =>
    $outOfPolicy &&
    css`
      opacity: 0.6;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `};

  @media (min-width: 770px) {
    grid-gap: ${({ theme }) => theme.spacing[40]};
  }
`;

export const OutOfPolicyWrapper = styled<React.FC<BoxProps>>(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[16]}`};

  span {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  i {
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;

export const CarImageColumn = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationColumn = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[20]};
  text-align: start;
`;

export const LocationInformation = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  text-align: left;
`;

export const VehicleDetailsColumn = styled<React.FC<BoxProps & { $isMobile?: boolean }>>(Box)`
  display: ${({ $isMobile }) => (!$isMobile ? "flex" : "grid")};
  @media (min-width: 700px) {
    grid-template-columns: 0fr 0fr;
    gap: ${({ theme, $isMobile }) => theme.spacing[$isMobile ? 16 : 8]};
  }
  grid-template-columns: 0fr;
  flex-direction: ${({ $isMobile }) => (!$isMobile ? "column" : "row")};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[6]};
  margin-top: ${({ $isMobile }) => ($isMobile ? "16px" : "0")};
  padding-right: ${({ theme }) => theme.spacing[12]};
  text-align: left;
  white-space: pre;
`;

export const PriceColumn = styled<React.FC<BoxProps>>(Box)`
  display: grid;
  grid-template-rows: auto min-content;
`;

export const PriceInfo = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: end;
  justify-content: start;
`;

export const Discount = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const ViewDeal = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  justify-content: end;
`;

export const DealButton = styled<React.FC<ButtonProps>>(Button)`
  white-space: nowrap;
`;

export const DirectBillIcon = styled.span.attrs({})`
  display: inline-flex;
  align-items: center;

  img {
    width: 16px;
  }

  span {
    margin-left: -4px;
  }
`;
