import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
/**
 * Light icon imports (prefix: 'fal')
 */
import {
  faArrowsH as falArrowsH,
  faCalendar as falCalendar,
  faChartBar as falChartBar,
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faClipboardList as falClipboardList,
  faCogs as falCogs,
  faComment as falComment,
  faCommentAltEdit as falCommentAltEdit,
  faCreditCard as falCreditCard,
  faDownload as falDownload,
  faEnvelope as falEnvelope,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faEyeSlash as falEyeSlash,
  faFlag as falFlag,
  faHeart as falHeart,
  faHistory as falHistory,
  faHotel as falHotel,
  faInfoCircle as falInfoCircle,
  faLocation as falLocation,
  faLockAlt as falLockAlt,
  faMap as falMap,
  faMoneyCheckDollarPen as falMoneyCheckDollarPen,
  faPaperPlane as falPaperPlane,
  faPencil as falPencil,
  faPhone as falPhone,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faQuestionCircle as falQuestionCircle,
  faSearch as falSearch,
  faShareAlt as falShareAlt,
  faShieldCheck as falShieldCheck,
  faStar as falStar,
  faSuitcase as falSuitcase,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faUserCircle as falUserCircle,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
} from "@fortawesome/pro-light-svg-icons";
/**
 * Regular icon imports (prefix: 'far')
 */
import {
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faArrowToBottom as farArrowToBottom,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBed as farBed,
  faBuilding as farBuilding,
  faCalendar as farCalendar,
  faCalendarAlt as farCalendarAlt,
  faCalendarPen as farCalendarPen,
  faCalendarPlus as farCalendarPlus,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faClock as farClock,
  faCog as farCog,
  faCommentAltLines as farCommentAltLines,
  faCompress as farCompress,
  faCopy as farCopy,
  faCreditCard as farCreditCard,
  faDollarSign as farDollarSign,
  faEdit as farEdit,
  faEllipsisH as farEllipsisH,
  faEuroSign as farEuroSign,
  faExchange as farExchange,
  faExclamationCircle as farExclamationCircle,
  faExternalLink as farExternalLink,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFile as farFile,
  faFileAlt as farFileAlt,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faFrown as farFrown,
  faGift as farGift,
  faGlobe as farGlobe,
  faGrinStars as farGrinStars,
  faHeart as farHeart,
  faHistory as farHistory,
  faHotel as farHotel,
  faInfoCircle as farInfoCircle,
  faLocationDot as farLocationDot,
  faMagnifyingGlass as farMagnifyingGlass,
  faMapPin as farMapPin,
  faMegaphone as farMegaphone,
  faMeh as farMeh,
  faMinusCircle as farMinusCircle,
  faMoneyCheckDollarPen as farMoneyCheckDollarPen,
  faPaperPlane as farPaperPlane,
  faPlane as farPlane,
  faPoundSign as farPoundSign,
  faPrint as farPrint,
  faQuestionCircle as farQuestionCircle,
  faRepeat as farRepeat,
  faSearch as farSearch,
  faShare as farShare,
  faShieldCheck as farSheildCheck,
  faSignOut as farSignOut,
  faSmile as farSmile,
  faSpinnerThird as farSpinnerThird,
  faText as farText,
  faThumbsDown as farThumbsDown,
  faThumbsUp as farThumbsUp,
  faTimes as farTimes,
  faTimesCircle as farTimesCircle,
  faTired as farTired,
  faToolbox as farToolbox,
  faTrash as farTrash,
  faTruckMedical as farTruckMedical,
  faUniversity as farUniversity,
  faUser as farUser,
  faUserPlus as farUserPlus,
  faWallet as farWallet,
} from "@fortawesome/pro-regular-svg-icons";
/**
 * Solid icon imports (prefix: 'fas')
 */
import {
  faBan as fasBan,
  faBars as fasBars,
  faBed as fasBed,
  faBell as fasBell,
  faBolt as fasBolt,
  faCaretCircleDown as fasCaretCircleDown,
  faCaretDown as fasCaretDown,
  faCaretUp as fasCaretUp,
  faChartBar as fasChartBar,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronCircleLeft as fasChevronCircleLeft,
  faCircle as fasCircle,
  faCoffee as fasCoffee,
  faCog as fasCog,
  faCommentAlt as fasCommentAlt,
  faDirections as fasDirections,
  faDollarSign as fasDollarSign,
  faDumbbell as fasDumbbell,
  faEllipsisH as fasEllipsisH,
  faEnvelope as fasEnvelope,
  faEuroSign as fasEuroSign,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExpand as fasExpand,
  faExpandAlt as fasExpandAlt,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFilter as fasFilter,
  faFlag as fasFlag,
  faGlassMartiniAlt as fasGlassMartiniAlt,
  faHeart as fasHeart,
  faHexagonExclamation as fasHexagonExclamation,
  faIdCard as fasIdCard,
  faImages as fasImages,
  faInfoCircle as fasInfoCircle,
  faList as fasList,
  faListCheck as fasListCheck,
  faLocation as fasLocation,
  faLock as fasLock,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMegaphone as fasMegaphone,
  faMicrowave as fasMicrowave,
  faMinus as fasMinus,
  faMoneyBillWaveAlt as fasMoneyBillWaveAlt,
  faMoneyCheckDollarPen as fasMoneyCheckDollarPen,
  faOven as fasOven,
  faPaperPlane as fasPaperPlane,
  faParking as fasParking,
  faPencil as fasPencil,
  faPhone as fasPhone,
  faPlane as fasPlane,
  faPlug as fasPlug,
  faPlus as fasPlus,
  faPoundSign as fasPoundSign,
  faRotateRight as fasRotateRight,
  faShapes as fasShapes,
  faShare as fasShare,
  faShareSquare as fasShareSquare,
  faShieldCheck as fasShieldCheck,
  faSlidersH as fasSlidersH,
  faSlidersVSquare as fasSlidersVSquare,
  faSmokingBan as fasSmokingBan,
  faSnowflake as fasSnowflake,
  faSpinnerThird as fasSpinnerThird,
  faStar as fasStar,
  faStream as fasStream,
  faSuitcaseRolling as fasSuitcaseRolling,
  faSwimmer as fasSwimmer,
  faSync as fasSync,
  faTelescope as fasTelescope,
  faThLarge as fasThLarge,
  faTimesCircle as fasTimesCircle,
  faTrash as fasTrash,
  faUser as fasUser,
  faUserFriends as fasUserFriends,
  faUserLock as fasUserLock,
  faUserPlus as fasUserPlus,
  faWheelchair as fasWheelchair,
  faWifi as fasWifi,
  faWindowClose as fasWindowClose,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarSharp as faSsStarSharp } from "@fortawesome/sharp-solid-svg-icons";

library.add(
  faSsStarSharp,
  faFacebookSquare,
  falArrowsH,
  falCalendar,
  falChartBar,
  falCheckCircle,
  falChevronDown,
  falChevronUp,
  falClipboardList,
  falCogs,
  falComment,
  falCommentAltEdit,
  farCommentAltLines,
  falCreditCard,
  falDownload,
  falEnvelope,
  falExclamationCircle,
  falExclamationTriangle,
  falEyeSlash,
  falFlag,
  falHeart,
  falHistory,
  falHotel,
  falInfoCircle,
  faLinkedin,
  falLocation,
  falLockAlt,
  falMap,
  falMoneyCheckDollarPen,
  falPaperPlane,
  falPhone,
  falPlus,
  falPlusCircle,
  falPencil,
  falQuestionCircle,
  falSearch,
  falShareAlt,
  falShieldCheck,
  falStar,
  falSuitcase,
  falTimes,
  falTimesCircle,
  falUserCircle,
  falUserPlus,
  falUsers,
  farArrowLeft,
  farArrowRight,
  farArrowToBottom,
  farArrowUpRightFromSquare,
  farBed,
  farBuilding,
  farCalendar,
  farCalendarAlt,
  farCalendarPen,
  farCalendarPlus,
  farCheck,
  farCheckCircle,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farChevronUp,
  farClock,
  farCog,
  farCompress,
  farCopy,
  farCreditCard,
  farDollarSign,
  farEdit,
  farEllipsisH,
  farEye,
  farEyeSlash,
  farEuroSign,
  farExchange,
  farExclamationCircle,
  farCommentAltLines,
  farFile,
  farFileAlt,
  farFileInvoiceDollar,
  farFrown,
  farGlobe,
  farGift,
  farGrinStars,
  farHeart,
  fasHexagonExclamation,
  farHotel,
  farInfoCircle,
  farLocationDot,
  farMagnifyingGlass,
  farMapPin,
  farMegaphone,
  farMeh,
  farMinusCircle,
  farMoneyCheckDollarPen,
  farPaperPlane,
  farPlane,
  farPoundSign,
  farPrint,
  farRepeat,
  farQuestionCircle,
  farSearch,
  farShare,
  farSheildCheck,
  farSignOut,
  farSmile,
  farSpinnerThird,
  farText,
  farThumbsUp,
  farThumbsDown,
  farTimes,
  farTimesCircle,
  farTired,
  farTrash,
  farTruckMedical,
  farToolbox,
  farUniversity,
  farUser,
  farUserPlus,
  farWallet,
  fasBars,
  fasBan,
  fasBed,
  fasBell,
  fasBolt,
  fasCaretCircleDown,
  fasCaretDown,
  fasCaretUp,
  fasCircle,
  fasChartBar,
  fasCheckCircle,
  fasCheck,
  fasChevronCircleLeft,
  fasCoffee,
  fasCog,
  fasCommentAlt,
  fasDirections,
  fasDollarSign,
  fasDumbbell,
  fasEllipsisH,
  fasEnvelope,
  fasEuroSign,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasExpand,
  fasExpandAlt,
  fasEye,
  fasEyeSlash,
  fasFilter,
  fasFlag,
  fasGlassMartiniAlt,
  fasHeart,
  farHistory,
  fasIdCard,
  fasImages,
  fasInfoCircle,
  fasList,
  fasListCheck,
  fasLocation,
  fasLock,
  fasMapMarkerAlt,
  fasMegaphone,
  fasMicrowave,
  fasMinus,
  fasMoneyBillWaveAlt,
  fasMoneyCheckDollarPen,
  fasOven,
  fasPaperPlane,
  fasParking,
  fasPencil,
  fasPhone,
  fasPlane,
  fasPlug,
  fasPoundSign,
  fasRotateRight,
  fasPlus,
  fasShapes,
  fasShare,
  fasShareSquare,
  fasShieldCheck,
  fasSlidersH,
  fasSlidersVSquare,
  fasSmokingBan,
  fasSnowflake,
  fasSpinnerThird,
  fasStar,
  fasStream,
  fasSuitcaseRolling,
  fasSwimmer,
  fasSync,
  fasTelescope,
  fasThLarge,
  fasTimesCircle,
  fasTrash,
  fasUser,
  fasUserFriends,
  fasUserLock,
  fasUserPlus,
  fasWheelchair,
  fasWifi,
  fasWindowClose,
  faTwitterSquare,
  farExternalLink
);

/**
 * @deprecated Please use the `Icon` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/icon-eeqv1LXW Atlas Web Icon Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-icon--docs Atlas Web Icon Storybook}
 *
 * @summary The `Icon` component is leveraging a subset of the `fontawesome` icon library with light, solid and regular options available.
 *
 * @example <Icon icon={["far", "user-plus"]} />
 * @remarks When adding a new icon, make sure that a) it's from the right package 2) the import is alphabetically sorted and 3) the import is renamed appropriately. The icon name is in Figma's parent component section on the right pane
 * @see {@link https://fontawesome.com/icons?d=gallery&p=2 FontAwesome Icons}
 * @see {@link https://www.figma.com/file/RHhWd2Gs1l7FLwBy9DLS46kV/Foundation-LIVE?node-id=2065%3A2843 Design Specs}
 */
export const Icon = FontAwesomeIcon;
