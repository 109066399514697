import { useEffect, useMemo } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";

import type { WexPromptsField } from "@hotel-engine/types/paymentProfile";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import * as Styled from "./styles";

const WexPromptsFieldsInput: Record<
  WexPromptsField,
  { label?: string; disabled?: boolean; type?: string }
> = {
  driverNumber: {
    label: "Driver number",
  },
  tripNumber: {
    label: "Trip number",
  },
  driversLicenseNumber: {
    label: "Driver's license number",
    disabled: true,
  },
  unitNumber: {
    label: "Unit number",
  },
  odometer: {
    label: "Odometer",
    type: "number",
  },
  poNumber: {
    label: "PO number",
  },
  jobNumber: {
    label: "Job number",
  },
  trailerNumber: {
    label: "Trailer number",
  },
  vehicleNumber: {
    label: "Vehicle number",
  },
};

export type WexPromptsProps = {
  fields: Partial<Record<WexPromptsField, string | number>>;
};

export const WexPrompts = ({ fields }: WexPromptsProps) => {
  const { setFieldValue } = useFormikContext();

  const Fields = useMemo(() => Object.entries(fields), [fields]);

  useEffect(() => {
    const setFieldsValues = () => {
      setFieldValue("wexPrompts", []);
      const fieldArray = Fields.map(([key, value]) => ({
        field: key,
        value,
      }));
      setFieldValue("wexPrompts", fieldArray);
    };

    setFieldsValues();
  }, [Fields, setFieldValue]);

  if (!Fields.length) return <></>;

  return (
    <Box display="flex" flexDirection="column" gap={24}>
      <Divider variant="dotted" />
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography as="h3" variant="heading/md">
          Your information
        </Typography>
        <Typography variant="body/sm-light">
          Please ensure all information previously added is up to date.
        </Typography>
      </Box>
      <Styled.PromptsContainer>
        <FieldArray name="wexPrompts">
          {() => (
            <>
              {Fields.map(([key, _value], index) => (
                <Field
                  key={key}
                  {...WexPromptsFieldsInput[key]}
                  name={`wexPrompts[${index}].value`}
                  maxLength={20}
                  component={InputField}
                />
              ))}
            </>
          )}
        </FieldArray>
      </Styled.PromptsContainer>
    </Box>
  );
};
