import { Form } from "formik";
import styled, { css } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const ReviewContentsWrapper = styled.main`
  padding: 24px;
  h1 {
    ${typographyMixin("large")};
    color: ${colors.errieBlack};
  }
  p {
    ${typographyMixin("body-medium")};
    color: ${colors.errieBlack};
  }
`;

export const FormikForm = styled(Form)`
  width: 100%;
`;

export const ReviewForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StarCopy = styled.p(
  typographyMixin("body-medium"),
  css`
    margin: 20px 0 24px;
    color: ${colors.grey[700]};
  `
);

export const PropertyInfoWrapper = styled.div`
  padding: 0 0 24px;
  border-bottom: 1px solid ${colors.grey[100]};
  width: 100%;
`;

export const ReviewFormWrapper = styled.div`
  padding: 24px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReviewLabel = styled.p(
  typographyMixin("large"),
  css`
    color: ${colors.grey[800]};
  `
);

export const ReviewFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0 0;
`;

export const ReviewButton = styled(Button)`
  padding: 8px 15px;
  height: 40px;
`;

export const SubmitButton = styled(ReviewButton)`
  ${({ disabled }) =>
    disabled &&
    css`
      &.ant-btn-primary:disabled {
        background-color: ${colors.grey[200]};
        border-color: ${colors.grey[200]};
        color: ${colors.grey[400]};
      }
    `}
`;
