import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constants";
import {
  toggleSearchMapClusterPinAddPointCountToZIndex,
  updateSearchMapCenterPinVariants,
  updateSearchMapClusteringProperties,
  updateSearchMapClusterPinVariants,
  updateSearchMapControls,
  updateSearchMapCustomControls,
  updateSearchMapDatasetProperties,
  updateSearchMapFitToBoundsProperties,
  updateSearchMapOptions,
  updateSearchMapPinAnchor,
  updateSearchMapPricePinVariants,
} from "./reducers";

export const searchMapSlice = createSlice({
  name: "SearchMap",
  initialState,
  reducers: {
    updateSearchMapDatasetProperties,
    updateSearchMapClusteringProperties,
    updateSearchMapOptions,
    updateSearchMapControls,
    updateSearchMapCustomControls,
    updateSearchMapFitToBoundsProperties,
    updateSearchMapPinAnchor,
    updateSearchMapPricePinVariants,
    updateSearchMapClusterPinVariants,
    toggleSearchMapClusterPinAddPointCountToZIndex,
    updateSearchMapCenterPinVariants,
    resetSearchMapProperties: () => initialState,
  },
});

export const searchMapActions = searchMapSlice.actions;

export default searchMapSlice.reducer;
