import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `TextInput` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/text-input-H7TKgKMu Atlas Web TextInput Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-text-input--docs Atlas Web TextInput Storybook}
 *
 * @summary The `Input`, `Search`, and `TextArea` components wraps antd's `<Input />`, `<Input.Search />`, and `<Input.TextArea />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Input component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/input/ AntD Input Documentation}
 * @see {@link https://3x.ant.design/components/input/#Input.Search AntD Search Input Documentation}
 * @see {@link https://3x.ant.design/components/input/#Input.TextArea AntD Text Area Input Documentation}
 *
 */
export const Input = Styled.AntInput;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `TextInput` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/text-input-H7TKgKMu Atlas Web TextInput Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-text-input--docs Atlas Web TextInput Storybook}
 */
export const Search = Styled.AntInputSearch;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `TextArea` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/text-area-91qWEB5x Atlas Web TextArea Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-text-area--docs Atlas Web TextInput Storybook}
 */
export const TextArea = Styled.AntInputTextArea;

export type { Input as TInput } from "antd";

export type { InputProps } from "antd/lib/input";
