import { useRef, useState } from "react";
import { BasisTheoryProvider, useBasisTheory } from "@basis-theory/basis-theory-react";
import { type TextElement } from "@basis-theory/basis-theory-react/types";
import { Field, Form, Formik, type FormikProps } from "formik";
import moment from "moment";
import { Alert, Box, Divider, Typography } from "@hotelengine/atlas-web";

import config from "config";
import { useCreatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useCreatePaymentProfile";
import { PrivacyPolicyDisclaimer } from "@hotel-engine/app/PrivacyPolicyDisclaimer";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import CheckboxField from "@hotel-engine/common/FormikFields/CheckboxField";
import Button from "@hotel-engine/common/Button";
import { useUser } from "@hotel-engine/hooks";
import type {
  IPaymentProfile,
  IPaymentProfileCreateParams,
  IPaymentProfileUpdateParams,
  WexCardPaymentProfile,
} from "@hotel-engine/types/paymentProfile";
import { CreditCardInput } from "./components/CreditCardInput";
import { BasisTheoryInput } from "./components/BasisTheoryInput";
import { ExpDateInput } from "./components/ExpDateInput";
import { wexFormSchema, type WexFormValues } from "./schema";
import * as Styled from "./styles";
import { useUpdatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useUpdatePaymentProfile";
import { type IErrorResponse, isIPaymentProfileError } from "@hotel-engine/types/errors";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { FieldsErrorList, formatErrorReason } from "./helpers";

export type WexFormProps = {
  existingPaymentProfile?: WexCardPaymentProfile;
  onSubmitSuccess: (paymendProfile: IPaymentProfile) => void;
  onCancel: () => void;
};

export const WexForm = ({ existingPaymentProfile, onSubmitSuccess, onCancel }: WexFormProps) => {
  const [token, setToken] = useState<string | undefined>(existingPaymentProfile?.basisTheoryToken);
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const user = useUser();
  const { bt } = useBasisTheory(config.basisTheoryKey, { elements: true });
  const createPaymentProfile = useCreatePaymentProfile();
  const updatePaymentProfile = useUpdatePaymentProfile();

  const formRef = useRef<FormikProps<WexFormValues>>(null);
  const cardNumberRef = useRef<TextElement>(null);
  const driverLicenseRef = useRef<TextElement>(null);

  const isEditingMode = !!existingPaymentProfile;

  const handleTokenization = async () => {
    const cardNumber = cardNumberRef.current;
    const driverLicense = driverLicenseRef.current;

    const btPayload = {
      data: {
        card_number: cardNumber,
        drivers_license_number: driverLicense,
      },
      mask: {
        card_number: "{{ data.card_number | reveal_last: 4 }}",
        drivers_license_number: "{{ data.drivers_license_number | reveal_last: 2 }}",
      },
      containers: ["/pci/"],
    };

    if (!token) {
      const newToken = await bt?.tokens.create({
        type: "token",
        ...btPayload,
      });

      setToken(newToken?.id);
      return newToken;
    } else {
      const newToken = await bt?.tokens.update(token, {
        ...btPayload,
      });
      return newToken;
    }
  };

  const handleSubmit = async (values: WexFormValues) => {
    setApiErrors([]);

    const cardNumber = cardNumberRef.current;
    if (cardNumber && cardNumber.metadata.empty) {
      formRef.current?.setFieldError("cardNumber", "Card number is required");
      cardNumber.focus();
      return;
    }

    const expDate = moment(values.expDate, "MM/YY");
    const payload = {
      expirationMonth: values.expDate && String(expDate.get("month") + 1),
      expirationYear: values.expDate && String(expDate.get("year")),
      nickname: values.nickname,
      name: user.firstName && `${user.firstName}${` ${user.lastName}`}`,
      metadata: {
        ...(values.odometer && { odometer: String(values.odometer) }),
        ...(values.poNumber && { poNumber: values.poNumber }),
        ...(values.trailerNumber && { trailerNumber: values.trailerNumber }),
        ...(values.jobNumber && { jobNumber: values.jobNumber }),
        ...(values.vehicleNumber && { vehicleNumber: values.vehicleNumber }),
        ...(values.driverNumber && { driverNumber: values.driverNumber }),
        ...(values.tripNumber && { tripNumber: values.tripNumber }),
        ...(values.unitNumber && { unitNumber: values.unitNumber }),
      },
      default: values.default,
    } as IPaymentProfileCreateParams | IPaymentProfileUpdateParams;

    const onSuccess = (data: IPaymentProfile) => {
      onSubmitSuccess(data);
    };

    const onError = (error: IErrorResponse) => {
      captureMessage("Wex payment profile form: save/update error", {
        data: values,
        error,
      });

      const errorResponse = error.response?.data;
      if (isIPaymentProfileError(errorResponse)) {
        errorResponse.errorsList?.forEach((err) => {
          const reason = formatErrorReason(err.reason ?? "");
          if (err.fieldName && FieldsErrorList[err.fieldName]) {
            formRef.current?.setFieldError(FieldsErrorList[err.fieldName], reason);
          } else {
            setApiErrors((prev) => [...prev, reason ?? ""]);
          }
        });
      } else {
        setApiErrors(["An error occurred. Please try again."]);
      }
    };

    if (!isEditingMode) {
      const btToken = await handleTokenization();

      const btTokenData = btToken?.data as
        | { card_number: string; drivers_license_number?: string }
        | undefined;
      const cardNumberMasked = btTokenData?.card_number;
      const driversLicenseNumberMasked = btTokenData?.drivers_license_number;

      createPaymentProfile.mutate(
        {
          ...payload,
          cardType: "wex",
          maskedCardNumber: cardNumberMasked,
          basisTheoryToken: btToken?.id,
          last4: cardNumberMasked?.substring(cardNumberMasked.length - 4),
          metadata: {
            ...("metadata" in payload && payload.metadata),
            ...(driversLicenseNumberMasked && { driversLicenseNumber: driversLicenseNumberMasked }),
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      const paymentProfile: Omit<WexCardPaymentProfile, "cardNumber"> & { cardNumber?: string } = {
        ...existingPaymentProfile,
      };
      delete paymentProfile.cardNumber;

      updatePaymentProfile.mutate(
        {
          ...paymentProfile,
          ...payload,
          metadata: {
            ...("metadata" in paymentProfile && paymentProfile.metadata),
            ...("metadata" in payload && payload.metadata),
          },
          id: existingPaymentProfile.id,
        } as IPaymentProfileUpdateParams,
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const hasExpDate =
    existingPaymentProfile?.expirationMonth && existingPaymentProfile?.expirationYear;
  const expDate =
    isEditingMode && hasExpDate
      ? moment(
          `${existingPaymentProfile.expirationMonth}/${existingPaymentProfile.expirationYear}`,
          existingPaymentProfile.expirationYear.length === 4 ? "MM/YYYY" : "MM/YY"
        )
      : undefined;

  const initialValues: WexFormValues = existingPaymentProfile
    ? {
        cardNumber: existingPaymentProfile.cardNumber,
        expDate: hasExpDate ? `${expDate?.format("MM/YY")}` : undefined,
        nickname: existingPaymentProfile.nickname ?? "",
        default: existingPaymentProfile.default,
        ...existingPaymentProfile.metadata,
        odometer:
          existingPaymentProfile?.metadata && existingPaymentProfile.metadata.odometer
            ? Number(existingPaymentProfile.metadata.odometer)
            : undefined,
      }
    : ({} as WexFormValues);

  const errorTitle =
    apiErrors.length === 1 ? apiErrors[0] : "A few errors occurred. Please try again.";

  return (
    <BasisTheoryProvider bt={bt}>
      <Box display="flex" flexDirection="column" gap={16}>
        <Typography as="h3" variant="heading/md">
          {isEditingMode ? "Edit" : "Add"} Wex Fleet Card
        </Typography>
        {apiErrors.length > 0 && (
          <Alert size="sm" title={errorTitle} sentiment="critical">
            {apiErrors.length > 1 && (
              <ul>
                {apiErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={wexFormSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ errors, isSubmitting }) => {
            const isLoading =
              isSubmitting || createPaymentProfile.isLoading || updatePaymentProfile.isLoading;
            return (
              <Form data-private data-testid="payment-method-wex-form">
                <Box display="flex" flexDirection="column">
                  <CreditCardInput
                    ref={cardNumberRef}
                    error={errors.cardNumber}
                    value={initialValues.cardNumber}
                  />
                  <Field
                    component={ExpDateInput}
                    name="expDate"
                    disabled={!!initialValues.expDate}
                  />
                  <Field
                    component={InputField}
                    name="nickname"
                    label="Card nickname (optional)"
                    placeholder="Rewards"
                  />
                </Box>
                <Box marginTop={4} marginBottom={20}>
                  <Divider variant="dotted" />
                </Box>
                <Box display="flex" flexDirection="column" gap={4} marginBottom={20}>
                  <Typography as="h3" variant="heading/md">
                    Additional details
                  </Typography>
                  <Typography variant="body/sm-light">
                    Please provide the necessary information below where applicable. Please ensure
                    all information is up to date.
                  </Typography>
                </Box>
                <Styled.AdditionalFieldsContainer>
                  <Field
                    component={InputField}
                    name="driverNumber"
                    label="Driver number"
                    placeholder="######"
                    maxLength={20}
                  />
                  <Field
                    component={InputField}
                    name="tripNumber"
                    label="Trip number"
                    placeholder="######"
                    maxLength={20}
                  />
                  <BasisTheoryInput
                    id="driversLicenseNumber"
                    label="Driver's license number"
                    error={errors.driversLicenseNumber}
                    placeholder="######"
                    maxLength={20}
                    disabled={isEditingMode}
                    value={initialValues.driversLicenseNumber}
                    ref={driverLicenseRef}
                  />
                  <Field
                    component={InputField}
                    name="unitNumber"
                    label="Unit number"
                    placeholder="######"
                    maxLength={20}
                  />
                  <Field
                    component={InputField}
                    name="odometer"
                    label="Odometer"
                    placeholder="######"
                    type="number"
                    maxLength={20}
                  />
                  <Field
                    component={InputField}
                    name="poNumber"
                    label="PO number"
                    placeholder="######"
                    maxLength={20}
                  />
                  <Field
                    component={InputField}
                    name="trailerNumber"
                    label="Trailer number"
                    placeholder="######"
                    maxLength={20}
                  />
                  <Field
                    component={InputField}
                    name="jobNumber"
                    label="Job number"
                    placeholder="######"
                    maxLength={20}
                  />
                </Styled.AdditionalFieldsContainer>
                <Field
                  component={InputField}
                  name="vehicleNumber"
                  label="Vehicle number"
                  placeholder="######"
                  maxLength={20}
                />
                <Field name="default" component={CheckboxField} label="Make default card" />
                <Box display="flex" gap={8} marginTop={4} marginBottom={20}>
                  <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                    Save Card
                  </Button>
                  <Button type="ghost" htmlType="button" disabled={isLoading} onClick={onCancel}>
                    Cancel
                  </Button>
                </Box>
                <Alert
                  size="sm"
                  sentiment="helpful"
                  title="To verify your card, you may see a temporary charge of $1 on your account. This amount will be refunded automatically."
                />
                <Box marginTop={20}>
                  <PrivacyPolicyDisclaimer $top={20} />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </BasisTheoryProvider>
  );
};
