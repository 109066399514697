import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used.
 *
 * @summary The `Form` component wraps antd's `<Form />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Form component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/form/ AntD Form Documentation}
 */
export const Form = Styled.AntForm;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `FormControl` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/form-control-bFx0WsJj Atlas Web FormControl Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-form-control--docs Atlas Web FormControl Storybook}
 */
export const FormItem = Styled.AntFormItem;

export type { FormItemProps } from "antd/lib/form";
