import { useFormikContext } from "formik";

import { Avatar, Icon } from "@hotelengine/atlas-web";
import type { ICheckoutFormData } from "pages/Checkout/validation";

export const Prefix = ({
  guestType,
  index,
}: {
  guestType: "primary" | "secondary";
  index: number;
}) => {
  const form = useFormikContext<ICheckoutFormData>();

  const selectedUser = form.values.guestList[index][guestType].user;
  if (selectedUser) {
    const { firstName, lastName, email, businessId } = selectedUser;
    const name = !!firstName && !!lastName ? `${firstName} ${lastName}` : email ?? "Guest";
    return <Avatar size={32} name={name} variant={!!businessId ? "filled" : "outlined"} />;
  }

  return <Icon name="user-plus--solid" />;
};
