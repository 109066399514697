import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `RadioGroup` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/radio-group-MgLSD7Ey Atlas Web Radio Group Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-radio-group--docs Atlas Web Radio Group Storybook}
 *
 * @remarks The `Radio` component wraps antd's `<Radio />` component.
 *
 * Since we are leveraging antd for most components, our internal Radio component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/radio/ AntD Radio Documentation}
 */
export const Radio = Styled.AntRadio;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `RadioGroup` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/radio-group-MgLSD7Ey Atlas Web Radio Group Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-radio-group--docs Atlas Web Radio Group Storybook}
 */
export const RadioGroup = Styled.AntRadioGroup;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `RadioGroup` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/radio-group-MgLSD7Ey Atlas Web Radio Group Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-radio-group--docs Atlas Web Radio Group Storybook}
 */
export const RadioButton = Styled.AntRadioButton;

export type { RadioChangeEvent } from "antd/lib/radio";
export type { RadioProps } from "antd/lib/radio";
