import styled, { css } from "styled-components";
import { type ElementStyle } from "@basis-theory/basis-theory-react/types";
import { Box, Typography, type BoxProps } from "@hotelengine/atlas-web";

import { colors } from "@hotel-engine/styles";

export const Container = styled<React.FC<BoxProps>>(Box)``;

export const InputContainer = styled<React.FC<BoxProps>>(Box)<{
  $hasError: boolean;
  $isFocused: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius[4]};
  border: 1px solid ${({ $hasError }) => ($hasError ? colors.red[400] : colors.grey[300])};
  width: 100%;
  height: 40px;
  outline: none;
  transition: all 0.3s;

  > div:first-child {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  &:hover {
    border-color: ${({ $hasError }) => ($hasError ? colors.red[400] : colors.antdFocusBlue)};
  }

  ${({ $isFocused, $hasError }) =>
    $isFocused &&
    css`
    border-color: ${colors.antdFocusBlue};
    box-shadow: ${
      $hasError ? "0 0 0 2px rgba(255, 53, 53, 0.2)" : "0 0 0 2px rgba(47, 88, 235, 0.2)"
    };
  `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
    background: ${colors.grey[50]};

    &:hover {
      border-color: ${colors.grey[300]};
    }
  `}
`;

export const Hint = styled(Typography).attrs({
  as: "span",
  variant: "body/sm-light",
})<{
  $hasError: boolean;
}>`
  color: ${({ $hasError }) => ($hasError ? colors.red[400] : colors.kiwiGrey)};
`;

export const TextElement: ElementStyle = {
  base: {
    "::placeholder": { color: "#929395" },
    ":disabled": {
      backgroundColor: colors.grey[50],
      color: colors.grey[800],
    },
  },
};
