import { useState } from "react";

import { Button, Sheet, SheetContent } from "@hotelengine/atlas-web";
import storage from "@hotel-engine/storage";

import * as Styled from "./CookieBanner.styles";
import { CookieSettings } from "./CookieSettings";
import { COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";

export const CookieBanner = (args: {
  onClose: () => void;
  setAmplitudeCookieAccepted: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Styled.CookieBannerContainer data-testid="cookie-banner">
        <Styled.CookieBannerContent display="flex" justifyContent="space-between">
          <Styled.CookieText variant="body/md-light" padding={16}>
            Our site uses cookies to ensure you get the best experience while using it. By clicking
            'OK' you agree to our use of cookies. Please see our{" "}
            <a href={COMPANY_ROUTES.privacy}>privacy policy</a> or click 'Manage' to review your
            cookie preferences.{" "}
          </Styled.CookieText>
          <Styled.ButtonContainer display="flex">
            <Styled.Approve
              color="accent"
              size="sm"
              onClick={() => {
                storage.setSecureItem(
                  "cookiePerms",
                  JSON.stringify({ Statistics: true, Marketing: true })
                );
                args.setAmplitudeCookieAccepted();
                args.onClose();
              }}
            >
              OK
            </Styled.Approve>
            <Button variant="outlined" size="sm" onClick={() => setIsModalVisible(true)}>
              Manage
            </Button>
          </Styled.ButtonContainer>
        </Styled.CookieBannerContent>
      </Styled.CookieBannerContainer>
      <Sheet isOpen={isModalVisible}>
        <SheetContent>
          <CookieSettings
            setAmplitudeCookieAccepted={args.setAmplitudeCookieAccepted}
            onOk={() => (setIsModalVisible(false), args.onClose())}
          />
        </SheetContent>
      </Sheet>
    </>
  );
};
