import { googleMaps } from "@hotel-engine/scripts/google";
import { captureMessage } from "@hotel-engine/utilities";
import { attempt, isError, once } from "lodash/fp";
import type { Dispatch, SetStateAction } from "react";
import type { GoogleMapState } from "@hotel-engine/app/GoogleMap";
import type { OverlayViewState } from "@hotel-engine/app/GoogleMap/components/OverlayView";
import type {
  ICircleRegistrationParams,
  SetMapLoadingTimes,
  IMapRegistrationParams,
  InitCircleParams,
  InitMapParams,
  InitOverlayParams,
  IOverlayRegistrationParams,
  MapListeners,
  MapPosition,
  InitRectangleParams,
  IRectangleRegistrationParams,
} from "@hotel-engine/app/GoogleMap/types";
import { colors } from "@hotel-engine/styles";

export default function GoogleMapsService() {
  /** Load Google Maps API */
  function loadMaps() {
    return googleMaps.importLibrary("maps");
  }

  /**
   * @param api loaded Google Maps API
   * @param element referenced DOM Element
   * @param options Map options
   * @returns google map instance
   */
  const registerMap = once(({ api, element, options }: IMapRegistrationParams) => {
    return new api.Map(element, options);
  });

  /**
   * @param api loaded Google Maps API
   * @param map loaded Google Map object
   * @param position lat / lng object
   * @param radius miles converted to meters
   * @returns radius circle instance
   */
  const registerCircle = once(
    ({
      api,
      map,
      position,
      radius,
      isSearchResults,
      visible = true,
    }: ICircleRegistrationParams) => {
      return new api.Circle({
        center: position,
        fillColor: colors.blue[500],
        fillOpacity: isSearchResults ? 0 : 0.35,
        map,
        radius: radius,
        strokeOpacity: isSearchResults ? 1 : 0,
        strokeWeight: 2,
        strokeColor: colors.blue[500],
        clickable: false,
        visible,
      });
    }
  );

  /**
   * @param api loaded Google Maps API
   * @param map loaded Google Map object
   * @param position lat / lng object
   * @param radius miles converted to meters
   * @returns radius circle instance
   */
  const registerRectangle = once(
    ({ api, map, bounds, visible = true }: IRectangleRegistrationParams) => {
      return new api.Rectangle({
        map,
        bounds,
        clickable: false,
        visible,
      });
    }
  );

  /** Add event listeners to map, currently used listeners are dragend isZoomable
   * @param map loaded Google Map object
   * @param listeners object of listeners and their callback functions
   */
  function addListeners(map: google.maps.Map, listeners: MapListeners) {
    Object.keys(listeners).forEach((key) => {
      map.addListener(key, listeners[key]);
    });
  }

  /** Remove event listeners from map
   * @param map loaded Google Map object
   * @param listeners object of listeners and their callback functions
   */
  function removeListeners(map: google.maps.Map, listeners: MapListeners) {
    Object.keys(listeners).forEach((key) => {
      google.maps.event.clearListeners(map, key);
    });
  }

  /** create new Map object and set it to state
   * @param params `{ element: referenced DOM Element, options: Map options }`
   * @param handler function to set response to state
   */
  function initMap(
    params: InitMapParams,
    handler: (obj: Partial<GoogleMapState>) => void,
    setMapLoadingTimes?: SetMapLoadingTimes
  ) {
    const startedAt = Date.now();
    const errorMessage = "Google was unable to display this map";
    loadMaps()
      .then((api) => registerMap({ api, ...params }))
      .then((res) => {
        const response = attempt(() => {
          const finishedAt = Date.now();

          handler({ isLoading: false, map: res });
          setMapLoadingTimes?.({ finishedAt, startedAt });
        });

        if (isError(response)) {
          handler({ isLoading: false, mapLoadingError: true });
          captureMessage("Error loading handler", {
            error: response,
          });
        }
      })
      .catch((error) => {
        captureMessage(errorMessage, { error, params }, "info");
      });
  }

  /** create new Overlay object and set it to state
   * @param params `{ container: HTMLElement, pane: sets the overlay type relative to the map, position: lat, lng to position custom marker }`
   * @param handler function to set response to state
   */
  function initOverlay(
    { container, pane, position }: InitOverlayParams,
    setOverlay: Dispatch<SetStateAction<OverlayViewState>>
  ) {
    const errorMessage = "Google was unable to create this overlay";
    loadMaps()
      .then((api) => createOverlay({ api, container, pane, position }))
      .then((res) => {
        const response = attempt(() => {
          setOverlay(res);
        });

        if (isError(response)) {
          captureMessage("Error loading overlay", {
            error: response,
          });
        }
      })
      .catch((error) => {
        captureMessage(errorMessage, { error, container }, "info");
      });
  }

  /** create new Circle object and set it to state
   * @param params `{ map: loaded Google Map object, position: lat / lng object, radius: miles converted to meters }`
   * @param setCircle stateSetter for GoogleMapsRadiusMarker component
   */
  function initCircle(
    params: InitCircleParams,
    setCircle: Dispatch<SetStateAction<google.maps.Circle | undefined>>
  ) {
    const errorMessage = "Google was unable to create this circle";
    loadMaps()
      .then((api) => registerCircle({ api, ...params }))
      .then((res) => {
        const response = attempt(() => {
          setCircle(res);
        });

        if (isError(response)) {
          captureMessage("Error loading overlay", {
            error: response,
          });
        }
      })
      .catch((error) => {
        captureMessage(errorMessage, { error }, "info");
      });
  }

  /** create new Rectangle object and set it to state
   * @param params `{ map: loaded Google Map object, position: lat / lng object, radius: miles converted to meters }`
   * @param setRectangle stateSetter
   */
  function initRectangle(
    params: InitRectangleParams,
    setRectangle: Dispatch<SetStateAction<google.maps.Rectangle | undefined>>
  ) {
    const errorMessage = "Google was unable to create this rectangle";
    loadMaps()
      .then((api) => registerRectangle({ api, ...params }))
      .then((res) => {
        const response = attempt(() => {
          setRectangle(res);
        });

        if (isError(response)) {
          captureMessage("Error loading overlay", {
            error: response,
          });
        }
      })
      .catch((error) => {
        captureMessage(errorMessage, { error }, "info");
      });
  }

  function createOverlay({ api, container, pane, position }: IOverlayRegistrationParams) {
    class Overlay extends api.OverlayView {
      container: HTMLElement;
      pane: keyof google.maps.MapPanes;
      position: MapPosition;

      constructor(
        constructorContainer: HTMLElement,
        constructorPane: keyof google.maps.MapPanes,
        constructorPosition: MapPosition
      ) {
        super();
        this.container = constructorContainer;
        this.pane = constructorPane;
        this.position = constructorPosition;
      }

      onAdd(): void {
        const appendPane = this.getPanes()?.[this.pane];
        appendPane?.appendChild(this.container);
      }

      draw(): void {
        const projection = this.getProjection();
        const point = projection.fromLatLngToDivPixel(this.position);
        if (point === null) {
          return;
        }
        this.container.style.transform = `translate(${point.x}px, ${point.y}px)`;
      }

      onRemove(): void {
        if (this.container.parentNode !== null) {
          this.container.parentNode.removeChild(this.container);
        }
      }
    }

    return new Overlay(container, pane, position);
  }

  return {
    initMap: (
      params: InitMapParams,
      handler: (obj: Partial<GoogleMapState>) => void,
      setMapLoadingTimes?: SetMapLoadingTimes
    ) => initMap(params, handler, setMapLoadingTimes),
    addListeners: (map: google.maps.Map, listeners: MapListeners) => {
      addListeners(map, listeners);
    },
    removeListeners: (map: google.maps.Map, listeners: MapListeners) => {
      removeListeners(map, listeners);
    },
    initOverlay: (
      params: InitOverlayParams,
      setOverlay: Dispatch<SetStateAction<OverlayViewState>>
    ) => initOverlay(params, setOverlay),
    initCircle: (
      params: InitCircleParams,
      setCircle: Dispatch<SetStateAction<google.maps.Circle | undefined>>
    ) => initCircle(params, setCircle),
    initRectangle: (
      params: InitRectangleParams,
      setRectangle: Dispatch<SetStateAction<google.maps.Rectangle | undefined>>
    ) => initRectangle(params, setRectangle),
  };
}
