import { useState, useEffect } from "react";
import { getCountryForTimezone } from "countries-and-timezones";
import { useFormikContext } from "formik";
import type { CountryData } from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";

import type { IJoinFormData } from "pages/Join/definitions/validation";

import { twilioListedCountries } from "./constants";
import * as Styled from "./styles";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";

export interface IInternationalPhoneInputProps {
  /** Label to be applied to the phone input */
  label?: string;
  /** Name of the phone input, defaults to 'phone' */
  name?: string;
  /** Makes it possible to extend the styles as needed by creating a new styled component from this one, passed automatically */
  className?: string;
  /** Determines the focus of the phone input if more than one are rendered */
  index?: number;
  /** Determines if this input should use an alternate error message featuring an icon */
  includeErrorIcon?: boolean;
  /** Determines if the input's form will submit upon pressing 'Enter' on the keyboard. Defaults to true. **/
  submitOnEnter?: boolean;
  /** Determines if the error message should be shown immediately or on blur */
  showErrorOnBlur?: boolean;
  /** Data test id for the phone input */
  dataTestId?: string;
  /** Determines if component should display an error message or not */
  shouldShowError?: boolean;
}

/**
 * InternationalPhoneInput component is utilized within a Formik wrapper where it updates the phone field value.
 * It also uses data from the browser's location to automatically select the default & preferred country.
 * It leverages React-Phone-Input-2 as the main input which is customizable with aut formatting
 * In our implementation, we are passing a `SetStateAction` from the parent containing the Formik context.
 * This state value can then be passed in the validation schema being used by Formik to check the length based on the coutry mask selected.
 * The actual mask and its associated validation will be handled internally by this component.
 * @see {@link https://github.com/bl00mber/react-phone-input-2 React-Phone-Input-2 Documentation}
 */

export const InternationalPhoneInput = ({
  className,
  label,
  name = "phone",
  index = 0,
  includeErrorIcon = false,
  submitOnEnter = true,
  showErrorOnBlur = false,
  dataTestId = "international-phone-input",
  shouldShowError = true,
}: IInternationalPhoneInputProps) => {
  const { errors, handleBlur, handleSubmit, setFieldValue, values, touched } =
    useFormikContext<IJoinFormData>();

  const value = values[name];
  const error = errors[name];
  const isTouched = showErrorOnBlur ? !touched[name] : touched[name];

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const preferredCountry =
    values?.countryCode?.toLocaleUpperCase() || getCountryForTimezone(timezone)?.id || "US";

  const countryValue = preferredCountry?.toLocaleLowerCase();

  const [selectedCountry, setSelectedCountry] = useState(countryValue);
  const [inputText, setInputText] = useState(value);
  const [disableDropdown, setDisableDropdown] = useState(false);

  const input = document.querySelector(
    `.react-tel-input input.form-control-${index}`
  ) as HTMLInputElement | null;

  useEffect(() => {
    if (name === "phone" && !error?.phone && value?.length) {
      setInputText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Submits the form on Enter key press
   * Requires a handler because of type conflicts between
   * FormEvent<HTMLFormElement> & KeyboardEvent<HTMLInputElement>
   */
  const handleKeyDown = (e) => {
    setFieldValue(name, e.target.value);
    setFieldValue("countryCode", selectedCountry);
    if (submitOnEnter) {
      handleSubmit();
    }
  };

  const handleBlurEvent = (e) => {
    setFieldValue(name, e.target.value);
    setFieldValue("countryCode", selectedCountry);
    handleBlur(e);
  };

  const setDropdownStatus = (formattedNumber) => {
    const splitNumber = formattedNumber.split(" ");
    const countryNumberMatchesUSA = splitNumber[0] === "+1";
    const hasFinishedAreaCodeSection = splitNumber.length >= 3;

    // the phone input will guess the country flag once the next character is typed after area code
    // disable the country flag(country code) dropdown once we have guessed the flag by area code
    // prevents setting/saving another +1 number country code in the db when the phone number is a US phone number
    setDisableDropdown(countryNumberMatchesUSA && hasFinishedAreaCodeSection);
  };

  const handleOnChange = (number, country: CountryData, _e, formattedNumber) => {
    setDropdownStatus(formattedNumber);

    if (country?.countryCode) {
      setSelectedCountry(country?.countryCode.toUpperCase());
    }
    setInputText(number);

    /** This will focus the phone input once a country code selection is made
     * vs the true autoFocus built into the component, which is immediate
     */
    input?.focus();
  };

  const handleOnMount = (val, data: CountryData, formattedNumber) => {
    setDropdownStatus(formattedNumber);

    if (data?.countryCode) {
      setSelectedCountry(data?.countryCode.toUpperCase());
      setFieldValue("countryCode", data?.countryCode.toUpperCase());
    }
  };

  // list of country codes
  const twilioListedCountryCodes = twilioListedCountries.map((country) => country[0]);

  const handleValidation = () => {
    if (!isTouched) return true;
    return error === undefined;
  };

  const errorJSX = includeErrorIcon ? (
    <Styled.ValidationContent>
      <Icon name="hexagon-exclamation--solid" color="accentRed" size="sm" />
      <Typography variant="body/xs" color="accentRed">
        {error}
      </Typography>
    </Styled.ValidationContent>
  ) : (
    <Styled.ErrorText>{error}</Styled.ErrorText>
  );

  return (
    <Styled.InternationalPhoneInputWrapper className={className}>
      {!!label && <Styled.Label>{label}</Styled.Label>}
      <PhoneInput
        disableDropdown={disableDropdown}
        country={selectedCountry}
        countryCodeEditable={false}
        enableAreaCodeStretch
        inputProps={{
          style: { fontSize: "16px" },
          className: `form-control form-control-${index}`,
          name,
          "data-testid": dataTestId,
          "data-private": true,
        }}
        onlyCountries={twilioListedCountryCodes}
        isValid={handleValidation}
        onBlur={handleBlurEvent}
        onChange={handleOnChange}
        onMount={handleOnMount}
        onEnterKeyPress={handleKeyDown}
        preferredCountries={[selectedCountry]}
        value={inputText}
      />
      {!!shouldShowError && <Box marginTop={6}>{!!error && !!isTouched && errorJSX}</Box>}
    </Styled.InternationalPhoneInputWrapper>
  );
};
