import { Box, Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { TCarFeatureInfo } from "../../constants";
import { DirectBillIcon } from "../../Desktop/styles";

export interface ICarFeaturesListProps {
  features: TCarFeatureInfo[];
  isResults?: boolean;
  isTrips?: boolean;
}

const getTextVariant = (isResults: boolean, isTrips: boolean, isDesktop: boolean) => {
  if (isDesktop) return "body/sm";
  if (isTrips) return "body/sm";
  if (isResults) return "body/sm";
  return "body/xs";
};

export const VehicleDetails = ({ features, isResults, isTrips }: ICarFeaturesListProps) => {
  const isDesktop = useBreakpoint("xxl", "min");
  return (
    <>
      {features.map((feature, i) => {
        const iconName = feature.icon;
        const iconColor = feature.color;
        const label = feature.label;
        const isDirectBill = iconName === "directBill";

        return (
          <Styled.VehicleDetails
            $isResults={isResults}
            $isTrips={isTrips}
            $lessGap={isDirectBill}
            key={iconName + i.toString()}
            data-testid={`car-detail-${i}`}
          >
            <Box display="flex" alignItems="center">
              {isDirectBill ? (
                <DirectBillIcon>
                  <img src="/assets/creditcards/directbill.svg" alt="Direct bill icon" />
                </DirectBillIcon>
              ) : (
                <Icon
                  name={iconName}
                  size={isDesktop ? "sm" : "md"}
                  color={iconColor}
                  data-testid={`car-detail-${i}-icon`}
                />
              )}
            </Box>
            <Typography
              variant={getTextVariant(!!isResults, !!isTrips, isDesktop)}
              color={isDesktop ? "foregroundPrimary" : "foregroundSecondary"}
              data-testid={`car-detail-${i}-label`}
            >
              {label}
            </Typography>
          </Styled.VehicleDetails>
        );
      })}
    </>
  );
};
