export enum PaymentProfileErrorMessages {
  CARD_ATTACHED_TO_ACTIVE_TRIP = "Card attached to active trip",
}

export const PaymentProfileErrorMessagesMapping: Record<
  PaymentProfileErrorMessages,
  { title: string; description: string }
> = {
  [PaymentProfileErrorMessages.CARD_ATTACHED_TO_ACTIVE_TRIP]: {
    title: "Unable to delete card",
    description:
      "This payment method is tied to an active or future booking and cannot be deleted.",
  },
};
