import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { FeedbackModal } from "@hotel-engine/app/modals/FeedbackModal";
import Footer from "@hotel-engine/app/Footer";
import Header from "@hotel-engine/app/Header";
import { SkipLink } from "@hotel-engine/app/SkipLink";
import { useWindowSize } from "@hotel-engine/hooks";
import type { breakpoints } from "@hotel-engine/styles/variables/sizes";
import { sizes } from "@hotel-engine/styles/variables/sizes";

import * as Styled from "./styles";
import SupportCaseDrawer from "../SupportCaseDrawer";
import { useAppSelector } from "store/hooks";
import { GlobalBackgroundColorOverride } from "@hotel-engine/styles/GlobalStyles/global.style";

export interface IFeedbackModalData {
  shouldShow: boolean;
  /** Passive access is when a user clicks the button, active is when it randomly shows up on certain pages some of the time */
  accessMethod: "active" | "passive";
}

export interface IPageLayoutProps {
  /** components / elements for the page */
  children: ReactNode;
  /** Add a className to the root element */
  className?: string | undefined;
  /**
        Indicates if padding should be added. Defaults to true and should only be used in cases
        when the content goes to the edge of the screen like in the Property Results page.
    */
  hasPadding?: boolean;
  /** Allows page to specify a custom horizontal padding around content */
  customPadding?: string;
  /** Prevents footer from rendering. */
  noFooter?: boolean;
  /** Sets the layout to either span the width of the browser, or have a max width of 1200 or 768 pixels. */
  contentWidth?: keyof typeof breakpoints | "100%" | string;
  /** Value to be set in the document title */
  title?: string;
  /** Indicates that the page is a flights page */
  isFlights?: boolean;
  /* The header floats and requires the background color for the page to be on the whole body */
  bodyBackgroundColor: "backgroundPrimary" | "backgroundSecondary" | "accentBeigeLightest";
  /* Allows to remove background from header    */
  hasHeaderBackground?: boolean;
}

const PageLayout = ({
  children,
  className,
  hasPadding = true,
  customPadding,
  noFooter = false,
  title = "Save an average of 26% off public rates",
  contentWidth = "xxl",
  bodyBackgroundColor,
  hasHeaderBackground,
}: IPageLayoutProps) => {
  const { mobile, tablet } = useWindowSize();
  // TODO: probably dont need this state anymore follow up in WP-778
  const [isMobileMenuOpen, _] = useState<boolean>(false);
  const [feedbackModalOpen, toggleFeedbackModal] = useState(false);
  const supportCaseDrawer = useAppSelector((state) => state.SupportCaseDrawer);
  const caseId = supportCaseDrawer?.caseId;
  const showDrawer = supportCaseDrawer?.showDrawer;

  useEffect(() => {
    document.title = title;
  }, [title]);

  // the route changes renders the page at the previous routes scrolls position, so when this component mounts,
  // this useEffect forces it to be scrolled to the top
  useEffect(() => {
    globalThis.scrollTo(0, 0);
  }, []);

  const widthProperty = sizes.breakpoints[contentWidth] || contentWidth;

  return (
    <Styled.PageLayoutWrapper
      className={`${className} ${isMobileMenuOpen ? "menu-opened" : "menu-closed"}`}
      contentWidth={widthProperty}
      isOpen={!!isMobileMenuOpen && (mobile || tablet)}
    >
      <GlobalBackgroundColorOverride $backgroundColorTokenName={bodyBackgroundColor} />
      <SkipLink />
      <Header hasBackground={hasHeaderBackground} />
      <Styled.PageLayoutContent
        aria-label="Main Content"
        tabIndex={-1}
        contentWidth={widthProperty}
        noPadding={!hasPadding}
        customPadding={hasPadding ? customPadding : undefined}
        className={hasPadding ? "has-padding" : "has-no-padding"}
        id="site-content"
      >
        {children}
      </Styled.PageLayoutContent>
      {!noFooter && (
        <Footer className={className} openFeedbackModal={() => toggleFeedbackModal(true)} />
      )}
      {!!feedbackModalOpen && (
        <FeedbackModal accessMethod="passive" onClose={() => toggleFeedbackModal(false)} />
      )}
      <SupportCaseDrawer caseId={caseId} showDrawer={showDrawer} />
    </Styled.PageLayoutWrapper>
  );
};

export default PageLayout;
