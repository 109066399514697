import { useEffect, useState } from "react";

import { useSearchParams } from "@hotel-engine/hooks/useSearchParams";
import type { IImpersonateUserMutationResult } from "@hotel-engine/react-query/users/useImpersonateUserMutation";
import { useImpersonateUserMutation } from "@hotel-engine/react-query/users/useImpersonateUserMutation";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@hotelengine/atlas-web";
import { useAuth0 } from "@auth0/auth0-react";
import config from "config";

export const IMPERSONATE_SCOPE = "impersonate:user";

const getFullName = (user: IImpersonateUserMutationResult | null | undefined) => {
  if (!user) return;

  return `${user.firstName} ${user.lastName}`;
};

const ImpersonateUser = () => {
  const { loginWithRedirect } = useAuth0();

  const [impersonatedUser, setImpersonatedUser] = useState<IImpersonateUserMutationResult | null>();
  const { book_for_user_id: bookForUserId } = useSearchParams<{ book_for_user_id?: number }>();
  const { mutateAsync } = useImpersonateUserMutation();

  const handleImpersonate = async () => {
    if (impersonatedUser) {
      await loginWithRedirect({
        authorizationParams: {
          prompt: "login",
          connection: config.auth0ImpersonationConnection,
          redirect_uri: globalThis.location.origin,
          impersonatedUserId: bookForUserId,
          scope: IMPERSONATE_SCOPE,
        },
      });
    }

    // Since handleAuth0Callback will be called on redirect callback, it handles setting the user and
    // invalidating the queries, so we don't need to do anything here.
  };

  useEffect(() => {
    (async () => {
      setImpersonatedUser(bookForUserId ? await mutateAsync({ userId: bookForUserId }) : null);
    })();
  }, [bookForUserId, mutateAsync]);

  const onCancel = () => {
    setImpersonatedUser(null);
  };

  return (
    <Dialog isOpen={!!impersonatedUser}>
      <DialogContent>
        <DialogTitle>Impersonate User</DialogTitle>
        <Typography variant="body/md" as="p">
          Would you like to impersonate {getFullName(impersonatedUser)}?
        </Typography>
        <Box marginTop={16} display="flex" flexDirection="row" justifyContent="flex-end" gap={8}>
          <Button onClick={onCancel} variant="outlined">
            No
          </Button>
          <Button onClick={handleImpersonate}>Yes</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImpersonateUser;
