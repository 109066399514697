import type { IExpressBookCheckoutForm } from "@hotel-engine/types/expressBook";

import { ampli } from "ampli";
import { useFormikContext } from "formik";
import { PolicyItem } from "pages/Checkout/LegacyLodging/ImportantInformation/PolicyItem";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import { useFlexCutoffTime } from "@hotel-engine/hooks/useFlexCutoffTime";

interface IExpressBookCancelCopyProps {
  copyText: string;
}
export const ExpressBookCancelCopy = ({ copyText }: IExpressBookCancelCopyProps) => {
  const { values } = useFormikContext<IExpressBookCheckoutForm>();
  const { isFlexEnabled } = values;
  const user = useAppSelector((store) => store.Auth.user);
  const {
    copy: { flexOriginalPaymentCopy, flexTravelCreditCopy },
  } = useFlexCutoffTime();
  const refundToOriginalPayment = user?.business?.refundToOriginalFormOfPayment;

  return (
    <Styled.ExpressBookCancelCopyContainer
      onClick={() => {
        ampli.clickCancellationPolicyOnExpressBookModal();
      }}
    >
      {isFlexEnabled ? (
        <PolicyItem
          content={refundToOriginalPayment ? flexOriginalPaymentCopy : flexTravelCreditCopy}
          title="Flex Cancellation Notice"
          testId="flex-cancellation"
        />
      ) : (
        <PolicyItem content={copyText} title={"Property Cancellation Policy"} />
      )}
    </Styled.ExpressBookCancelCopyContainer>
  );
};
