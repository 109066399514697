import type { ReactNode } from "react";
import { useEffect } from "react";
import { useLocation } from "@hotel-engine/lib/react-router-dom";

import type { DrawerProps } from "antd/lib/drawer";

import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";
import { Dimensions } from "@hotel-engine/utilities";

import * as Styled from "./styles";

export interface IDrawer extends DrawerProps {
  children?: ReactNode;
  /** Applies styling to be used in conjunction with the Footer common component */
  hasFooter?: boolean;
  /** Applies styling to be used for children which should take up the whole drawer */
  fullscreen?: boolean;
  /** is there another sibling drawer to be aware of */
  hasSiblingDrawer?: boolean;
}

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Sheet` component (paired with it's `align` prop) from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/sheet-Nx6ztoq8 Atlas Web Sheet Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-sheet--docs Atlas Web Sheet Storybook}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-sheet--docs#using-the-sheet-as-a-side-sheet Atlas Web Side Sheet Example}
 *
 * @summary The `Drawer` component wraps the antd `<Drawer />` component.
 * It allows for the drawer children to be vertically scrollable content, along with an optional sticky header and sticky footer
 *
 * @see {@link DrawerFooter &lt;DrawerFooter /&gt;}
 * @see {@link https://3x.ant.design/components/drawer/ AntD Drawer Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=1904%3A9299 Design Specs}
 * */
const Drawer = ({ children, hasSiblingDrawer = false, ...drawerProps }: IDrawer) => {
  const { pathname } = useLocation();
  const hidenChatPaths = pathname.includes("/book") || pathname.includes("/directSelectSearch");
  const shouldHideChat = !(hidenChatPaths && Dimensions.mobile);
  useEffect(() => {
    // hide salesforce chat window if drawer is open
    if (drawerProps.visible) {
      toggleSalesForceChat(false);
    } else if (!hasSiblingDrawer && !drawerProps.visible) {
      // show salesforce chat bubble if drawer is closed and there is no sibling drawer
      toggleSalesForceChat(shouldHideChat);
    }

    return () => {
      if (!hasSiblingDrawer) {
        // reset salesforce chat bubble state if drawer is unmounted and there is no sibling drawer
        toggleSalesForceChat(shouldHideChat);
      }
    };
  }, [drawerProps, hasSiblingDrawer, shouldHideChat]);

  return (
    <Styled.Drawer
      data-testid={`ant-drawer--${drawerProps.visible ? "open" : "closed"}`}
      {...drawerProps}
    >
      {children}
    </Styled.Drawer>
  );
};

export default Drawer;
