/* eslint-disable react/display-name */
import { createElement } from "react";

import type { IconName, IconPrefix } from "@fortawesome/fontawesome-pro";
import type { AlertProps } from "antd/lib/alert";

import { Icon } from "@hotel-engine/common/Icon";

import * as Styled from "./styles";

type AlertType = "error" | "warning" | "success" | "info";

type VariantType = "system-outage";

type VerticalSpacing = "sm" | "md" | "lg";

export interface IAlertBanner extends AlertProps {
  /** The type of banner that is displayed `error | warning | success | info` */
  type?: AlertType;
  /** For conditional variant that may be used for a bit more "global" styles included in the common component styles.ts file itself */
  variant?: VariantType;
  /** When using a "custom icon" you sometimes need to specify the variant weight */
  iconWeightVariant?: IconPrefix;
  /** custom icon allows us to use fontawesome icons */
  customIcon?: IconName;
  /** Optional flag for System Outage Messages */
  outage?: boolean;
  /** Vertical spacing at the top for layout purposes in order to replace the className prop and individual implementation of margin top and margin bottom at various places with seemingly consistent patterns. Right now the scale is increments of 8 and values available are `8px | 16px | 32px`. NOTE: This should be removed and replaced with specific spacing at the implementation level within the relative styles.ts file once all parent pages/components no longer utilize SASS*/
  marginTop?: VerticalSpacing;
  /** Vertical spacing at the bottom for layout purposes in order to replace the className prop and individual implementation of margin top and margin bottom at various places with seemingly consistent patterns. Right now the scale is increments of 8 and values available are `8px | 16px | 32px`. NOTE: This should be removed and replaced with specific spacing at the implementation level within the relative styles.ts file once all parent pages/components no longer utilize SASS */
  marginBottom?: VerticalSpacing;
}

const iconConfig: { [key in AlertType]: IconName } = {
  error: "times-circle",
  warning: "exclamation-circle",
  success: "check-circle",
  info: "info-circle",
};

const getIcon = (iconWeight: IconPrefix, type: AlertType) => {
  return createElement(Icon, {
    icon: [iconWeight, iconConfig[type]],
  });
};

/**
 * The `AlertBanner` component wraps antd's `<Alert />` component and is used to show small inline alerts.
 * @deprecated This component utilizes AntD and should not be used. Please use either the `Alert` or `ExpandableAlert` components from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/alert-6WgQSbnc Atlas Web Alert Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-alert--docs Atlas Web Alert Storybook}
 */
export const LegacyAlertBanner = ({
  message,
  type = "error",
  variant,
  description,
  customIcon,
  outage,
  marginTop,
  marginBottom,
  iconWeightVariant,
  ...props
}: IAlertBanner) => {
  const iconWeight = () => {
    if (iconWeightVariant) {
      return iconWeightVariant;
    }
    if (outage && description) {
      return "fas";
    }
    if (description) {
      return "fal";
    }

    return "far";
  };

  const icon = customIcon ? (
    <Icon icon={[iconWeight(), customIcon]} data-testid={`${customIcon}-icon`} />
  ) : (
    getIcon(iconWeight(), type)
  );

  return (
    <Styled.AlertBanner
      type={type}
      variant={variant}
      showIcon
      icon={icon}
      message={message}
      description={description}
      marginTop={marginTop}
      marginBottom={marginBottom}
      iconWeightVariant={iconWeightVariant}
      {...props}
    />
  );
};

export default LegacyAlertBanner;
