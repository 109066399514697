import styled, { css } from "styled-components";
import { Link } from "@hotel-engine/lib/react-router-dom";

import { TopNav as AtlasTopNav } from "@hotelengine/atlas-web";

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.uiPrimary};
`;

type GlobalNavWrapperProps = { $globalNavBackgroundColorOverride?: string };

export const GlobalNavWrapper = styled.div<GlobalNavWrapperProps>`
${({ $globalNavBackgroundColorOverride, theme }) => {
  if (!!$globalNavBackgroundColorOverride) {
    // flex-pro treatment
    if ($globalNavBackgroundColorOverride.includes("linear-gradient")) {
      return css`
        background: ${$globalNavBackgroundColorOverride};
      `;
    }
    // rewards treatment
    return css`
      background-color: ${theme.colors[$globalNavBackgroundColorOverride]};
    `;
  }

  return css``;
}}

${({ theme }) => {
  // z-index needs to target the top nav, and be set by default so hovers work correctly.
  // eslint-disable-next-line custom-rules/themed-z-index
  return css`
    & > * {
      z-index: ${theme.zIndex.backdropAbove};
    }

    padding: ${theme.spacing[8]};

    // hardcoding this breakpoint to true-up the header with search box
    @media screen and (min-width: 1040px) {
      padding: ${theme.spacing[8]} ${theme.spacing[12]} ${theme.spacing[8]};
    }
  `;
}}
`;

export const TopNav = styled(AtlasTopNav)`
  justify-content: center;
`;
