import { Button, Typography } from "@hotelengine/atlas-web";

/**
 * This component returns the styled JSX for the error message that is passed in as a prop to the `antd` `message` function
 */
export const ErrorMessage = () => {
  return (
    <Typography variant="body/sm" color="accentGrayStrong">
      An error occurred, please{" "}
      <Button variant="link" size="sm" onClick={() => globalThis.location.reload()}>
        <Typography variant="body/sm" color="accentGrayStrong">
          reload the page
        </Typography>
      </Button>
    </Typography>
  );
};
