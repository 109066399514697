import { Timeline as AntDTimeline } from "antd";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Timeline` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/timeline-pbtR9ljl Atlas Web Timeline Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-timeline--docs Atlas Web Timeline Storybook}
 *
 * @summary The `TimeLine` component wraps antd's `<TimeLine />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal TimeLine component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/timeline/ AntD TimeLine Documentation}
 */
export const Timeline = AntDTimeline;
export type { TimelineProps, TimeLineItemProps } from "antd/lib/timeline";
