import { colors } from "@hotel-engine/styles";

// Zoom level used for Itinerary and Trip Results maps
export const SPECIFIC_PROPERTY_ZOOM = 16;
export const MIN_MAP_ZOOM = 4;
export const MAX_CLUSTER_ZOOM = 15;
export const MAX_MAP_ZOOM = 20;
export const DEFAULT_MAP_ZOOM = 13;

export const AREA_OVERLAP_TO_TRIGGER_AUTO_SEARCH = 0.75;

export const zIndexOrder = {
  previewPanel: 7,
  activeOrSpecific: 5,
  priorThanNormal: 4,
  normal: 3,
  hidden: 2,
};

export const initialMapOptions: google.maps.MapOptions = {
  center: { lat: 0, lng: 0 },
  clickableIcons: false,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  draggable: true,
  fullscreenControl: false,
  gestureHandling: "greedy",
  mapId: "f33d2db925698719",
  maxZoom: MAX_MAP_ZOOM,
  minZoom: MIN_MAP_ZOOM,
  streetViewControl: false,
  zoom: 13,
  zoomControl: false,
};

export const defaultPriceMarkerAttributes = {
  color: colors.grey[800],
  hoverColor: colors.retroBlue,
  icon: undefined,
  hidePrice: false,
  zIndex: zIndexOrder.normal,
};

export const defaultClusterOptions = {
  radius: 80,
  minZoom: MIN_MAP_ZOOM,
  maxZoom: MAX_CLUSTER_ZOOM,
  minPoints: 2,
};
