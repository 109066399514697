import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Chip` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/chip-UoNDsOXc Atlas Web Chip Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-chip--docs Atlas Web Chip Storybook}
 *
 * @summary The `Tag` component wraps antd's `<Tag />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Tag component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/tag/ AntD Tag Documentation}
 */
const Tag = Styled.Tag;

export default Tag;
