import type { ICurrency } from "../constants";
import type { GuestSelectionGuests, IOccupant, IReservationRoom } from "./booking";
import type { ICheckoutVerification } from "./notifications";
import type { IPaymentProfile, WexPromptsField } from "./paymentProfile";
import type { INightlyRate } from "./room";
import type { IValues } from "pages/ModifyReservation/types";

export type IReservationFilter = "earned" | "spent";

export interface IPaymentTypes {
  profileId: null | IPaymentProfile["id"];
  type: "travel_credits" | "points" | "direct_bill" | "card" | "postpay_customer_payment_method";
  amount: number;
  wexFleetCardCustomFields?: Partial<Record<WexPromptsField, string>>;
}
export interface IReservationCreatePayload {
  allowIncidentals?: boolean;
  affiliateTag: string | null;
  bookForUserId?: number;
  contractRateUuid: string;
  contractCustomFieldsAttributes: Array<{
    customFieldId: number;
    value?: string;
  }>;
  directBillCode?: string;
  duplicateOverride: boolean;
  isBillable: boolean;
  occupants: GuestSelectionGuests[];
  paymentTypes: IPaymentTypes[];
  userAgent: string;
  flexType?: "unassigned" | "modification" | "cancellation" | "total" | "flex_pro";
  flexEnabled: boolean;
  tripExtensionRequestId?: string;
}

export interface IReservationCustomField {
  id: number;
  contractId: number | string;
  customFieldId: number;
  name: string;
  value: string;
}

export interface IReservationOccupant {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
}

export interface IReservationResponse {
  /** the reservation contract number */
  id: string;
  /** the reservation status */
  status: string;
  /** the reservation status message */
  status_message?: string;
  /** indicates if message is the initial action cable message */
  initial_message?: boolean;
  errors?: string | string[];
  allowRetryRooms?: boolean;
  allowRetryBooking?: boolean;
  success?: "Contract Pending";
}
export type IReservationError = {
  statusCode?: number;
  status?: string;
} & Pick<IReservationResponse, "errors" | "allowRetryBooking" | "allowRetryRooms">;
export function isIReservationError(e: unknown): e is IReservationError {
  return "statusCode" in (e as IReservationError) || "errors" in (e as IReservationError);
}

export interface ICreateReservationError {
  errors?: string[];
  statusCode?: number;
}

export const isCreateReservationError = (
  error: IReservationResponse | ICreateReservationError
): error is ICreateReservationError => {
  return "statusCode" in error;
};

/**
 * @deprecated
 * use {@link IReservationBase} instead
 */
export interface IReservation {
  allowIncidentals: boolean;
  approvedBy?: number;
  approverId?: number;
  approvalStatus: string;
  bookedBy: string;
  bookingFee?: number;
  businessId: number;
  cancelBy?: string;
  cancellationPolicy?: string;
  checkInInstructions?: string;
  checkIn: string;
  checkOut: string;
  confirmationNumber: string;
  childFolioUrls: string;
  clientProgramName?: string;
  contractNumber?: string;
  convenienceFee?: number;
  creditCardPaymentTotal: number;
  customFields: IReservationCustomField[];
  dateBooked: string;
  department: string;
  directBillCode?: string;
  directBillPaymentTotal: number;
  earnedTravelCreditsAmount: string;
  employeeId: string | null;
  fees: number;
  flexCost: string;
  flexType?: "unassigned" | "modification" | "cancellation" | "total" | "flex_pro";
  flexEnabled: boolean;
  guestCount: number;
  guestEmail: string;
  guestFirstName: string;
  guestLastName: string;
  guestPhone: string;
  hotelChainName?: string;
  hotelCollect: boolean;
  hotelFeeAmount?: number;
  hotelFeeDescription?: string;
  id: string;
  incidentalsFee: number;
  isBillable: boolean;
  /** loyaltyEligible allows modifyRes to know if the reservation was loyalty eligible */
  loyaltyEligible?: boolean;
  mandatoryFeeAmount?: number;
  mandatoryFeesDescription?: string;
  nightCount: number;
  nightlyRate: number;
  nightlyRates?: INightlyRate[];
  occupants: IOccupant[][];
  primaryPaymentType: string;
  product?: string;
  propertyId: number;
  propertyName: string;
  propertyStreet: string;
  propertyCity: string;
  propertyConfirmationNumber?: string;
  propertyState: string;
  propertyPostalCode: string;
  propertyCountry: string;
  propertyPhone: string;
  propertyLatitude: number;
  propertyLongitude: number;
  propertyPhotoUrl: string;
  propertyTimezone: string;
  sameDayReservation: boolean;
  status: "booked" | "visiting" | "completed" | "cancelled";
  refundType: "Travel Credits" | "Original Form of Payment" | null;
  refundAmount: string | null;
  rewardsDollarPaymentTotal: number;
  rewardPoints: number;
  rewardPointsSpent: number;
  rewardsUserId: number;
  roomCount: number;
  roomDescription: string;
  subtotal: number;
  supplier?: string;
  taxes: number;
  total: number;
  travelCreditPaymentTotal: number;
  userId: number;
  pendingModification?: boolean;
  pendingModificationTypes?: string[];
  // new properties added from 1.1 redesigns
  bookingNumber?: string;
  departmentName?: string;
  traveler?: string;
  partner?: string;
  travelDate?: string;
  duration?: string;
  price?: string;
}

export interface IReservationFeeDetails {
  name: string;
  amount: number;
  feeType: "flat" | "nightly";
  taxable: boolean;
  subtotal: number;
  taxes: number;
  total: number;
  isHotelFee: boolean;
}

export interface IRefundBreakdown {
  action: string;
  clawedBackAmount?: string;
  clawedBackCurrency?: ICurrency;
  createdAt: string;
  creditCardAmount: string;
  directBillAmount: string;
  lastClawbackAt?: string;
  originalFormOfPayment: boolean;
  travelCreditsAmount: string;
  rewardPointsDollarAmount: string;
  totalRefund: string;
  travelCreditAmount: string;
}

interface IReservationCharges {
  bookingFee: string;
  convenienceFee: string;
  flexCost: string;
  flexCostPerRoom: string;
  incidentalsFee: string;
  memberSavingsTotal: string;
  paymentType: string;
  prepaidFees: string;
  redeemedRewardPointsDollarTotal: string;
  redeemedTravelCreditsTotal: string;
  roomCharges: string;
  roomSubtotal: string;
  subtotal: string;
  taxes: string;
  totalCustomerCharge: string;
}

// This is a cleaned type used for the
// reservation react-query hooks
export interface IReservationBase {
  activePropertyReviewRequestId: string | null;
  allowIncidentals: boolean;
  approvalStatus: string;
  approvedBy: number | null;
  approverId: number | null;
  bookedBy: string;
  dateBooked: string;
  bookingFee: number;
  businessId: number;
  cancelBy: string | null;
  cancellationPolicy: string;
  // The amount that you were refunded after cancelling
  cancellationRefundAmount: number;
  // The amount that you would get back if you cancelled right now
  cancellationRefundCalculatedAmount: number;
  charges: IReservationCharges;
  checkIn: string;
  checkInInstructions: string | null;
  checkInTime: string | null;
  checkOut: string;
  checkOutTime: string | null;
  checkoutVerification: ICheckoutVerification | null;
  clientProgramName: string | null;
  contractNumber: string;
  convenienceFee: number;
  createdAt: string;
  creditCardPaymentTotal: number;
  customFields: IReservationCustomField[];
  directBillCode: string | null;
  directBillPaymentTotal: number;
  duration?: string;
  earnedTravelCreditsAmount: string;
  employeeId: string | null;
  feeDetails?: IReservationFeeDetails[];
  fees: number;
  flexCost: string;
  flexEnabled: boolean;
  // The amount that you were refunded after shortening
  flexShorteningRefundAmount: number;
  flexType?: "unassigned" | "modification" | "cancellation" | "total" | "flex_pro";
  guestCount: number;
  guestEmail: string;
  guestFirstName: string;
  guestLastName: string;
  guestPhone: string;
  hotelChainName: string;
  hotelCollect: boolean;
  hotelFeeAmount: number;
  hotelFeeDescription: string;
  id: string;
  incidentalsFee: number;
  isBillable: boolean;
  isChildContract?: boolean;
  loyaltyEligible: boolean;
  mandatoryFeesDescription: string;
  memberSavingsTotal: number;
  modificationActions: IModificationActions;
  modifications: IModifications;
  newFormatSingleContract?: boolean;
  nightCount: number;
  nightlyRate: number;
  nightlyRates: INightlyRate[];
  occupants: IOccupant[][];
  originalCheckIn: string;
  originalCheckOut: string;
  pendingModification: boolean;
  pendingModificationTypes: string[];
  prepaidFees: number;
  primaryPaymentType: string;
  product: string | null;
  propertyCity: string;
  propertyConfirmationNumber: string;
  propertyCountry: string;
  propertyId: number;
  propertyLatitude: number;
  propertyLongitude: number;
  propertyName: string;
  propertyPhone: string;
  propertyPhotoUrl: string;
  propertyPostalCode: string;
  propertyState: string;
  propertyStreet: string;
  propertyTimezone: string;
  refundAmount: number | null;
  refundBreakdowns: IRefundBreakdown[];
  refundType: string | null;
  rewardPoints: number;
  rewardsDollarPaymentTotal: number;
  rewardsUserId: number;
  rooms: IReservationRoom[];
  roomCharges: number;
  roomCount: number;
  roomDescription: string;
  roomTitle: string;
  sameDayReservation: boolean;
  status: string;
  subtotal: number;
  supplier: string;
  taxes: number;
  total: number;
  travelCompAmount: number;
  travelCompDescription: string | null;
  travelCreditPaymentTotal: number;
  tripExtensions: ITripExtensions;
  userId: number;
}

type OrderableColumns =
  | "check_in"
  | "check_out"
  | "created_at"
  | "guest_last"
  | "properties_city"
  | "properties_name"
  | "total";
type SortOrder = "asc" | "desc";

export type IReservationQueryParams = Partial<
  Pick<IReservationBase, "id" | "status" | "total" | "rewardsUserId"> & {
    limit?: number;
    offset?: number;
    checkInGt: IReservationBase["checkIn"];
    checkInLt: IReservationBase["checkIn"];
    contractType: "contract" | "multi_room";
    createdAtGt: Date | string;
    createdAtLt: Date | string;
    onlyMyTrips: boolean;
    pendingModificationOnly: boolean;
    rewardsType: "earned" | "spent";
    sort: `${OrderableColumns}-${SortOrder}` | `-${OrderableColumns}`;
    totalGt: IReservationBase["total"];
    totalLt: IReservationBase["total"];
    search: string[];
    "status[]"?: string[];
  }
>;
export function isReservationV2(
  reservationParams: IReservationQueryParams | Pick<IReservationBase, "id">
): reservationParams is Pick<IReservationBase, "id"> {
  if (!reservationParams?.id) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...everythingElse } = reservationParams;
  return Object.keys(everythingElse).length === 0;
}

export interface ITripsSummary {
  guest_first: string;
  guest_last: string;
  property_city: string;
  property_state: string;
  property_country: string;
  completed_nights: number;
  total_nights: number;
}

export interface ITripExtensions {
  personalTravel: boolean;
  previousContractNumber: string | null;
  subsequentBookings: string[];
  subsequentContractNumber: string | null;
}

export interface IModificationActions {
  cancellable: boolean;
  extendable: boolean;
  flexShortenable: boolean;
  modifiable: boolean;
  shortenable: boolean;
}

export interface IModifications {
  all: {
    ["cancel request"]?: number;
    ["date change"]?: number;
    ["cancellation request"]?: number;
    ["reservation name change"]?: number;
    ["reservation request"]?: number;
    ["special request"]?: number;
  };
}

export interface ISpecialRequestPropValues {
  checked: boolean;
  value: string | null;
}

export interface ISpecialRequest {
  earlyCheckIn: ISpecialRequestPropValues;
  adjacentRooms: ISpecialRequestPropValues;
  rollawayBed: ISpecialRequestPropValues;
  floorRequest: ISpecialRequestPropValues;
  other: ISpecialRequestPropValues;
}

export interface ISpecialRequestProps {
  setSpecialRequest: (specialRequest: ISpecialRequest, roomPosition?: number) => void;
  formValues: IValues;
  resetValue: (value: keyof IValues) => void;
}

export interface IModificationStatus {
  isError?: boolean;
  isLoading?: boolean;
  isSubmitted?: boolean;
  isUpdated?: boolean;
  isModalVisible?: boolean;
}

export interface ICalendarContract {
  id: string;
  status: string;
  checkIn: string;
  checkOut: string;
  propertyName: string;
  groupContractId: string | null;
}

export interface ICalendarTraveler {
  department: string | null;
  employeeId: string | null;
  firstName: string;
  lastName: string;
  contracts: ICalendarContract[];
}
