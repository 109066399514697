import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Popover` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/popover-tFDYAfVO-tFDYAfVO Atlas Web Popover Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-popover--docs Atlas Web Popover Storybook}
 *
 * @summary The `Popover` component wraps antd's `<Popover />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Popover component does not have any of its own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/popover/ AntD Popover Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=4387%3A34332 Design Specs}
 */
export const Popover = Styled.Popover;
