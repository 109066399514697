import { Modal as antdModal } from "antd";
import type { ModalFuncProps as antdModalFuncProps } from "antd/lib/modal";

import * as Styled from "./styles";
import { GlobalModalStyles } from "./styles";

export type ModalFuncProps = antdModalFuncProps;

const returnModalStyles = (obj: ModalFuncProps) => {
  return {
    ...obj,
    title: (
      <>
        {obj.title}
        <GlobalModalStyles />
      </>
    ),
  };
};

const functionalModals = {
  confirm: (obj: ModalFuncProps) => antdModal.confirm(returnModalStyles(obj)),
  destroyAll: () => antdModal.destroyAll(),
  error: (obj: ModalFuncProps) => antdModal.error(returnModalStyles(obj)),
  info: (obj: ModalFuncProps) => antdModal.info(returnModalStyles(obj)),
  success: (obj: ModalFuncProps) => antdModal.success(returnModalStyles(obj)),
  warn: (obj: ModalFuncProps) => antdModal.warn(returnModalStyles(obj)),
  warning: (obj: ModalFuncProps) => antdModal.warning(returnModalStyles(obj)),
};

const ModalComponent = Styled.Modal;

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Sheet` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/sheet-Nx6ztoq8 Atlas Web Sheet Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-sheet--docs Atlas Web Sheet Storybook}
 */
export const Modal = Object.assign(ModalComponent, functionalModals);
