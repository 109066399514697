import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import { useSearchParams } from "./useSearchParams";
import { useTripsLegacyContext } from "pages/Trips/contextLegacy";
import { useMemo } from "react";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { getBusinessToken } from "@hotel-engine/utilities/auth";
import { useParams } from "@hotel-engine/lib/react-router-dom";
import { isGroupContract } from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";

/**
 * Utility hook to help determine details about a contract and its extendability. Will look at current url or react-router params to pull reservation information and return a memoized object of helpful properties.
 * @returns Memoized object of properties centered around the extendability of the current contract and its rooms: isContractExtendable, extendableRoomCount, showMultiDateTripExtensions, canExtendAllRoomsTogether
 */
const useIsContractExtendable = () => {
  // Grab the contract data from search params to use in our reservation query
  const { contract_number, extendedContractId } = useSearchParams<{
    contract_number: string;
    extendedContractId: string;
  }>();

  // Grabs preview id from the trips context, in case we're viewing from here
  const previewPanelId = useTripsLegacyContext()?.state?.showPreview?.id;
  // Grabs any reservation ids from react-router params
  const { reservationId: modifyResId } = useParams<{ reservationId: string }>();
  // The current user
  const user = useAppSelector((state) => state.Auth.user);

  // the id that is used to determine the reservation. Can be any of the following properties
  const id = contract_number || previewPanelId || extendedContractId || modifyResId;

  const altToken = user?.accountType === "personal" && getBusinessToken();

  // Get the reservation data
  const { data: reservation } = useReservationQuery(
    {
      id: String(id),
    },
    undefined,
    altToken
  );

  // Feature flags
  const allowMultiRoomTripExtensions = useIsFeatureFlagOn("multi_room_trip_extensions");
  const allowMultiDateTripExtensions = useIsFeatureFlagOn("sste_multi_date");

  return useMemo(() => {
    // What rooms are extendable
    const extendableRooms = reservation?.rooms?.filter(
      (room) => room.modificationActions.extendable
    );

    // Determines whether this is a group contract
    const isReservationGroupContract =
      (!!reservation && isGroupContract(reservation)) ||
      Boolean(reservation && reservation?.rooms?.length > 1);

    // What is the total number of guests from extendable rooms
    const extendableRoomsGuestCount =
      extendableRooms?.length &&
      extendableRooms?.map((room) => room.occupants.length).reduce((a, b) => a + b);

    // determines if all rooms in a contract are extendable
    const canExtendAllRoomsTogether =
      extendableRooms?.every((room) => room.checkOut === extendableRooms[0].checkOut) ?? false;

    // determines if this contract is considered extendable
    const isContractExtendable =
      (reservation?.modificationActions?.extendable &&
        // extending a group contract is gated behind feature flag
        (reservation.roomCount < 2 || allowMultiRoomTripExtensions) &&
        // user can only extend all rooms unless they're behind a feature flag
        (canExtendAllRoomsTogether || allowMultiDateTripExtensions) &&
        user?.accountType === "business") ??
      false;

    // returns the number of extendable rooms in a contract
    const extendableRoomCount = extendableRooms?.length || 0;

    // determines whether we allow multi-room trip extensions to select different dates
    const showMultiDateTripExtensions = Boolean(
      extendableRoomCount > 1 &&
        isReservationGroupContract &&
        allowMultiDateTripExtensions &&
        allowMultiRoomTripExtensions
    );

    return {
      isContractExtendable,
      extendableRooms,
      extendableRoomCount,
      isGroupContract: isReservationGroupContract,
      extendableRoomsGuestCount,
      showMultiDateTripExtensions,
      canExtendAllRoomsTogether,
    };
  }, [reservation, user, allowMultiRoomTripExtensions, allowMultiDateTripExtensions]);
};

export default useIsContractExtendable;
