import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  ISearchMapCenterPin,
  ISearchMapClusterPin,
  ISearchMapClustingProperties,
  ISearchMapControls,
  ISearchMapCustomControls,
  ISearchMapDatasetProperties,
  ISearchMapFitToBoundsProperties,
  ISearchMapOptions,
  ISearchMapPin,
  ISearchMapPricePin,
  ISearchMapProperties,
} from "./types";
import { defaultClusteringProperties, defaultSearchMapOptions } from "./constants";

export const updateSearchMapDatasetProperties = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapDatasetProperties>>
) => {
  return {
    ...state,
    dataset: {
      ...state.dataset,
      ...action.payload,
    },
  };
};

export const updateSearchMapClusteringProperties = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapClustingProperties>>
) => {
  return {
    ...state,
    clustering: {
      ...state.clustering,
      ...action.payload,
    },
  };
};

export const updateSearchMapOptions = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapOptions>>
) => {
  const mapMinZoom = action.payload.minZoom ?? defaultSearchMapOptions.minZoom;
  const mapMaxZoom = action.payload.maxZoom ?? defaultSearchMapOptions.maxZoom;
  const clusteringMinZoom = state.clustering.minZoom ?? defaultClusteringProperties.minZoom;
  const clusteringMaxZoom = state.clustering.maxZoom ?? defaultClusteringProperties.maxZoom;

  if (clusteringMinZoom < mapMinZoom) {
    state.clustering.minZoom = mapMinZoom;
  }

  if (clusteringMaxZoom > mapMaxZoom) {
    state.clustering.maxZoom = mapMaxZoom;
  }

  state.options = {
    ...state.options,
    ...action.payload,
  };
};

export const updateSearchMapControls = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapControls>>
) => {
  return {
    ...state,
    controls: {
      ...state.controls,
      ...action.payload,
    },
  };
};

export const updateSearchMapCustomControls = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapCustomControls>>
) => {
  return {
    ...state,
    customControls: {
      ...state.customControls,
      ...action.payload,
    },
  };
};

export const updateSearchMapFitToBoundsProperties = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapFitToBoundsProperties>>
) => {
  return {
    ...state,
    fitToBounds: {
      ...state.fitToBounds,
      ...action.payload,
    },
  };
};

export const updateSearchMapPinAnchor = (
  state: ISearchMapProperties,
  action: PayloadAction<{
    pin: keyof ISearchMapProperties["pins"];
    anchor: ISearchMapPin["anchor"];
  }>
) => {
  state.pins[action.payload.pin].anchor = action.payload.anchor;
};

export const updateSearchMapPricePinVariants = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapPricePin["variants"]>>
) => {
  state.pins.pricePin.variants = {
    ...state.pins.pricePin.variants,
    ...action.payload,
  };
};

export const updateSearchMapClusterPinVariants = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapClusterPin["variants"]>>
) => {
  state.pins.clusterPin.variants = {
    ...state.pins.clusterPin.variants,
    ...action.payload,
  };
};

export const toggleSearchMapClusterPinAddPointCountToZIndex = (state: ISearchMapProperties) => {
  state.pins.clusterPin.addPointCountToZIndex = !state.pins.clusterPin.addPointCountToZIndex;
};

export const updateSearchMapCenterPinVariants = (
  state: ISearchMapProperties,
  action: PayloadAction<Partial<ISearchMapCenterPin["variants"]>>
) => {
  state.pins.centerPin.variants = {
    ...state.pins.centerPin.variants,
    ...action.payload,
  };
};
