import config from "config";
import {
  type ISearchMapPin,
  type ISearchMapFitToBoundsProperties,
  type ISearchMapProperties,
} from "./types";

export const defaultClusteringProperties = {
  minZoom: 4,
  maxZoom: 15,
  minPoints: 5,
  radius: 85,
  extent: 512,
  nodeSize: 64,
  log: false,
  generateId: false,
};

export const defaultSearchMapOptions = {
  mapId: config.googleMapId,
  minZoom: 4,
  maxZoom: 20,
  zoom: 13,
  clickableIcons: false,
};

export const defaultSearchMapControls = {
  controlSize: 40,
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};

export const defaultSearchMapCustomControls = {
  customSearchThisAreaControl: true,
  customMapTypeControl: true,
  customZoomControl: true,
  customExpandControl: true,
};

export const defaultSearchMapFitToBoundsProperties = {
  enabled: true,
  mode: "nth_results" as ISearchMapFitToBoundsProperties["mode"],
  nthResults: 60,
  center: "dataset_center" as ISearchMapFitToBoundsProperties["center"],
  padding: { top: 80, right: 40, bottom: 80, left: 40 },
};

export const defaultSearchMapPinProperties = {
  pricePin: {
    anchor: "center" as ISearchMapPin["anchor"],
    variants: {
      hidden: {
        zIndex: 1,
      },
      base: {
        zIndex: 8,
      },
      loyalty: {
        zIndex: 10,
      },
      exceedsGsa: {
        zIndex: 5,
      },
      favorited: {
        zIndex: 15,
      },
      preferred: {
        zIndex: 20,
      },
      outOfPolicy: {
        zIndex: 2,
      },
    },
  },
  clusterPin: {
    anchor: "center" as ISearchMapPin["anchor"],
    addPointCountToZIndex: false,
    variants: {
      base: {
        zIndex: 10,
      },
      favorited: {
        zIndex: 20,
      },
      preferred: {
        zIndex: 25,
      },
      favoritedAndPreferred: {
        zIndex: 30,
      },
    },
  },
  centerPin: {
    anchor: "bottom-center" as ISearchMapPin["anchor"],
    variants: {
      base: {
        zIndex: 1,
      },
    },
  },
};

export const initialState: ISearchMapProperties = {
  dataset: {
    showBoundingBox: false,
    showRequestedRadius: false,
    showEffectiveRadius: false,
    showDatasetCenter: false,
    showPinAnchorOrigin: false,
    showClusterRadius: false,
  },
  clustering: {
    ...defaultClusteringProperties,
  },
  options: {
    ...defaultSearchMapOptions,
  },
  controls: {
    ...defaultSearchMapControls,
  },
  customControls: {
    ...defaultSearchMapCustomControls,
  },
  fitToBounds: {
    ...defaultSearchMapFitToBoundsProperties,
  },
  pins: {
    ...defaultSearchMapPinProperties,
  },
};
