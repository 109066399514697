import { SortOptions } from "store/Search/constants";
import type { CountableFilter, ISearchFiltersState } from "./types";

const BASIC_FILTER_INITIAL_STATE = {
  selected: false,
  count: 0,
};

export const initialState: ISearchFiltersState = {
  facility: {
    ecoFriendly: BASIC_FILTER_INITIAL_STATE,
    dining: BASIC_FILTER_INITIAL_STATE,
    fitnessCenter: BASIC_FILTER_INITIAL_STATE,
    meetingSpace: BASIC_FILTER_INITIAL_STATE,
    petFriendly: BASIC_FILTER_INITIAL_STATE,
    swimmingPool: BASIC_FILTER_INITIAL_STATE,
    selfServiceLaundry: BASIC_FILTER_INITIAL_STATE,
    truckParking: BASIC_FILTER_INITIAL_STATE,
  },
  loyaltyPrograms: {
    all: BASIC_FILTER_INITIAL_STATE,
  },
  popular: {
    clientProgram: BASIC_FILTER_INITIAL_STATE,
    doubleRewards: BASIC_FILTER_INITIAL_STATE,
    breakfast: BASIC_FILTER_INITIAL_STATE,
    parking: BASIC_FILTER_INITIAL_STATE,
    wifi: BASIC_FILTER_INITIAL_STATE,
    gsaOrLower: BASIC_FILTER_INITIAL_STATE,
    incidentalCoverage: BASIC_FILTER_INITIAL_STATE,
    refundable: BASIC_FILTER_INITIAL_STATE,
  },
  priceRange: {
    ceil: 1000,
    floor: 0,
    userMax: null,
    userMin: null,
  },
  propertyNames: {},
  stayTypes: {
    all: BASIC_FILTER_INITIAL_STATE,
    hotelsAndMore: BASIC_FILTER_INITIAL_STATE,
    apartmentsAndMore: BASIC_FILTER_INITIAL_STATE,
  },
  propertyTypes: {
    alternativeStays: {
      ...BASIC_FILTER_INITIAL_STATE,
      available: false,
    },
    apartments: {
      ...BASIC_FILTER_INITIAL_STATE,
      available: false,
    },
    vacationHomes: {
      ...BASIC_FILTER_INITIAL_STATE,
      available: false,
    },
  },
  room: {
    airConditioning: BASIC_FILTER_INITIAL_STATE,
    coffeeMaker: BASIC_FILTER_INITIAL_STATE,
    internet: BASIC_FILTER_INITIAL_STATE,
    kitchen: BASIC_FILTER_INITIAL_STATE,
    refrigerator: BASIC_FILTER_INITIAL_STATE,
  },
  orderedSelectedFilters: [],
  selectedSort: SortOptions.Recommended,
  services: {
    dryCleaning: BASIC_FILTER_INITIAL_STATE,
    shuttle: BASIC_FILTER_INITIAL_STATE,
    spa: BASIC_FILTER_INITIAL_STATE,
    valetParking: BASIC_FILTER_INITIAL_STATE,
  },
  starRating: {
    1: BASIC_FILTER_INITIAL_STATE,
    2: BASIC_FILTER_INITIAL_STATE,
    3: BASIC_FILTER_INITIAL_STATE,
    4: BASIC_FILTER_INITIAL_STATE,
    5: BASIC_FILTER_INITIAL_STATE,
  },
  suppliers: {},
};

export const SELECTABLE_FILTER_TYPES = [
  "facility",
  "popular",
  "room",
  "services",
  "suppliers",
  "starRating",
  "stayTypes",
  "propertyTypes",
  "loyaltyPrograms",
  "propertyNames",
] as const;

export const COUNTABLE_FILTER_TYPES = [
  "facility",
  "popular",
  "room",
  "services",
  "suppliers",
  "stayTypes",
  "propertyTypes",
  "loyaltyPrograms",
] as const;

/**
 * These filters do not update their counts when a filter within their group is selected,
 * so a user can select multiple options within the filter group and the counts will not change.
 */
export const FILTER_TYPES_THAT_KEEP_COUNTS: CountableFilter[] = [
  "loyaltyPrograms",
  "propertyTypes",
  "suppliers",
  "stayTypes",
] as const;
