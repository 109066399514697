import type { SkeletonProps } from "antd/lib/skeleton";

import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Skeleton` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/skeleton-QzBVNFeZ Atlas Web Skeleton Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-skeleton--docs Atlas Web Skeleton Storybook}
 *
 * @summary The `skeleton` component wraps antd's `<skeleton />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal skeleton component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/skeleton/ AntD Skeleton Documentation}
 */
export const Skeleton = Styled.Skeleton;

export type ISkeleton = SkeletonProps;
