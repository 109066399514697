import { useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import {
  Button,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@hotelengine/atlas-web";

import config from "config";
import { eventChallengePresent, eventChallengeSolved } from "./client";

let interval;
const waitForTabClose = (tab) => {
  return new Promise((resolve) => {
    clearTimeout(interval);
    interval = setInterval(() => {
      if (tab?.closed) {
        clearTimeout(interval);
        resolve("complete");
      }
    }, 100);
  });
};

const CloudFlareChallenge = () => {
  const [showChallenge, setShowChallenge] = useState(false);

  const onOk = async () => {
    // open new tab
    const tab = globalThis.open(config.heApi + "/cloudflare.html?t=" + new Date().valueOf());
    await waitForTabClose(tab).then(() => {
      console.log("CloudFlareChallenge: Challenge Solved");
      const event = new CustomEvent(eventChallengeSolved);
      globalThis.dispatchEvent(event);
      setShowChallenge(false);
    });
  };

  useEffect(() => {
    if (!showChallenge) {
      console.log("CloudFlareChallenge: Listening for challenge");
      const callback = () => {
        setShowChallenge(true);
      };
      globalThis.addEventListener(eventChallengePresent, callback);
      return () => globalThis.removeEventListener(eventChallengePresent, callback);
    } else {
      // Log this event to datadog
      datadogRum.addAction("cloudflareChallenge");
      return () => {
        // noop
      };
    }
  }, [showChallenge]);

  return showChallenge ? (
    <Sheet isOpen={showChallenge}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Security Check</SheetTitle>
        </SheetHeader>
        <Typography variant="body/sm" as="p" marginTop={24} marginBottom={48}>
          Safety first! Please complete the security check to continue using the app.
        </Typography>
        <Button color="accent" size="md" onClick={() => onOk()} style={{ marginLeft: "auto" }}>
          OK
        </Button>
      </SheetContent>
    </Sheet>
  ) : null;
};

export default CloudFlareChallenge;
