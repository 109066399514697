import { memo } from "react";

import { Icon } from "antd";
import type { SpinProps } from "antd/lib/spin";

import Spin from "@hotel-engine/common/Spin";

import * as Styled from "./styles";

export interface ILoader extends SpinProps {
  /** Enabled potential for implementation CSS overrides */
  className?: string;
  /** Colors that are configured via CSS classes 'white' | 'grey' */
  color?: "white" | "grey";
  /** Layouts and appearances that are configured via css classes 'inline' | 'stacked' | 'pill' (default: 'inline')
   *  Inline - To be used while in association with other elements like having it next to a piece of text or next to a button or in flow of a paragraph
   *  and should fit into the DOM without disruption of the flow or DOM position of other nodes. This reduces the side effects that could occur when you
   *  have flexbox children, siblings and parents. Note: This is specified and set as the default should in the future, we need to add other types to this
   *  element that would effect the layout such as creating a full width (block) technique or flexbox.
   *  Stacked - This adjusts the layout of the children element should both the loading icon/indicator and text exists, it stacks them on top of each other vertically.
   *  Pill - This resembles the same layout as the inline but also adds rounded corners and a box shadow to the appearance.
   */
  type?: "inline" | "stacked" | "pill";
  /** NOTE: Sizes are controlled by and passed through via extending SpinProps so its not explicitly declared here. You should still assign your property and its value of `size="large"` to the Loader component itself and the CSS classes will take care of the rest. You're options are 'small' | 'default' | 'large'.  'default' is the medium sized loader component. Example: `<Loader size="default" />` */
  /** Note: The `tip` is also extended by SpinProps and enabled you to include text that is associated with the `Spin` child component. It can be directly applied to the Loader component itself. Example: `<Loader tip"Loading Text" />` */
  size?: "small" | "default" | "large";
}

const icon = <Icon role="alert" type="loading" spin />;

const Loader = ({
  size = "small",
  color = "grey",
  type = "inline",
  className,
  ...props
}: ILoader) => {
  return (
    <Styled.Loader size={size} color={color} type={type} className={className}>
      <Spin indicator={icon} {...props} />
    </Styled.Loader>
  );
};

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Icon` component from `@hotelengine/atlas-web` paired with the component's `animationStyle` prop.
 * @see {@link https://atlas.he-dev.com/latest/components/icon-eeqv1LXW Atlas Web Icon Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-icon--docs Atlas Web icon Storybook}
 *
 * @summary The `Loader` component is used to display the process in which a change of state takes place where any type of delay in time may occur before the final results are displayed.
 *
 * NOTE: Memoization is being used to account for when a loader is being rendered,
 * often changes occur to the parent so this should prevent numerous unnecessary rerenders
 *
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=3277%3A145 Design Specs}
 */
export default memo(Loader);
