import styled from "styled-components";
import { Box, Icon, type BoxProps } from "@hotelengine/atlas-web";

export const Dismiss = styled<React.FC<BoxProps>>(Box)`
  min-width: 60px;
  min-height: 60px;
  background-color: #CECDD5;
`;

export const Prompt = styled<React.FC<BoxProps>>(Box)`
  height: 60px;
  // matching iOS banner...
  background-color: #E3E1EA;
  width: 100%;
  z-index: 301 /* disable theming (do not replicate) */;
`;

export const Info = styled<React.FC<BoxProps>>(Box)`
  margin: 10px;
  border-radius: 15px /* disable theming (do not replicate) */;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 100%;
  max-height: 100%;
`;

export const DismissIcon = styled(Icon)`
  height: 24px;
  width: 26px;
  margin: 0 10px;
`;

export const HEImage = styled.img`
  border-radius: 10px /* disable theming (do not replicate) */;
  height: 40px;
`;

export const StoreText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const InfoRight = styled<React.FC<BoxProps>>(Box)`
  width: 100%;
  height: 100%;
  margin-left: 15px;
`;

export const ImageContainer = styled<React.FC<BoxProps>>(Box)`
  height: 100%;
`;
