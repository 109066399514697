import { Collapse as AntdCollapse } from "antd";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Accordion` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/accordion-DiRgjCiB Atlas Web Accordion Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-accordion--docs Atlas Web Accordion Storybook}
 *
 * @summary The `Collapse` component wraps antd's `<Collapse />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Collapse component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/collapse/ AntD Collapse Documentation}
 */
export const Collapse = AntdCollapse;
export const CollapsePanel = AntdCollapse.Panel;

export type { Collapse as TCollapse } from "antd";

export type { CollapseProps } from "antd/lib/collapse";
