import type { CarRentalVendorAirportAccessType } from "@hotel-engine/types/cars/cars.shared";
import { useTranslation } from "react-i18next";

export const useAirportAccess = () => {
  const { t } = useTranslation("cars");

  return {
    getAirportAccess: (
      airportAccessCode?: CarRentalVendorAirportAccessType
    ): string | undefined => {
      const translationKey = `airportAccess.${airportAccessCode}`;
      const hasTranslation = t(translationKey) !== translationKey;

      return hasTranslation ? t(translationKey) : undefined;
    },
  };
};
