export enum SortOptions {
  Recommended = "RECOMMENDED",
  BestValue = "BEST_VALUE",
  DistanceAsc = "DISTANCE_ASC",
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
  StarRatingDesc = "STAR_RATING_DESC",
  StarRatingAsc = "STAR_RATING_ASC",
  GuestRatingDesc = "GUEST_RATING_DESC",
}
