import { useEffect, useRef } from "react";
import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ITripsResult } from "@hotel-engine/types/trips";
import { captureMessage } from "@hotel-engine/utilities/logger";

import type { TripsFilterStatus } from "pages/Trips/contextLegacy";

import { useApi } from "../useApi";
import type { BookingType } from "../../types/itinerary";

type IParams = {
  limit: number;
  offset: number;
  sort: string;
  "filter[group]": TripsFilterStatus;
  "filter[timezone]": string | null;
  "filter[onlyMyTrips]"?: boolean;
  "filter[onlyCancelledTrips]"?: boolean;
  "filter[bookingType]"?: BookingType;
};

const REQUEST_CANCEL_REASON = "Members: Request aborted by user.";

export const useTripsQuery = ({
  params,
  options,
}: {
  params: IParams;
  options?: UseQueryOptions<ITripsResult, IErrorResponse>;
}) => {
  const controllerRef = useRef<AbortController>();

  const abort = () => {
    // Abort previous search request if it is still pending. Calling .abort() on
    // an already ended or cancelled request is a noop and will not cause an issue
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    controllerRef.current?.abort(REQUEST_CANCEL_REASON);
  };

  // Abort the request on unmount
  useEffect(() => abort, []);

  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.trips, params],
    async () => {
      // Abort existing request
      abort();

      controllerRef.current = new AbortController();

      const data = await get(endpoints.trips, params, {
        signal: controllerRef.current.signal,
      });

      return data as ITripsResult;
    },
    {
      ...options,
      onError: (error) => {
        captureMessage("Could not load trips", { error });
      },
    }
  );
};
