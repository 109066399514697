import { useEffect, useLayoutEffect, useState } from "react";

import type { ICardUpdateValues } from "@hotel-engine/app/EditPaymentProfileForm/components/CardInfo";
import { FormItem } from "@hotel-engine/common/Form";
import { useCurrencyCode } from "@hotel-engine/hooks/useCurrencyCode";
import type { CreditDebitCardPaymentProfile } from "@hotel-engine/types/paymentProfile";
import { ampli } from "ampli";
import config from "config";

import { billingCodeCopy, formatExpirationDisplay } from "../helpers";
import * as Styled from "./styles";

export interface ISpreedlyCardInputProps {
  payment: CreditDebitCardPaymentProfile | null | undefined;
  onCardUpdate: (updatedValues: ICardUpdateValues) => void;
  setAmpliValues?: (updatedValues: ICardUpdateValues) => void;
  amplitudeSubmissionObj?: {
    propertyId: number;
    searchId: number;
    roomRateId: number;
  };
  isAutopayModal?: boolean;
  isRenderedInModal?: boolean;
}

export interface ICardInputs {
  /** boolean if card number is valid or not */
  cardNumberInvalid?: boolean;
  /** type of card ex. visa */
  cardType?: spreedly.SpreedlyCardType | "amex" | "mastercard" | null;
  /** boolean if expiration cvv is valid or not */
  cvvInvalid?: boolean;
  /** boolean if expiration card date is valid or not */
  expirationDateInvalid?: boolean;
  /** expiration card date MM/YY */
  expirationDisplay?: string;
  /** boolean if card data should be disabled not */
  disabled?: boolean;
  /** whether it is rendered in a modal or not. If so other styling applies */
  $isRenderedInModal?: boolean;
}

export const SpreedlyCardInput = ({
  payment,
  onCardUpdate,
  setAmpliValues,
  amplitudeSubmissionObj,
  isRenderedInModal,
  isAutopayModal,
}: ISpreedlyCardInputProps) => {
  const currencyCode = useCurrencyCode();
  const { Spreedly } = globalThis;
  const [cardInputs, setCardInputs] = useState<ICardInputs>({ cardType: null });
  const spreedlyStyle = (error: boolean): string => Styled.spreedlyStyle(error, Boolean(payment));

  const handleExpirationChange = async (event) => {
    const expirationChars = event.target.value.replace(/[^0-9]/gi, " ").split("");
    if (expirationChars.length > 2) {
      expirationChars.splice(2, 0, "/");
    }

    const expirationDisplay = expirationChars.join("").replace(" ", "").trim();
    const expirationDateInvalid = expirationDisplay?.length < 5;

    setCardInputs({
      ...cardInputs,
      expirationDisplay,
      expirationDateInvalid,
    });
  };

  useLayoutEffect(() => {
    Spreedly.init(config.spreedlyKey, {
      numberEl: "spreedly-number",
      cvvEl: "spreedly-cvv",
    });

    Spreedly.on("ready", () => {
      Spreedly.setFieldType("number", "tel");
      Spreedly.setFieldType("cvv", "tel");
      Spreedly.setNumberFormat("prettyFormat");
      Spreedly.setPlaceholder("number", "Card number");
      Spreedly.setPlaceholder("cvv", payment ? "***" : "CVV");

      Spreedly.setStyle("number", `${spreedlyStyle(false)}; font-size: 16px;`);
      Spreedly.setStyle("cvv", `${spreedlyStyle(false)}; font-size: 16px;`);

      if (payment) {
        // Gets masked version of existing payment (e.g. ******1234)
        Spreedly.setRecache(payment.spreedlyFingerprint, {
          card_type: payment.type,
          last_four_digits: payment.last4,
        });
      }
    });

    Spreedly.on("fieldEvent", (name, type, activeEl, inputProperties) => {
      if (name === "number" && Object.keys(inputProperties).length && inputProperties.cardType) {
        const cardMap = {
          master: "mastercard",
          american_express: "amex",
        };

        const cardType = cardMap[type] ? cardMap[type] : type;
        setCardInputs((state) => ({ ...state, cardType }));
      }

      if (type === "blur") {
        Spreedly.validate();
      }
    });

    Spreedly.on("errors", (errors) => {
      if (errors.some((e) => e.attribute === "number")) {
        Spreedly.setStyle("number", spreedlyStyle(true));
      }

      if (errors.some((e) => e.attribute === "cvv")) {
        Spreedly.setStyle("cvv", spreedlyStyle(true));
      }
    });

    Spreedly.on("validation", async (inputProperties) => {
      const { validNumber, validCvv } = inputProperties;
      const cvvInvalid = !validCvv;

      // Set styling
      Spreedly.setStyle("number", spreedlyStyle(!validNumber));
      Spreedly.setStyle("cvv", spreedlyStyle(cvvInvalid));

      setCardInputs((state) => ({
        ...state,
        cardNumberInvalid: !validNumber,
        cvvInvalid,
        expirationDateInvalid: !state?.expirationDisplay || state?.expirationDisplay?.length < 5,
      }));
    });
    if (payment?.id) {
      setCardInputs((state) => ({
        ...state,
        expirationDisplay: formatExpirationDisplay(payment),
      }));
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { cvvInvalid, expirationDisplay } = cardInputs;

    const expirationYear =
      expirationDisplay && expirationDisplay.length > 4 ? `20${expirationDisplay?.slice(-2)}` : "";
    const expirationMonth = expirationDisplay?.slice(0, 2);

    onCardUpdate({ cvvInvalid, expirationMonth, expirationYear });
    setAmpliValues?.({ cvvInvalid, expirationMonth, expirationYear });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInputs, onCardUpdate]);

  return (
    <>
      <FormItem
        label="Card number"
        colon={false}
        validateStatus={cardInputs.cardNumberInvalid ? "error" : undefined}
        help={cardInputs.cardNumberInvalid ? "Enter valid card number" : undefined}
      >
        <Styled.CardNumber
          id="spreedly-number"
          data-testid="card-number"
          cardNumberInvalid={cardInputs.cardNumberInvalid}
          disabled={Boolean(payment)}
        />
      </FormItem>
      <Styled.SplitFormGroup
        $isRenderedInModal={!!isRenderedInModal}
        $isAutopayModal={!!isAutopayModal}
      >
        <Styled.FormItemCC
          $isRenderedInModal={!!isRenderedInModal}
          label="Exp. date"
          colon={false}
          validateStatus={cardInputs.expirationDateInvalid ? "error" : undefined}
          help={cardInputs.expirationDateInvalid ? "Enter expiration date (MM/YY)" : undefined}
        >
          <Styled.CreditCardInput
            id="exp_Date"
            aria-label="Expiration Date (MM/YY)"
            placeholder="MM/YY"
            size="large"
            maxLength={5}
            onChange={handleExpirationChange}
            value={cardInputs.expirationDisplay}
            inputMode="tel"
            disabled={Boolean(payment)}
            style={{ fontSize: "16px" }}
          />
        </Styled.FormItemCC>
        <Styled.FormItemCC
          $isRenderedInModal={!!isRenderedInModal}
          label="Security code"
          colon={false}
          validateStatus={cardInputs.cvvInvalid ? "error" : undefined}
          help={cardInputs.cvvInvalid ? "Enter valid CVV" : undefined}
        >
          {/* Since we cannot disable focus from tabbing inside the spreedly iframe we hide it and replace it with this element when editing */}
          {Boolean(payment) && (
            <Styled.SecurityCode
              data-testid="cvv"
              cvvInvalid={cardInputs.cvvInvalid}
              disabled={Boolean(payment)}
            >
              ***
            </Styled.SecurityCode>
          )}
          <Styled.SecurityCode
            id="spreedly-cvv"
            data-testid="cvv"
            cvvInvalid={cardInputs.cvvInvalid}
            hidden={Boolean(payment)}
          />
        </Styled.FormItemCC>
        <Styled.ZipCode
          $isRenderedInModal={!!isRenderedInModal}
          name="billingPostalCode"
          placeholder={billingCodeCopy(currencyCode)}
          label={billingCodeCopy(currencyCode)}
          colon={false}
          inputMode="tel"
          triggerAmplitudeEvent={() => {
            setAmpliValues && ampli.editBillingAddress(amplitudeSubmissionObj);
          }}
        />
      </Styled.SplitFormGroup>
    </>
  );
};
