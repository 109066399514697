import { notification as antDNotification } from "antd";
import type { ArgsProps } from "antd/lib/notification";

import { NotificationStyles } from "./styles";

/** Returns the notification message with the styles injected */
const returnNotificationStyles = (obj: ArgsProps) => {
  return {
    ...obj,
    message: (
      <>
        {obj.message}
        <NotificationStyles />
      </>
    ),
  };
};

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Toast` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/toast-9VycyPHI-9VycyPHI Atlas Web Toast Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-%F0%9F%86%95-toast--docs Atlas Web Toast Storybook}
 *
 * @summary The `Notification` helper leverages antD to provide feedback based on user interaction.
 * It may also be used to give details on upcoming actions the user will take
 *
 * @see {@link https://www.figma.com/file/S9dcIwiGPXxXIT1Iw1n33D/1.-Basic-Components-(Web)?node-id=1%3A8539 Design Specs}
 */
export const notification = {
  error: (obj: ArgsProps) => antDNotification.error(returnNotificationStyles(obj)),
  info: (obj: ArgsProps) => antDNotification.info(returnNotificationStyles(obj)),
  open: (obj: ArgsProps) => antDNotification.open(returnNotificationStyles(obj)),
  success: (obj: ArgsProps) => antDNotification.success(returnNotificationStyles(obj)),
  warn: (obj: ArgsProps) => antDNotification.warn(returnNotificationStyles(obj)),
  warning: (obj: ArgsProps) => antDNotification.warning(returnNotificationStyles(obj)),
  close: (key: string) => antDNotification.close(key),
  destroy: () => antDNotification.destroy(),
};
