import { Select as AntdSelect } from "antd";

import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `Select` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/select-MV2NxHhS Atlas Web Select Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-select--docs Atlas Web Select Storybook}
 *
 * @summary The `Select` component wraps antd's `<Select />` component.
 *
 * @remarks Since we are leveraging antd for most components, our internal Select component does not have any of it's own props. Instead, we are directly referring to the interface definition antd is expecting. This allows us to easily be in alignment with their type definitions and prevents type errors that may arise from maintaining an override interface.
 *
 * @see {@link https://3x.ant.design/components/select/ AntD Select Documentation}
 */

export const Select = Styled.Select;
export const SelectOption = AntdSelect.Option;

export type { Select as TSelect } from "antd";

export type { SelectProps } from "antd/lib/select";
export type { SelectValue } from "antd/lib/select";
